import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Divider, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { Container } from "react-bootstrap";
import Title from "antd/es/typography/Title";
import Navbar from "../../components/Navbar/Navbar";
import logo from "../../assets/images/logo.png";
import { capitalizeEachWord, formatDate } from "../../utils/Common";
import ReactToPrint from "react-to-print";

export default function ViewMemorandum() {
  const location = useLocation();
  const navigate = useNavigate();
  const componentRef = useRef();
  const passedData = location.state;

  return (
    <Navbar isForm>
      <Container className="p-4">
        <div ref={componentRef}>
          <Row>
            <Col xs={1} className="me-4">
              <img src={logo} alt="logo" className="view-page-logo" />
            </Col>
            <Col>
              <Row>
                <Title level={3}>{window.$company_name}</Title>
              </Row>
              <Row>{window.$company_address}</Row>
            </Col>
          </Row>
          <Divider />
          <Row className="center my-3">
            <Title level={4}>MEMORANDUM NO. {passedData.code}</Title>
          </Row>
          <Row>
            <Col xs={2}>Date</Col>
            <Col xs={1}>:</Col>
            <Col>{formatDate(passedData.memo_date)}</Col>
          </Row>
          <Row>
            <Col xs={2}>To</Col>
            <Col xs={1}>:</Col>
            <Col>
              {(function () {
                let to = passedData.details
                  ? passedData.details.map((t) => t.memo_to)
                  : [];
                return to.join(", ");
              })()}
            </Col>
          </Row>
          <Row>
            <Col xs={2}>From</Col>
            <Col xs={1}>:</Col>
            <Col>{capitalizeEachWord(passedData.memo_from)}</Col>
          </Row>
          <Row>
            <Col xs={2}>Subject</Col>
            <Col xs={1}>:</Col>
            <Col>{capitalizeEachWord(passedData.subject)}</Col>
          </Row>
          <Divider />
          <Row className="px-5 py-2 center">
            <div
              dangerouslySetInnerHTML={{
                __html: passedData.content ? passedData.content : "",
              }}
            />
          </Row>

          <Row className="mt-5">Prepared by:</Row>
          <Col xs={12} md={10} lg={7} className="text-center mt-5">
            <Divider className="black-divider" />
            Executive Assistant
          </Col>

          <Row className="mt-5">Approved by:</Row>
          <Col xs={12} md={10} lg={7} className="text-center mt-5">
            <Divider className="black-divider" />
            Operations Head
          </Col>
        </div>

        <Row className="d-flex justify-content-end noPrint">
          <Row>
            <Button
              className="cancel-btn me-2"
              onClick={() => navigate("/memorandums")}
            >
              Cancel
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button type="primary">
                  <PrinterOutlined /> Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Row>
        </Row>
      </Container>
    </Navbar>
  );
}
