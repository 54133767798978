import React, { useState } from "react";
import { Row, Col, Card, Upload, Modal, Button, Typography } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import Papa from "papaparse";
import Navbar from "../../components/Navbar/Navbar";
import { usePostRequest } from "../../hooks/usePostRequest";
import { capitalizeEachWord } from "../../utils/Common";

const { Dragger } = Upload;
const { Title } = Typography;


export default function UploadTimesheet() {
    const navigate = useNavigate();
  const location = useLocation();
  const formPurpose = location.state;
  const [fileList, setFileList] = useState([]);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  // const [modal, contextHolder] = Modal.useModal();
  const { callPost, isLoading } = usePostRequest(() => {navigate("/timesheet")});

  const handleRemove = (selectedFile) => {
    var newlist = fileList.filter((file) => {
      return selectedFile.uid !== file.uid;
    });
    setFileList(newlist);
  };

  const handleBeforeUpload = (file) => {
    const isSizeValid = file.size / 1024 / 1024 < 10;

    if (!isSizeValid) {
      setFileSizeExceeded(true);
      return true;
    } else {
      setFileList([...fileList, file]);
      return false;
    }
  };

  function handleUploadTimesheet() {
    const file = fileList[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          // The converted data is stored in result.data hence here ta magprocess
          // Drop the employee column
          const newArray = result.data.map(({ employee, ...rest }) => rest);
          callPost("timesheets/upload_timesheet", { data: newArray }, false);
        },
        header: true, // If the first row of the CSV contains headers
        skipEmptyLines: true, // Skip empty lines in the CSV
      });
    }
  }

  function handleUploadBiometrics() {
    var formInputs = {};
    formInputs.file_name = fileList[0].name;
    formInputs.file = fileList[0];

    var values = Object.entries(formInputs).map(([key, value]) => ({
      key,
      value,
    }));
    callPost("timesheets/upload_biometrics", values, true);
  }

  return (
    <Navbar formPageTitle="Upload Timesheet" isForm>
      <Card>
        <Title level={3}>{capitalizeEachWord("upload " + formPurpose)}</Title>
        <Row className="mt-3">
          <Col xs={24}>
            <Dragger
              {...{
                fileList: fileList,
                onRemove: handleRemove,
                beforeUpload: handleBeforeUpload,
                // multiple: false,
                listType: "picture",
                progress: { showInfo: true },
                data: (file) => {},
                accept: ".xlsx, .csv",
              }}
            >
              <CloudUploadOutlined className="upload-icon" />
              <p className="ant-upload-text">Browse Files</p>
            </Dragger>
          </Col>
        </Row>

        {
          formPurpose && formPurpose === "timesheet" && 
          <Row className="d-flex justify-content-center p-0 mt-2">
            <Button type="primary" className=" mt-3">
                <a href="https://greencycle-api.hrconnect.ph/greencycle_timesheet_template.csv" className="center-text no-style-btn">
                    Download Template
                </a>
            </Button>
        </Row>
        }

        <Row className="d-flex justify-content-end mt-4">
          <Row>
            <Button danger className="me-2" onClick={() => navigate("/timesheet")}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={
                formPurpose === "timesheet"
                  ? handleUploadTimesheet
                  : handleUploadBiometrics
              }
              disabled={fileList.length === 0}
              loading={isLoading}
            >
              Upload
            </Button>
          </Row>
        </Row>
      </Card>

      <Modal
        open={fileSizeExceeded}
        title={""}
        footer={null}
        onCancel={() => setFileSizeExceeded(false)}
      >
        File size must not exceed 5MB.
      </Modal>
      {/* {contextHolder} */}
    </Navbar>
  );
}
