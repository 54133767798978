import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Button, Form, Modal, Table, Row, Col, DatePicker, InputNumber, TimePicker } from 'antd'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { usePostRequest } from '../../hooks/usePostRequest'
import { UseGetRequest } from '../../utils/api/ApiRequests'
import moment from 'moment'
import dayjs from 'dayjs'

// moment.locale('en');


const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  
  // Watch all values
  const values = Form.useWatch([], form);
    React.useEffect(() => {
      form.validateFields({
        validateOnly: true,
      }).then(() => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
      );
    }, [values]);
    return (
    <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Submit</Button>
    );
};

const EditSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  
  // Watch all values
  const values = Form.useWatch([], form);
    React.useEffect(() => {
      form.validateFields({
        validateOnly: true,
      }).then(() => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
      );
    }, [values]);
    
    return (
    <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Save Changes</Button>
    );
};



export default function NightDifferential () {
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const {callPost, isLoading} = usePostRequest(()=>{
    setShowAddModal(false); 
    setShowEditModal(false);
    setShowViewModal(false);
    // form.resetFields(); editForm.resetFields();
    setFilterConfig((prev) => ({...prev, is_updated: !prev.is_updated}))});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  // const [initialValues, setInitialValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [filterConfig, setFilterConfig] = useState({
    is_updated: false
  });
  const [isFetching, setIsFetching] = useState(false);
  
  const tableColumns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (date, record) => (
            <div onClick={() => handleRowClick(record)} className='pointer-cursor'>{moment(date).format('MMM DD, YYYY')}</div>
          )
    },
    {
        title: 'Rate (%)',
        dataIndex: 'rate',
        key: 'rate',
    },
    {
        title: 'Time In',
        dataIndex: 'time_start',
        key: 'time_start',
        render: (time_start, record) => (
            <div onClick={() => handleRowClick(record)} className='pointer-cursor'>{moment(time_start, 'HH:mm:ss').format('hh:mm A')}</div>
        )
    },
    {
        title: 'Time Out',
        dataIndex: 'time_end',
        key: 'time_end',
        render: (time_end, record) => (
            <div onClick={() => handleRowClick(record)} className='pointer-cursor'>{moment(time_end, 'HH:mm:ss').format('hh:mm A')}</div>
        )
    },
  ];

  const confirmDelete = (nightDiff) => {
    modal.confirm({
      title: `Are you sure you want to delete night differential on "${nightDiff.date}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleDelete(nightDiff.id),
      okType: 'danger',
    });
  };

  const onFinish = (formInputs) => {
    // var values = Object.entries(formInputs).map(([key, value]) => ({ key, value }));
    formInputs.date = formInputs.date.format('YYYY-MM-DD');
    formInputs.time_start = formInputs.time_start.format('HH:mm:ss');
    formInputs.time_end = formInputs.time_end.format('HH:mm:ss');
    callPost(
      "night_differentials/add", 
      formInputs,
      false
    );
    form.resetFields();
  };

  const onEdit = (formInputs) => {
    // var values = Object.entries(formInputs).map(([key, value]) => ({ key, value }));
    formInputs.date = formInputs.date.format('YYYY-MM-DD');
    formInputs.time_start = formInputs.time_start.format('HH:mm:ss');
    formInputs.time_end = formInputs.time_end.format('HH:mm:ss');
    formInputs.night_differential_id = clickedRowData.id;
    callPost(
      `night_differentials/update`, 
      formInputs,
      false
    );
    editForm.resetFields();
  };

  function handleDelete(passedId) {
    callPost("night_differentials/delete",  {night_differential_id: passedId}, false);
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData);
    const formattedValues = { ...rowData };
    formattedValues.date = rowData.date?dayjs(rowData.date, "YYYY-MM-DD"):'';
    formattedValues.time_start = moment(rowData.time_start,"hh:mm A");
    formattedValues.time_end = moment(rowData.time_end,"hh:mm A"); 
    editForm.setFieldsValue(formattedValues);
    setShowViewModal(true);
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });


React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UseGetRequest("night_differentials/get", filterConfig);
    if (response.data) {
        const tempData = response.data.data.night_differential.map((row) => {
            var info = row;
            return info;
        })
        setTableData(tempData);
    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar
      pageTitle="Night Differentials"
      pageButtonTitle="Add Night Differential"
      onClickButton={() => setShowAddModal(true)}
      isManager
    >
      <Table
        className="mt-5"
        rowKey="id"
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching || isLoading}
      />

      <Modal
        title={"Add Night Differential"}
        centered
        open={showAddModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          form.resetFields();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={onFinish}
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <DatePicker  placeholder="YYYY-MM-DD"/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="rate"
                label="Rate (%)"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_start"
                label="Time Start"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format={"hh:mm A"} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_end"
                label="Time End"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format={"hh:mm A"} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => setShowAddModal(false)}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Night Differential"
        centered
        open={showEditModal}
        footer={null}
        onCancel={() => {
          setShowEditModal(false);
          editForm.resetFields();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={editForm}
          name="editForm"
          layout="vertical"
          onFinish={onEdit}
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <DatePicker format={"YYYY-MM-DD"}/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="rate"
                label="Rate (%)"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_start"
                label="Time Start"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format={"HH:mm A"} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_end"
                label="Time End"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format={"HH:mm A"}/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowEditModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <EditSubmitButton form={editForm} isLoading={isLoading} />
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Night Differential"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          editForm.resetFields();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={editForm}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <DatePicker format={"YYYY-MM-DD"} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="rate"
                label="Rate (%)"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <InputNumber disabled />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_start"
                label="Time Start"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format="hh:mm A" disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_end"
                label="Time End"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format="hh:mm A" disabled/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowViewModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
              >
                Edit
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </Navbar>
  );
}
