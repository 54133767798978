import React, {useState} from "react";
import {Form, Button, Row, Space, Select, Card, Input, Table, Checkbox} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { usePostRequest } from "../../hooks/usePostRequest";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import { GetAllEmployees, GetAllBranches, GetAllEmploymentStatus, GetAllBusinessUnits } from "../../utils/api/DropdownFiltersApi";
import moment from "moment";
import '../../../src/cssFiles/CustomStyles/Payroll.css';
import { extractAmount, getSum, isValidNumber, minsToHours } from "../../utils/Common";


const AddSubmitButton = ({ form, isLoading }) => {
    const [submittable, setSubmittable] = useState(false);
    const values = Form.useWatch([], form);
    
    React.useEffect(() => {
      form
        .validateFields({
          validateOnly: true,
        })
        .then(
          () => {
            setSubmittable(true);
          },
          () => {
            setSubmittable(false);
          }
        );
    }, [values, form]);
  
    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={!submittable}
        loading={isLoading}
      >
        Submit
      </Button>
    );
  };


export default function GeneratePayroll () {
    const [form] = Form.useForm()
    const navigate = useNavigate();
    const location = useLocation();
    const salaryType = location.state.salary_type;
    const dateFrom = location.state.date_from;
    const dateTo = location.state.date_to;
    const {callPost, isLoading} = usePostRequest(() => navigate("/payrolls"));
    const [filterConfig, setFilterConfig] = useState({
        salary_type: salaryType || '',
        date_from: moment(dateFrom.$d).format('YYYY-MM-DD'),
        date_to: moment(dateTo.$d).format('YYYY-MM-DD'),
        employee_id: [],
        branch_id: '',
        employment_status_id: '',
        business_unit_id: '',
    })
    const employeeOptions = GetAllEmployees();
    const branchOptions = GetAllBranches();
    const employmentStatusOptions = GetAllEmploymentStatus();
    const businessUnitOptions = GetAllBusinessUnits();
    const [tableData, setTableData] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isThirteenthIncluded, setIsThirteenthIncluded] = useState(false);


    const handleCheckbox = (e) => {
        setIsThirteenthIncluded(e.target.checked);
    }

    const tableColumns = [
        {
            title: 'Employee',
            dataIndex: 'employee_name',
            // width: 800,
            key: 'employee_name',
            render: (text, record, index) => (
                <Form.Item name={[index, 'employee_name']} initialValue={text}>
                    <Input disabled />
                </Form.Item>
            ),
        },
        {
            title: 'Rate',
            dataIndex: 'basic_rate',
            key: 'basic_rate',
            render: (text, record, index) => (
                <Form.Item name={[index, 'basic_rate']} initialValue={text}>
                    <Input disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Work Days',
            dataIndex: 'work_days',
            key: 'work_days',
            render: (text, record, index) => (
                <Form.Item name={[index, 'work_days']} initialValue={text}>
                    <Input disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Holiday Pay',
            dataIndex: 'holiday_pay',
            key: 'holiday_pay',
            render: (text, record, index) => (
                <Form.Item name={[index, 'holiday_pay']} initialValue={extractAmount(text)}>
                    <Input  disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Basic Pay',
            dataIndex: 'basic_pay',
            key: 'basic_pay',
            render: (text, record, index) => (
                <Form.Item name={[index, 'basic_pay']} initialValue={extractAmount(text)}>
                    <Input />
                </Form.Item>
            ),
        },
        {
            title: 'Undertime (hrs)',
            dataIndex: 'undertime_hrs',
            key: 'undertime_hrs',
            render: (text, record, index) => (
                <Form.Item name={[index, 'undertime_hrs']} initialValue={text}>
                    <Input  disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Undertime Amt',
            dataIndex: 'undertime_amount',
            key: 'undertime_amount',
            render: (text, record, index) => (
                <Form.Item name={[index, 'undertime_amount']} initialValue={text}>
                    <Input  disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Late (hrs)',
            dataIndex: 'late_hrs',
            key: 'late_hrs',
            render: (text, record, index) => (
                <Form.Item name={[index, 'late_hrs']} initialValue={text}>
                    <Input type="number"  disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Late',
            dataIndex: 'late',
            key: 'late',
            render: (text, record, index) => (
                <Form.Item name={[index, 'late']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'SSS',
            dataIndex: 'sss_amount',
            key: 'sss_amount',
            render: (text, record, index) => (
                <Form.Item name={[index, 'sss_amount']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'HDMF',
            dataIndex: 'hdmf_amount',
            key: 'hdmf_amount',
            render: (text, record, index) => (
                <Form.Item name={[index, 'hdmf_amount']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'PhilHealth',
            dataIndex: 'phic_amount',
            key: 'phic_amount',
            render: (text, record, index) => (
                <Form.Item name={[index, 'phic_amount']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Intellicare',
            dataIndex: 'intellicare_amount',
            key: 'intellicare_amount',
            render: (text, record, index) => (
                <Form.Item name={[index, 'intellicare_amount']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'SSS Salary Loan',
            dataIndex: 'sss_salary_loan',
            key: 'sss_salary_loan',
            render: (text, record, index) => (
                <Form.Item name={[index, 'sss_salary_loan']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'SSS Calamity Loan',
            dataIndex: 'sss_calamity_loan',
            key: 'sss_calamity_loan',
            render: (text, record, index) => (
                <Form.Item name={[index, 'sss_calamity_loan']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'HDMF Loan',
            dataIndex: 'pagibig_loan',
            key: 'pagibig_loan',
            render: (text, record, index) => (
                <Form.Item name={[index, 'pagibig_loan']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Calamity Loan',
            dataIndex: 'calamity_loan',
            key: 'calamity_loan',
            render: (text, record, index) => (
                <Form.Item name={[index, 'calamity_loan']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'HMO Maxicare',
            dataIndex: 'hmo_maxicare',
            key: 'hmo_maxicare',
            render: (text, record, index) => (
                <Form.Item name={[index, 'hmo_maxicare']} initialValue={0}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Coop Contribution',
            dataIndex: 'coop_contribution',
            key: 'coop_contribution',
            render: (text, record, index) => (
                <Form.Item name={[index, 'coop_contribution']} initialValue={0}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Coop Loan',
            dataIndex: 'cooperative_loan',
            key: 'cooperative_loan',
            render: (text, record, index) => (
                <Form.Item name={[index, 'cooperative_loan']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Company Loan',
            dataIndex: 'company_loan',
            key: 'company_loan',
            render: (text, record, index) => (
                <Form.Item name={[index, 'company_loan']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'WTH Tax',
            dataIndex: 'wth_tax',
            key: 'wth_tax',
            render: (text, record, index) => (
                <Form.Item name={[index, 'wth_tax']} initialValue={0}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'AXA',
            dataIndex: 'axa_amount',
            key: 'axa_amount',
            render: (text, record, index) => (
                <Form.Item name={[index, 'axa_amount']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Phone Loan',
            dataIndex: 'phone_loan',
            key: 'phone_loan',
            render: (text, record, index) => (
                <Form.Item name={[index, 'phone_loan']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Laptop Loan',
            dataIndex: 'laptop_loan',
            key: 'laptop_loan',
            render: (text, record, index) => (
                <Form.Item name={[index, 'laptop_loan']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Cash Advance',
            dataIndex: 'cash_advance',
            key: 'cash_advance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'cash_advance']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Others',
            dataIndex: 'others',
            key: 'others',
            render: (text, record, index) => (
                <Form.Item name={[index, 'others']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Total (1)',
            dataIndex: 'total_one',
            key: 'total_one',
            render: (text, record, index) => (
                // <Form.Item name={[index, 'total_one']}>
                    <Input value={tableData[index].total_one} type="number" disabled/>
                // </Form.Item>
            ),
        },
        {
            title: 'Daily Allowance',
            dataIndex: 'daily_allowance',
            key: 'daily_allowance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'daily_allowance']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Comm Allowance',
            dataIndex: 'comm_allowance',
            key: 'comm_allowance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'comm_allowance']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Transpo Allowance',
            dataIndex: 'transpo_allowance',
            key: 'transpo_allowance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'transpo_allowance']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Food Allowance',
            dataIndex: 'food_allowance',
            key: 'food_allowance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'food_allowance']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'HMO Allowance',
            dataIndex: 'hmo_allowance',
            key: 'hmo_allowance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'hmo_allowance']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Tech Allowance',
            dataIndex: 'tech_allowance',
            key: 'tech_allowance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'tech_allowance']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'OPS Allowance',
            dataIndex: 'ops_allowance',
            key: 'ops_allowance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'ops_allowance']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Special Allowance',
            dataIndex: 'special_allowance',
            key: 'special_allowance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'special_allowance']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Grocery Allowance',
            dataIndex: 'grocery_allowance',
            key: 'grocery_allowance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'grocery_allowance']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Entertainment Allowance',
            dataIndex: 'entertainment_allowance',
            key: 'entertainment_allowance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'entertainment_allowance']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Medicine Allowance',
            dataIndex: 'medicine_allowance',
            key: 'medicine_allowance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'medicine_allowance']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Uniform Allowance',
            dataIndex: 'uniform_allowance',
            key: 'uniform_allowance',
            render: (text, record, index) => (
                <Form.Item name={[index, 'uniform_allowance']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Total (2)',
            dataIndex: 'total_two',
            key: 'total_two',
            render: (text, record, index) => (
                // <Form.Item name={[index, 'total_two']} initialValue={text}>
                    <Input value={tableData[index].total_two} type="number" disabled/>
                // </Form.Item>
            ),
        },
        {
            title: 'Regular OT (hrs)',
            dataIndex: 'overtime_hrs',
            key: 'overtime_hrs',
            render: (text, record, index) => (
                <Form.Item name={[index, 'overtime_hrs']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Regular OT',
            dataIndex: 'regular_ot',
            key: 'regular_ot',
            render: (text, record, index) => (
                <Form.Item name={[index, 'regular_ot']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Restday OT (hrs)',
            dataIndex: 'restday_ot_hrs',
            key: 'restday_ot_hrs',
            render: (text, record, index) => (
                <Form.Item name={[index, 'restday_ot_hrs']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Restday OT',
            dataIndex: 'restday_ot',
            key: 'restday_ot',
            render: (text, record, index) => (
                <Form.Item name={[index, 'restday_ot']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Special Holiday Pay',
            dataIndex: 'special_holiday_pay',
            key: 'special_holiday_pay',
            render: (text, record, index) => (
                <Form.Item name={[index, 'special_holiday_pay']} initialValue={0}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Night Differential',
            dataIndex: 'night_differential',
            key: 'night_differential',
            render: (text, record, index) => (
                <Form.Item name={[index, 'night_differential']} initialValue={text}>
                    <Input type="number" disabled/>
                </Form.Item>
            ),
        },
        {
            title: 'Salary Adj',
            dataIndex: 'salary_adj',
            key: 'salary_adj',
            render: (text, record, index) => (
                <Form.Item name={[index, 'salary_adj']} initialValue={0}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Monthly',
            dataIndex: 'monthly',
            key: 'monthly',
            render: (text, record, index) => (
                <Form.Item name={[index, 'monthly']} initialValue={0}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Semi Monthly',
            dataIndex: 'semi_monthly',
            key: 'semi_monthly',
            render: (text, record, index) => (
                <Form.Item name={[index, 'semi_monthly']} initialValue={0}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
        {
            title: 'Total (3)',
            dataIndex: 'total_three',
            key: 'total_three',
            render: (text, record, index) => (
                // <Form.Item name={[index, 'total_three']} initialValue={text}>
                    <Input value={tableData[index].total_three} type="number" disabled/>
                // </Form.Item>
            ),
        },
        {
            title: 'Total Net Pay',
            dataIndex: 'total_net_pay',
            key: 'total_net_pay',
            render: (text, record, index) => (
                // <Form.Item name={[index, 'total_net_pay']} initialValue={text}>
                    <Input value={tableData[index].total_net_pay} type="number" disabled/>
                // </Form.Item>
            ),
        },
        {
            title: <div className="d-flex justify-content-between">Thirteenth Month <Checkbox onChange={handleCheckbox}></Checkbox></div> ,
            dataIndex: 'thirteenth_month',
            key: 'thirteenth_month',
            render: (text, record, index) => (
                <Form.Item name={[index, 'thirteenth_month']} initialValue={text}>
                    <Input type="number"/>
                </Form.Item>
            ),
        },
    ]

    // API Call to submit payroll
    const onFinish = (formInputs) => {
        const arrayOfValues = Object.values(formInputs);

        // Commented out values that cannot be edited on frontend hence, 
        // backend can fetch directly the values from db. This reduces the
        // payload size which may be helpful if we have more employee data to pass in the future
        const finalValues = {
            date_from: moment(dateFrom.$d).format('YYYY-MM-DD'),
            date_to: moment(dateTo.$d).format('YYYY-MM-DD'),
            is_thirteenth_included: isThirteenthIncluded?1:0,
            salary_type: salaryType,
            employee_id: filterConfig.employee_id,
            branch_id: filterConfig.branch_id,
            employment_status_id: filterConfig.employment_status_id,
            business_unit_id: filterConfig.business_unit_id,
            employees: [], 
            // salary_type: [],
            branches:[], 
            axa_amount:[],
            cash_advance: [],
            phone_loan: [],
            laptop_loan: [],
            sss_contribution: [],
            philhealth_contribution: [],
            pagibig_contribution: [],
            intellicare_amount: [],
            sss_salary_loan: [],
            sss_calamity_loan: [],
            pagibig_loan: [],
            calamity_loan: [],
            hmo_maxicare: [],
            coop_contribution: [],
            coop_loan: [],
            // cooperative_loan: [],
            company_loan: [],
            wth_tax: [],
            others: [],
            total_one: [],
            total_two: [],
            special_holiday_pay: [],
            salary_adj: [],
            monthly: [],
            semi_monthly: [],
            total_three: [],
            total_net_pay: [],
        }
        
        arrayOfValues.forEach((data, index) => {
            finalValues['employees'][index] = tableData[index].employee_id;
            // finalValues['salary_type'][index] = salaryType;
            finalValues['branches'][index] = tableData[index].branch_id;
            finalValues['axa_amount'][index] = data.axa_amount;
            finalValues['cash_advance'][index] = data.cash_advance;
            finalValues['phone_loan'][index] = data.phone_loan;
            finalValues['laptop_loan'][index] = data.laptop_loan;
            finalValues['sss_contribution'][index] = data.sss_amount;
            finalValues['philhealth_contribution'][index] = data.phic_amount;
            finalValues['pagibig_contribution'][index] = data.hdmf_amount;
            finalValues['intellicare_amount'][index] = data.intellicare_amount;
            finalValues['sss_salary_loan'][index] = data.sss_salary_loan;
            finalValues['sss_calamity_loan'][index] = data.sss_calamity_loan;
            finalValues['pagibig_loan'][index] = data.pagibig_loan;
            finalValues['calamity_loan'][index] = data.calamity_loan;
            finalValues['hmo_maxicare'][index] = data.hmo_maxicare;
            finalValues['coop_contribution'][index] = data.coop_contribution;
            finalValues['coop_loan'][index] = data.cooperative_loan; // confusing becos backend return cooperative_loan but asks for coop_loan
            finalValues['company_loan'][index] = data.company_loan;
            finalValues['wth_tax'][index] = data.wth_tax;
            finalValues['others'][index] = data.others;
            finalValues['total_one'][index] = tableData[index].total_one;
            finalValues['total_two'][index] = tableData[index].total_two;
            finalValues['special_holiday_pay'][index] = data.special_holiday_pay;
            finalValues['salary_adj'][index] = data.salary_adj;
            finalValues['monthly'][index] = data.monthly;
            finalValues['semi_monthly'][index] = data.semi_monthly;
            finalValues['total_three'][index] = tableData[index].total_three;
            finalValues['total_net_pay'][index] = tableData[index].total_net_pay;
        })

        callPost("payrolls/add", finalValues, false); 
    };

    const handleFilter = (e, field) => {
        if (field==="employee_id") {
            setFilterConfig((prev) => ({...prev, [field]: [e]}));
        } else {
            setFilterConfig((prev) => ({...prev, [field]: e}));
        }
    }

    // Function to update the totals 1,2,3, and netPay on each value update
    const handleValueChange = (changedValues, allValues) => {
       const changedIndex = parseInt(Object.keys(changedValues)[0]);
       const info = allValues[changedIndex];
 
       const gains = getSum([info.basic_pay,extractAmount(info.holiday_pay)]);
        const deductions = getSum([info.undertime_amount,info.late,info.sss_amount,info.hdmf_amount,info.phic_amount,
            info.intellicare_amount,info.sss_salary_loan,info.sss_calamity_loan,info.pagibig_loan,info.calamity_loan,
            info.hmo_maxicare,info.coop_contribution,info.cooperative_loan,info.company_loan,info.wth_tax,info.axa_amount,
            info.phone_loan,info.laptop_loan,info.cash_advance,info.others]);
        const tempTotalOne = parseFloat(gains-deductions).toFixed(2);
        
        const tempTotalTwo = getSum([info.daily_allowance,info.comm_allowance,info.transpo_allowance,info.food_allowance,
            info.hmo_allowance,info.tech_allowance,info.ops_allowance,info.special_allowance,
            info.grocery_allowance,info.entertainment_allowance,info.medicine_allowance,info.uniform_allowance]);

        const tempTotalThree = getSum([info.regular_ot,info.restday_ot,info.night_differential,info.special_holiday_pay,info.salary_adj, info.monthly, info.semi_monthly]);
        const totalNetPay = getSum([tempTotalOne,tempTotalTwo,tempTotalThree]);
        
        setTableData((prevTableData) => {
            const updatedTableData = [...prevTableData];
            updatedTableData[changedIndex] = {
              ...updatedTableData[changedIndex],
              total_one: tempTotalOne,
              total_two: tempTotalTwo,
              total_three: tempTotalThree,
              total_net_pay: totalNetPay,
            };
            return updatedTableData;
          });
    }

    React.useEffect(() => {
        async function fetchAutoFields () {
            setIsDataLoading(true);
            setTableData([]);
            const response = await UsePostRequest("payrolls/get_auto_fields", filterConfig);
            if (response.data) {
                const res = response.data.map((row) => {
                    var info = {};
                    row.undertime_hrs = minsToHours(row.undertime_mins);
                    row.overtime_hrs = minsToHours(row.overtime_mins);
                    row.late_hrs = minsToHours(row.late_mins);
                    row.restday_ot_hrs = minsToHours(row.restday_ot_mins);
                    Object.keys(row).forEach((key) => {
                        if (row[key] && isValidNumber(row[key]) && !key.includes("date") && !key.includes("id")) {
                            info[key] = extractAmount(row[key]);
                        } else {
                            info[key] = row[key];
                        }
                    }) 
        
                    // Compute the initial value for total (1), (2), (3), and net pay
                    const gains = getSum([info.basic_pay, extractAmount(info.holiday_pay)]);
                    const deductions = getSum([info.undertime_amount,info.late,info.sss_amount,info.hdmf_amount,info.phic_amount,
                        info.intellicare_amount,info.sss_salary_loan,info.sss_calamity_loan,info.pagibig_loan,info.calamity_loan,
                        info.hmo_maxicare,info.coop_contribution,info.cooperative_loan,info.company_loan,info.wth_tax,info.axa_amount,
                        info.phone_loan,info.laptop_loan,info.cash_advance,info.others]);
                    const tempTotalOne = gains-deductions;
                    info.total_one = parseFloat(tempTotalOne).toFixed(2);

                    const tempTotalTwo = getSum([info.daily_allowance,info.comm_allowance,info.transpo_allowance,info.food_allowance,
                        info.hmo_allowance,info.tech_allowance,info.ops_allowance,info.special_allowance,
                        info.grocery_allowance,info.entertainment_allowance,info.medicine_allowance,info.uniform_allowance]);
                        info.total_two = parseFloat(tempTotalTwo).toFixed(2);

                    const tempTotalThree = getSum([info.regular_ot,info.restday_ot,info.night_differential,info.special_holiday_pay,info.salary_adj]);
                    info.total_three = parseFloat(tempTotalThree).toFixed(2);
                    info.total_net_pay = getSum([tempTotalOne,tempTotalTwo,tempTotalThree]);

                    return info;
                })
                form.setFieldsValue(res)
                setTableData(res);
            }
            setIsDataLoading(false);
        }

        async function fetchMonthlyAutoFields () {
            setIsDataLoading(true);
            setTableData([]);
            const response = await UsePostRequest("payrolls/get_auto_fields_for_monthly", filterConfig);
            if (response.data) {
                const res = response.data.map((row) => {
                    var info = {};
                    row.undertime_hrs = minsToHours(row.undertime_mins);
                    row.overtime_hrs = minsToHours(row.overtime_mins);
                    row.late_hrs = minsToHours(row.late_mins);
                    row.restday_ot_hrs = minsToHours(row.restday_ot_mins);
                    Object.keys(row).forEach((key) => {
                        if (row[key] && isValidNumber(row[key]) && !key.includes("date") && !key.includes("id")) {
                            info[key] = extractAmount(row[key]);
                        } else {
                            info[key] = row[key];
                        }
                    }) 
        
                    // Compute the initial value for total (1), (2), (3), and net pay
                    const gains = getSum([info.basic_pay, extractAmount(info.holiday_pay)]);
                    const deductions = getSum([info.undertime_amount,info.late,info.sss_amount,info.hdmf_amount,info.phic_amount,
                        info.intellicare_amount,info.sss_salary_loan,info.sss_calamity_loan,info.pagibig_loan,info.calamity_loan,
                        info.hmo_maxicare,info.coop_contribution,info.cooperative_loan,info.company_loan,info.wth_tax,info.axa_amount,
                        info.phone_loan,info.laptop_loan,info.cash_advance,info.others]);
                    const tempTotalOne = gains-deductions;
                    info.total_one = parseFloat(tempTotalOne).toFixed(2);

                    const tempTotalTwo = getSum([info.daily_allowance,info.comm_allowance,info.transpo_allowance,info.food_allowance,
                        info.hmo_allowance,info.tech_allowance,info.ops_allowance,info.special_allowance,
                        info.grocery_allowance,info.entertainment_allowance,info.medicine_allowance,info.uniform_allowance]);
                        info.total_two = parseFloat(tempTotalTwo).toFixed(2);

                    const tempTotalThree = getSum([info.regular_ot,info.restday_ot,info.night_differential,info.special_holiday_pay,info.salary_adj]);
                    info.total_three = parseFloat(tempTotalThree).toFixed(2);
                    info.total_net_pay = getSum([tempTotalOne,tempTotalTwo,tempTotalThree]);

                    return info;
                })
                form.setFieldsValue(res)
                setTableData(res);
            }
            setIsDataLoading(false);
        }
        
        if (salaryType.includes("monthly")) {
            fetchMonthlyAutoFields()
        } else {
            fetchAutoFields()
        }
    }, [filterConfig])

    return (
        <Navbar formTitle="Generate Payroll" isForm>
            <Space>
                {/* <Select
                    showSearch
                    style={{ width: 200,}}
                    placeholder="Employee"
                    optionFilterProp="items"
                    filterOption={(input, option) => (option.label.toLowerCase().includes(input.toLowerCase()))}
                    filterSort={(optionA, optionB) => {
                        if (optionA===optionB) {
                            return 0
                        }
                        return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    }
                    options={employeeOptions}
                    onChange={(e) => handleFilter(e, 'employee_id')}
                /> */}
                <Select showSearch
                  placeholder="Employee"
                  optionFilterProp="items"
                  filterOption={(input, option) => (option.label.toLowerCase().includes(input.toLowerCase()))}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  } 
                  options={employeeOptions} 
                  onChange={(e) => handleFilter(e, 'employee_id')}
                />
                <Select
                    showSearch
                    style={{ width: 200,}}
                    placeholder="Branch"
                    optionFilterProp="items"
                    filterOption={(input, option) => (option.label.toLowerCase().includes(input.toLowerCase()))}
                    filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={branchOptions}
                    onChange={(e) => handleFilter(e, 'branch_id')}
                />
                <Select
                    showSearch
                    style={{ width: 200,}}
                    placeholder="Employment Status"
                    optionFilterProp="items"
                    filterOption={(input, option) => (option.label.toLowerCase().includes(input.toLowerCase()))}
                    filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={employmentStatusOptions}
                    onChange={(e) => handleFilter(e, 'employment_status_id')}
                />
                <Select
                    showSearch
                    style={{ width: 200,}}
                    placeholder="Business Unit"
                    optionFilterProp="items"
                    filterOption={(input, option) => (option.label.toLowerCase().includes(input.toLowerCase()))}
                    filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={businessUnitOptions}
                    onChange={(e) => handleFilter(e, 'business_unit_id')}
                />
            </Space>
            <Card>
                <Form
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    onFinish={onFinish}
                    onValuesChange={handleValueChange}
                >
                    <div className="payroll-table-wrapper">
                        <Table dataSource={tableData} columns={tableColumns} rowKey="key" bordered pagination={false} loading={isDataLoading}/>
                    </div>
                    <Form.Item>
                        <Row className="d-flex justify-content-end mt-2">
                        <Row>
                            <Button
                            className="me-2"
                            danger
                            onClick={() => {
                                navigate("/payrolls");
                            }}
                            disabled={isLoading}
                            >
                            Cancel
                            </Button>
                            <AddSubmitButton form={form} isLoading={isLoading} />
                        </Row>
                        </Row>
                    </Form.Item>
                </Form>
            </Card>
        </Navbar>
    )
}