import React, {useState, useRef} from "react";
import { useLocation } from "react-router-dom";
import { Row, Card, Col, Typography, Divider, Table, Button} from "antd";
import Navbar from "../../components/Navbar/Navbar";
import tempImg from "../../assets/images/user-profile.jpg";
import { UsePostRequest } from "../../utils/api/ApiRequests"; // used to GET data with that uses 'post'
import moment from "moment";
import ReactToPrint from "react-to-print";

import { capitalizeEachWord, formatAmount, formatDate } from "../../utils/Common";

const {Title} = Typography;

export default function EmployeeProfile () {
    const location = useLocation();
    const printRef = useRef();
    const passedId = location.state.passedId;
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [employmentHistory, setEmploymentHistory] = useState([]);


    const employmentHistoryTable = [
        {
          title: 'Period',
          dataIndex: 'period',
          key: 'period',
          render: (text, record) =>
            text ? moment(text).format('MMMM D, yyyy') : '',
        },
        {
          title: 'Position',
          dataIndex: 'position',
          key: 'position',
        },
        {
          title: 'Salary',
          dataIndex: 'salary',
          key: 'salary',
          render: (text) =>
            text ? formatAmount(text) : ''
        },
      ];
 
    React.useEffect(() => {
        async function fetchEmployeeData () {
            const response = await UsePostRequest("employees/get", {employee_id: passedId});
            if (response.data) {
                const res = response.data[0];
                const tempName = `${res.first_name} ${res.middle_name} ${res.last_name}`;
                res.fullname = capitalizeEachWord(tempName);
                setEmployeeDetails(res);
            }            
        }

        async function fetchEmploymentHistory () {
            const response = await UsePostRequest("employees/employment_history/"+ passedId);
            setEmploymentHistory([]);
            if (response.data) {
                const res = response.data;
                const result = res.map((data, index) => {
                    var info = [];
                    info.period = data.doc_date;
                    info.salary = data.value_to;
                    info.position = data.position_to;
                    return info;
                });
                setEmploymentHistory(result);
            }
        }

        fetchEmployeeData();
        fetchEmploymentHistory();
        // fetchEmployeeContracts();
    }, [])


    return (
        <Navbar formTitle="Employee Profile" isForm>
            <Row className="view-page" ref={printRef}>
                <Col lg={4} sm={4} xs={24}>
                    <Card className="profile-pic-container">
                        <img src={tempImg} alt="" className="profile-picture"/>
                    </Card>
                </Col>
                <Col lg={20} sm={20} xs={24}>
                    <Card className="mx-1">
                        <Row>
                            <Col lg={12} sm={12} xs={24}>
                                <Row><Col lg={5} className="bold">Name</Col><Col lg={1}>:</Col><Col lg={17}>{employeeDetails.fullname}</Col></Row>
                                <Row><Col lg={5} className="bold">Religion</Col><Col lg={1}>:</Col><Col lg={17}>{capitalizeEachWord(employeeDetails.religion)}</Col></Row>
                                <Row><Col lg={5} className="bold">Civil Status</Col><Col lg={1}>:</Col><Col lg={17}>{capitalizeEachWord(employeeDetails.civil_status)}</Col></Row>
                            </Col>
                            <Col lg={12} sm={12} xs={24}>
                                <Row><Col lg={5} className="bold">Gender</Col><Col lg={1}>:</Col><Col lg={17}>{capitalizeEachWord(employeeDetails.gender)}</Col></Row>
                                <Row><Col lg={5} className="bold">Birthdate</Col><Col lg={1}>:</Col><Col lg={17}>{moment(employeeDetails.birthdate).format('MMMM DD, YYYY')}</Col></Row>
                                <Row><Col lg={5} className="bold">Nationality</Col><Col lg={1}>:</Col><Col lg={17}>{capitalizeEachWord(employeeDetails.nationality)}</Col></Row>
                            </Col>
                        </Row>
                    </Card>
                    <Row>
                        <Col lg={12} sm={12} xs={24}>
                            <Card className="mt-2 mx-1">
                                <Title level={5}>Contact Details</Title>
                                <Divider/>
                                <Row><Col lg={7} className="bold">Contact No.</Col><Col lg={1}>:</Col><Col lg={15}>{employeeDetails.contact_no}</Col></Row>
                                <Row><Col lg={7} className="bold">Email Address</Col><Col lg={1}>:</Col><Col lg={15}>{employeeDetails.email}</Col></Row>
                                <Row><Col lg={7} className="bold">Address</Col><Col lg={1}>:</Col><Col lg={15}>{employeeDetails.address}</Col></Row>
                            </Card>
                        </Col>
                        <Col lg={12} sm={12} xs={24}>
                            <Card className="mt-2 mx-1">
                                <Title level={5}>Emergency Contact</Title>
                                <Divider/>
                                <Row><Col lg={7} className="bold">Contact Person</Col><Col lg={1}>:</Col><Col lg={15}>{employeeDetails.contact_number}</Col></Row>
                                <Row><Col lg={7} className="bold">Relationship</Col><Col lg={1}>:</Col><Col lg={15}>{capitalizeEachWord(employeeDetails.contact_person_relationship)}</Col></Row>
                                <Row><Col lg={7} className="bold">Contact No.</Col><Col lg={1}>:</Col><Col lg={15}>{employeeDetails.contact_number}</Col></Row>
                                <Row><Col lg={7} className="bold">Address</Col><Col lg={1}>:</Col><Col lg={15}>{capitalizeEachWord(employeeDetails.contact_address)}</Col></Row>
                            </Card>
                        </Col>
                    </Row>
                    <Card className="mx-1 mt-2">
                        <Title level={5}>Family Relationship Details</Title>
                        <Divider/>
                        <Row>
                            <Col lg={3} className="bold">Mother's Name</Col><Col lg={1}>:</Col><Col lg={6} >{capitalizeEachWord(employeeDetails.mothers_name)}</Col>
                            <Col lg={1} className="bold">Age</Col><Col lg={1}>:</Col><Col lg={2} >{employeeDetails.mothers_age}</Col>
                            <Col lg={2} className="bold">Occupation</Col><Col lg={1}>:</Col><Col lg={6} >{capitalizeEachWord(employeeDetails.mothers_occupation)}</Col>
                        </Row>
                        <Row>
                            <Col lg={3} className="bold">Father's Name</Col><Col lg={1}>:</Col><Col lg={6} >{capitalizeEachWord(employeeDetails.fathers_name)}</Col>
                            <Col lg={1} className="bold">Age</Col><Col lg={1}>:</Col><Col lg={2} >{employeeDetails.fathers_age}</Col>
                            <Col lg={2} className="bold">Occupation</Col><Col lg={1}>:</Col><Col lg={6} >{capitalizeEachWord(employeeDetails.fathers_occupation)}</Col>
                        </Row>
                        <Row>
                            <Col lg={3} className="bold">Spouse's Name</Col><Col lg={1}>:</Col><Col lg={6} >{capitalizeEachWord(employeeDetails.spouse_name)}</Col>
                            <Col lg={1} className="bold">Age</Col><Col lg={1}>:</Col><Col lg={2} >{employeeDetails.spouse_age}</Col>
                            <Col lg={2} className="bold">Occupation</Col><Col lg={1}>:</Col><Col lg={6} >{capitalizeEachWord(employeeDetails.spouse_occupation)}</Col>
                        </Row>
                        {
                            employeeDetails.children && employeeDetails.children.map((row) => (
                                <Row>
                                    <Col lg={3} md={6} xs={11} className="bold">Child Name</Col><Col lg={1}>:</Col><Col lg={6} md={17} xs={12} >{capitalizeEachWord(row.name)}</Col>
                                    <Col lg={1} md={3} xs={3} className="bold">Age</Col><Col lg={1}>:</Col><Col lg={2} xs={3}>{row.age}</Col>
                                    <Col lg={2} className="bold">Occupation</Col><Col lg={1}>:</Col><Col lg={6} >{capitalizeEachWord(row.occupation)}</Col>
                                </Row>
                            ))
                        }
                    </Card>
                    <Row>
                        <Col lg={12} sm={12} xs={24}>
                            <Card className="mt-2 mx-1">
                                <Title level={5}>Employee Benefit Contribution Information</Title>
                                <Divider/>
                                <Row><Col lg={6} className="bold">SSS No.</Col><Col lg={1}>:</Col><Col lg={15} className="ms-2">{employeeDetails.sss}</Col></Row>
                                <Row><Col lg={6} className="bold">HDMF No.</Col><Col lg={1}>:</Col><Col lg={15} className="ms-2">{employeeDetails.hdmf}</Col></Row>
                                <Row><Col lg={6} className="bold">PhilHealth No.</Col><Col lg={1}>:</Col><Col lg={15} className="ms-2">{employeeDetails.phic}</Col></Row>
                                <Row><Col lg={6} className="bold">Intellicare No.</Col><Col lg={1}>:</Col><Col lg={15} className="ms-2">{employeeDetails.intellicare_number}</Col></Row>
                                <Row><Col lg={6} className="bold">AXA Policy No.</Col><Col lg={1}>:</Col><Col lg={15} className="ms-2">{employeeDetails.axa_policy_number}</Col></Row>
                            </Card>
                        </Col>
                        <Col lg={12} sm={12} xs={24}>
                            <Card className="mt-2 mx-1">
                                <Title level={5}>Withholding Tax Information</Title>
                                <Divider/>
                                <Row><Col lg={6} className="bold">TIN</Col><Col lg={1}>:</Col><Col lg={15} className="ms-2">{employeeDetails.tin}</Col></Row>
                                <Row><Col lg={6} className="bold">Status</Col><Col lg={1}>:</Col><Col lg={15} className="ms-2">{employeeDetails.wtaxstatus}</Col></Row>
                                <Row><Col lg={6} className="bold">Dependent</Col><Col lg={1}>:</Col><Col lg={15} className="ms-2">{employeeDetails.dependent}</Col></Row>
                            </Card>
                        </Col>
                    </Row>
                    <Card className="mx-1 mt-2">
                        <Title level={5}>Employment Information</Title>
                        <Divider/>
                        <Row>
                        <Col lg={12} xs={24}>
                            <Row><Col lg={8} xs={7} className="bold">DTR ID</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.dtr_id}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Branch</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{capitalizeEachWord(employeeDetails.branch_name)}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Position</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.position_name==="CEO"?employeeDetails.position_name:capitalizeEachWord(employeeDetails.position_name)}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Salary</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">PHP {employeeDetails.salary?formatAmount(employeeDetails.salary[0]?.salary_to):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Salary Type</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.salary?capitalizeEachWord(employeeDetails.salary[0]?.salary_type):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Bank</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.bank}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Bank Account Name</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{capitalizeEachWord(employeeDetails.bank_account_name)}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Bank Account Number</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.bank_account_number}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Employee Status</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{capitalizeEachWord(employeeDetails.employee_status)}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Employment Status</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{capitalizeEachWord(employeeDetails.employment_status_name)}</Col></Row>
                        </Col>
                        <Col lg={12} xs={24}>
                            <Row><Col lg={8} xs={7} className="bold">Business Unit</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{capitalizeEachWord(employeeDetails.business_unit_name)}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Department</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{capitalizeEachWord(employeeDetails.department_name)}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Immediate Head</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{capitalizeEachWord(employeeDetails.immediate_head_name)}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Current Shift</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{capitalizeEachWord(employeeDetails.shift_name)}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Project</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{capitalizeEachWord(employeeDetails.project_name)}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Date Hired</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{formatDate(employeeDetails.date_hired)}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">End of Contract</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.end_of_contract?formatDate(employeeDetails.end_of_contract):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Date Regularized</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.date_regularized?formatDate(employeeDetails.date_regularized):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Previous Employer</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{capitalizeEachWord(employeeDetails.previous_employer)}</Col></Row>
                        </Col>
                       
                        </Row>
                    </Card>
                    <Card className="mx-1 mt-2">
                        <Title level={5}>Allowances</Title>
                        <Divider/>
                        <Row>
                        <Col lg={12} sm={12} xs={24}>
                            <Row><Col lg={8} xs={7} className="bold">Daily</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.allowance?formatAmount(employeeDetails.allowance[0]?.daily_allowance):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Communication</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.allowance?formatAmount(employeeDetails.allowance[0]?.commu_allowance):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Transportation</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.allowance?formatAmount(employeeDetails.allowance[0]?.transpo_allowance):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Food</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.allowance?formatAmount(employeeDetails.allowance[0]?.food_allowance):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Grocery</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.allowance?formatAmount(employeeDetails.allowance[0]?.grocery_allowance):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Entertainment</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.allowance?formatAmount(employeeDetails.allowance[0]?.entertainment_allowance):''}</Col></Row>
                        </Col>
                        <Col lg={12} sm={12} xs={24}>
                            <Row><Col lg={8} xs={7} className="bold">HMO</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.allowance?formatAmount(employeeDetails.allowance[0]?.hmo_allowance):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Tech</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.allowance?formatAmount(employeeDetails.allowance[0]?.tech_allowance):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">OPS</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.allowance?formatAmount(employeeDetails.allowance[0]?.ops_allowance):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Special</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.allowance?formatAmount(employeeDetails.allowance[0]?.special_allowance):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Medicine</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.allowance?formatAmount(employeeDetails.allowance[0]?.medicine_allowance):''}</Col></Row>
                            <Row><Col lg={8} xs={7} className="bold">Uniform</Col><Col lg={1} xs={1}>:</Col><Col lg={14} xs={14} className="ms-2">{employeeDetails.allowance?formatAmount(employeeDetails.allowance[0]?.uniform_allowance):''}</Col></Row>
                        </Col>
                        </Row>
                    </Card>
                    <Card className="mx-1 mt-2">
                        <Title level={5}>Employment History</Title>
                        <Divider/>
                        <Table dataSource={employmentHistory} columns={employmentHistoryTable}/>
                    </Card>
                </Col>
            </Row>
        </Navbar>
    )
}