import React, { useState, useEffect } from "react";
import { UseGetRequest, UsePostRequest } from "./ApiRequests";
import { capitalizeEachWord } from "../Common";


export const GetAllBranches = () => {
  const [data, setData] = useState([]);
    const defaultValue = {
        id: '',
        value: '',
        label: 'All Branches',
        name: 'All Branches'
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("branches/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllEmploymentStatus = () => {
  const [data, setData] = useState([]);
    const defaultValue = {
        id: '',
        value: '',
        label: 'All Statuses',
        name: 'All Statuses',
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("employment_statuses/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
  // Code above is for when the company's employment status is dynamic but for Greencycle, it is fixed
  // const employmenStatus = [
  //   { value: '', label: 'All'},
  //   { value: 'blacklisted', label: 'Blacklisted'},
  //   { value: 'eoc', label: 'End of Contract'},
  //   {label: 'Regular', value: 'regular'},
  //   {label: 'Resigned', value: 'resigned'},
  //   {label: 'Probationary', value: 'probationary'},
  //   {label: 'Project-based', value: 'project_based'},
  //   {label: 'Terminated', value: 'terminated'},
  // ];

  // return employmenStatus;
};

export const GetAllEmployeeStatus = () => {
  const [data, setData] = useState([]);
    const defaultValue = {
        id: '',
        value: '',
        label: 'All Statuses',
        name: 'All Statuses',
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("employee_statuses/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};


export const GetAllPositions = () => {
  const [data, setData] = useState([]);
    const defaultValue = {
        id: '',
        value: '',
        label: 'All Positions',
        name: 'All Positions'
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("positions/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllBusinessUnits = () => {
  const [data, setData] = useState([]);
    const defaultValue = {
        id: '',
        value: '',
        label: 'All Business Units',
        name: 'All Business Units'
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("business_units/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllProjects = () => {
  const [data, setData] = useState([]);
    const defaultValue = {
        id: '',
        value: '',
        label: 'All Projects',
        name: 'All Projects'
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("projects/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllDepartments = () => {
  const [data, setData] = useState([]);
    const defaultValue = {
        id: '',
        value: '',
        label: 'All Departments',
        name: 'All Departments'
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("departments/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllEmployees = () => {
  const [data, setData] = useState([]);
    const defaultValue = {
        id: '',
        value: '',
        label: 'All Employees',
        name: 'All Employees'
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UsePostRequest("employees/get_all", {});
        if (response.data) {
          const res = response.data.map((row) => {
            var tempName = `${row.first_name} ${row.middle_name} ${row.last_name}`;
            return {
              id: row.id,
              value: row.id,
              name: capitalizeEachWord(tempName),
              label: capitalizeEachWord(tempName),
              dtr_id: row.dtr_id,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllShifts = () => {
  const [data, setData] = useState([]);
    const defaultValue = {
        id: '',
        value: '',
        label: 'All Shifts',
        name: 'All Shifts'
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("shifts/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllRoles = () => {
  const [data, setData] = useState([]);
    const defaultValue = {
        id: '',
        value: '',
        label: 'All Shifts',
        name: 'All Shifts'
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await UseGetRequest("roles/get", {});
        if (response.data) {
          const res = response.data.data.map((row) => {
            return {
              id: row.id,
              value: row.id,
              name: row.name,
              label: row.name,
            };
          });
          setData([defaultValue, ...res]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return data;
};

export const GetAllSalaryTypes = () => {
  const salaryTypes = [
    {value: '', label: 'All Salary Types'},
    {value: 'hourly', label: 'Hourly'},
    {value: 'daily5', label: 'Daily (5)'},
    {value: 'daily6', label: 'Daily (6)'},
    {value: 'monthly22', label: 'Monthly (22)'},
    {value: 'monthly26', label: 'Monthly (26)'},
  ]

  return salaryTypes;
}