import React, {useState, useRef} from "react";
import { Row, Col, Typography, Divider, Card } from "antd";
import { formatAmount, formatDate, getSum, formartNumberToWords, capitalizeEachWord } from "../../utils/Common";
import logo from "../../assets/images/logo.png";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import moment from "moment";
const {Title} = Typography;

// This is the payslip lang but the PDF version to be downloaded. 
// Will find way to use the Payslip file but for now this one should be separated lang sa
// due to the ways payroll_ids are passed from each files

const PayslipPdf = (props) => {
    const dateToday = new Date();
    // const location = useLocation();
    const componentRef = useRef();
    const payrollId = props.payroll_id;
    const [payrollData, setPayrollData] = useState({});
    const [signature, setSignature] = useState(null);
    const [earningValues, setEarningValues] = useState([]);
    const [deductionValues, setDeductionValues] = useState([]);
    const earningLabels = [
        "Basic Pay",
        "Holiday Pay",
        "Night Differential",
        "Overtime",
        "Regular OT",
        "Restday OT",
        "Monthly",
        "Semi-monthly",
        "Salary Adjustment",
        "Special Holiday Pay",
        "Communication Allowance",
        "Daily Allowance",
        "Food Allowance",
        "HMO Allowance",
        "OPS Allowance",
        "Special Allowance",
        "Tech Allowance",
        "Transpo Allowance",
        "Training Allowance (F2F)",
        "Training Allowance (Virtual)",
        "Grocery Allowance",
        "Entertainment Allowance",
        "Medicine Allowance",
        "Uniform Allowance",
        "Thirteenth Month",
      ];
      const deductionLabels = [
        "Absent",
        "PhilHealth Contribution",
        "PhilHealth Loans",
        "PAGIBIG Contribution",
        "PAGIBIG Loans",
        "SSS Contribution",
        "SSS Loans",
        "SSS Calamity Loans",
        "SSS Salary Loans",
        "Calamity Loans",
        "Company Loans",
        "Cooperative Loans",
        "Cooperative Savings",
        "HMO Maxicare",
        "Undertime",
        "Late",
        "AXA",
        "Phone Loan",
        "Laptop Loan",
        "Cash Advance",
        "Undertime",
        "Withholding Tax",
        "Coop",
        "Others",
        "Intellicare Amount",
      ];

      function formatDateRange (dateFrom, dateTo) {
        if (moment(dateFrom).format('YYYY')===moment(dateTo).format('YYYY')) {
            return `${moment(dateFrom).format('MMMM DD')} - ${moment(dateTo).format('MMMM DD, YYYY')}`
        } else {
            return `${moment(dateFrom).format('MMMM DD, YYYY')} - ${moment(dateTo).format('MMMM DD, YYYY')}`
        }
      }

    React.useEffect(() => {
        props.setBulkPrintRef(componentRef.current);
    }, []);

    React.useEffect(() => {
        async function fetchPayrollData () {
            const response = await UsePostRequest("payrolls/get", {payroll_id: payrollId});
            if (response.data) {
              const payroll = response.data[0];
              const tempEarnings = [];
              const  tempdDeductions = [];
              var info = payroll;
              info.id = payroll.id;
              info.salary =  Number(payroll.basic_pay);
              info.date_from = payroll.date_from;
              info.date_to = payroll.date_to;
              info.restday_ot_mins = payroll.restday_ot_mins||0;
              info.regular_ot_mins = payroll.regular_ot_mins||0;
              info.undertime_mins = payroll.undertime_mins||0;
              info.late_mins = payroll.late_mins||0;

            // Store all earnings in the earningValues state value
              tempEarnings.push(
                payroll.basic_pay,
                payroll.holiday_pay,
                payroll.night_differential,
                payroll.overtime,
                payroll.regular_ot,
                payroll.restday_ot,
                payroll.monthly,
                payroll.semi_monthly,
                payroll.salary_adj,
                payroll.special_holiday_pay,
                payroll.comm_allowance,
                payroll.daily_allowance,
                payroll.food_allowance,
                payroll.hmo_allowance,
                payroll.ops_allowance,
                payroll.special_allowance,
                payroll.tech_allowance,
                payroll.transpo_allowance,
                payroll.training_allowance_f2f,
                payroll.training_allowance_virtual,
                payroll.grocery_allowance,
                payroll.entertainment_allowance,
                payroll.medicine_allowance,
                payroll.uniform_allowance
              );
            // Include thirteenth month to earnings if it was set to true during payroll generation
              if (payroll.is_thirteenth_included === "1") {
                tempEarnings.push(payroll.thirteenth_month);
              } 
            // Store all deductions in the deductionValues state value
            tempdDeductions.push(
                payroll.absent,
                payroll.philhealth_contribution,
                payroll.philhealth_loans,
                payroll.pagibig_contribution,
                payroll.pagibig_loan,
                payroll.sss_contribution,
                payroll.sss_loans,
                payroll.sss_calamity_loan,
                payroll.sss_salary_loan,
                payroll.calamity_loan,
                payroll.company_loan,
                payroll.cooperative_loans,
                payroll.cooperative_savings,
                payroll.hmo_maxicare,
                payroll.undertime_amount,
                payroll.late,
                payroll.axa_amount,
                payroll.phone_loan,
                payroll.laptop_loan,
                payroll.cash_advance,
                payroll.undertime,
                payroll.wth_tax,
                payroll.coop,
                payroll.others,
                payroll.intellicare_amount
              );
              
              info.total_earnings = getSum(tempEarnings);
              setEarningValues(tempEarnings);
              info.total_deductions = getSum(tempdDeductions);
              setDeductionValues(tempdDeductions);
              info.total_net_pay = info.total_earnings - info.total_deductions;
              setPayrollData(info);
            }
        }

        fetchPayrollData();
    }, [])

    return (
      <div ref={componentRef} className={`m-5 payslip-container ${props.hide}`}>
        <Card className="sharp-edge bordered pdf-payslip">
          <Row className="sharp-edge bordered pdf-payslip">
            <Col xs={20} md={14} lg={20} className="border-right">
              <Row className="border-bottom center-text py-2">
                <Title level={4}>SALARY SLIP</Title>
              </Row>
              <Row className="black-border center-text py-2">
                {formatDateRange(payrollData.date_from, payrollData.date_to)}
              </Row>
            </Col>
            <Col xs={4} md={10} lg={4} className="center-text secondary-bg">
              <Title level={4}>CONFIDENTIAL</Title>
            </Col>
          </Row>

          <Row className="bordered">
            <Col xs={12} md={12} className="border-right p-2">
              <Row>
                <Col xs={10} md={10}>
                  Name
                </Col>
                : {capitalizeEachWord(payrollData.employee_name)}
              </Row>
              <Row>
                <Col xs={10} md={10}>
                  Employee ID
                </Col>
                : {payrollData.employee_id}
              </Row>
            </Col>
            <Col xs={12} md={12} className="border-right p-2">
              <Row>
                <Col xs={10} md={10} lg={8}>
                  Position
                </Col>
                : {payrollData.position_name}
              </Row>
              <Row>
                <Col xs={10} md={10} lg={8}>
                  Status of Employment
                </Col>
                : {payrollData.employment_status_name}
              </Row>
              <Row>
                <Col xs={10} md={10} lg={8}>
                  Department
                </Col>
                : {payrollData.department_name}
              </Row>
            </Col>
          </Row>

          <Row className="bordered">
            <Col
              xs={12}
              className="center-text white-text primary-bg p-1 border-right"
            >
              Description
            </Col>
            <Col
              xs={6}
              className="center-text white-text primary-bg p-1 border-right"
            >
              Earnings
            </Col>
            <Col xs={6} className="center-text white-text primary-bg p-1">
              Deductions
            </Col>
          </Row>

          <Row className="bordered">
            <Col xs={12} className="p-2 border-right">
              {earningLabels.map((label, index) => {
                if (earningValues[index] && Number(earningValues[index]) > 0) {
                  return <Row>{label}</Row>;
                }
              })}
              {deductionLabels.map((label, index) => {
                if (
                  earningValues[index] &&
                  Number(deductionValues[index]) > 0
                ) {
                  return <Row>{label}</Row>;
                }
              })}
            </Col>
            <Col xs={6} className="p-2 border-right">
              {earningValues.map((value) => {
                if (value && Number(value) > 0) {
                  return (
                    <Row className="justify-content-end">
                      {formatAmount(value)}
                    </Row>
                  );
                }
              })}
            </Col>
            <Col xs={6} className="p-2">
              {earningValues.map((value) => {
                if (value && Number(value) > 0) {
                  return <br></br>;
                }
              })}
              {deductionValues.map((value) => {
                if (value && Number(value) > 0) {
                  return (
                    <Row className="justify-content-end">
                      {formatAmount(value)}
                    </Row>
                  );
                }
              })}
            </Col>
          </Row>

          <Row className="bordered">
            <Col xs={12} className="p-2 border-right">
              Total
            </Col>
            <Col
              xs={6}
              className="p-2 d-flex justify-content-end border-right bold"
            >
              {formatAmount(payrollData.total_earnings)}
            </Col>
            <Col xs={6} className="p-2 d-flex justify-content-end bold">
              {formatAmount(payrollData.total_deductions)}
            </Col>
          </Row>

          <Row className="bordered">
            <Col xs={12} md={12} lg={12} className="border-right p-2">
              <Row>
                <Col xs={10} md={10}>
                  Payment Date
                </Col>
                : {formatDate(dateToday)}
              </Row>
              <Row>
                <Col xs={10} md={10}>
                  Bank Name
                </Col>
                : {payrollData.bank}
              </Row>
              <Row>
                <Col xs={10} md={10}>
                  Bank Account Name
                </Col>
                : {payrollData.bank_account_name}
              </Row>
              <Row>
                <Col xs={10} md={10}>
                  Bank Account #
                </Col>
                : {payrollData.bank_account_number}
              </Row>
            </Col>
            <Col xs={12} md={12} lg={12} className="p-0">
              <Row className="center-text primary-bg white-text p-1">
                NET PAY
              </Row>
              <Row className="center-text secondary-bg bold p-1">
                {formatAmount(payrollData.total_net_pay)}
              </Row>
              <Row className="center-text p-2">
                <i>{formartNumberToWords(payrollData.total_net_pay)}</i>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    );
}

export default PayslipPdf;