import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import { Row, Table, Collapse, DatePicker } from "antd";
import Title from "antd/es/typography/Title";
import { Container } from "react-bootstrap";
import Navbar from "../../components/Navbar/Navbar";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import { capitalizeEachWord, formatDate, formatShortDate } from "../../utils/Common";
import moment from "moment";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
//   Title,
  Tooltip,
  Legend,
} from "chart.js";

const {Panel} = Collapse;
const {RangePicker} = DatePicker;

export default function EmployeeHistoryReport () {
    const location = useLocation();
    const employeeData = location.state;
    const [leaveHistoryData, setLeaveHistoryData] = useState([]);
    const [wfaHistoryData, setWfaHistoryData] = useState([]);
    const [absentHistoryData, setAbsentHistoryData] = useState([]);
    const [presentHistoryData, setPresentHistoryData] = useState([]);
    const [regOtHistoryData, setRegOtHistoryData] = useState([]);
    const [restDayOtHistoryData, setRestDayOtHistoryData] = useState([]);
    const [lateHistoryData, setLateHistoryData] = useState([]);
    const [undertimeHistoryData, setUndertimeHistoryData] = useState([]);
    const dateToday = moment().format('YYYY-MM-DD');
    
    const [filterConfig, setFilterConfig] = useState({
        date_from: employeeData.date_from || new Date(),
        date_to: employeeData.date_to || new Date(),
        employee_id: employeeData.id,
    });

    const handleDateFilter = (e) => {
        if (e) {
            setFilterConfig((prev) => ({...prev,
                date_from: e[0].format('YYYY-MM-DD'),
                date_to: e[1].format('YYYY-MM-DD'),
            }));
        }
      }

    const tableColumns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => (
                <div>{formatDate(date)}</div>
            )
        },
      ];

    function getMergeData (object) {
        var temp_array = []
        temp_array = object.map((data) => {
            var info = {}
            info.x = moment(data[0], 'MMM D, YYYY').toDate()
            info.y = Number(data[1])
            return info;
        })
        return temp_array;
    }

    const regOtDataset = getMergeData(regOtHistoryData);
    const restDayOtDataset = getMergeData(restDayOtHistoryData);
    const lateDataset = getMergeData(lateHistoryData);
    const undertimeDataset = getMergeData(undertimeHistoryData);      

    // Get the unique set of dates from both datasets
    const allDatesSet = new Set([...regOtDataset.map(data => data.x), ...restDayOtDataset.map(data => data.x), ...lateDataset.map(data => data.x), ...undertimeDataset.map(data => data.x)]);
    const allDates = Array.from(allDatesSet).sort((a, b) => a - b);

    // Create aligned datasets with null values for missing dates
    const regOtLineData = allDates.map(date => {
        const data = regOtDataset.find(item => item.x === date);
        return data ? { x: formatShortDate(date), y: data.y } : { x: formatShortDate(date), y: 0 };
    });

    const restDayOtLineData = allDates.map(date => {
        const data = restDayOtDataset.find(item => item.x === date);
        return data ? { x: formatShortDate(date), y: data.y } : { x: formatShortDate(date), y: 0 };
    });

    const lateLineData = allDates.map(date => {
        const data = lateDataset.find(item => item.x === date);
        return data ? { x: formatShortDate(date), y: data.y } : { x: formatShortDate(date), y: 0 };
    });

    const undertimeLineData = allDates.map(date => {
        const data = undertimeDataset.find(item => item.x === date);
        return data ? { x: formatShortDate(date), y: data.y } : { x: formatShortDate(date), y: 0 };
    });

    const chartOptions = {
        scales: {
        y: {
            beginAtZero: true,
            title: {
            display: true,
            text: 'No. of Minutes', // Replace with your desired label text
            },
        },
        },
    };

    const lineData = {
        datasets: [
            {
                label: 'Regular OT',
                data: regOtLineData,
                borderColor: 'red',
                fill: false,
                lineTension: 0.1,
            },
            {
                label: 'Rest Day OT',
                data: restDayOtLineData,
                borderColor: 'orange',
                fill: false,
                lineTension: 0.1
            },
            {
                label: 'Late',
                data: lateLineData,
                borderColor: 'green',
                fill: false,
                lineTension: 0.1
            },
            {
                label: 'Undertime',
                data: undertimeLineData,
                borderColor: 'blue',
                fill: false,
                lineTension: 0.1
            },
        ],
    };

    React.useEffect(() => {
        async function fetchHistoryData () {
            setLeaveHistoryData([]);
            setWfaHistoryData([]);
            setAbsentHistoryData([]);
            setPresentHistoryData([]);
            setRegOtHistoryData([]);
            setRestDayOtHistoryData([]);
            setLateHistoryData([]);
            setUndertimeHistoryData([]);

            const response = await UsePostRequest("timesheets/performance_evaluation_details", filterConfig);
            if (response.data) {
                const res = response.data[0];
          
                if (res.leave_dates) {
                    const tempArray = res.leave_dates.map((item) => {
                        var info = {}
                        info.date = formatDate(item);
                        return info;
                    })
                    setLeaveHistoryData(tempArray);
                }

                if (res.wfa_dates) {
                    const tempArray = res.wfa_dates.map((item) => {
                        var info = {}
                        info.date = formatDate(item);
                        return info;
                    })
                    setWfaHistoryData(tempArray);
                }

                if (res.absent_dates) {
                    const tempArray = res.absent_dates.map((item) => {
                        var info = {}
                        info.date = formatDate(item);
                        return info;
                    })
                    setAbsentHistoryData(tempArray);
                }

                if (res.present_dates) {
                    const tempArray = res.present_dates.map((item) => {
                        var info = {}
                        info.date = formatDate(item);
                        return info;
                    })
                    setPresentHistoryData(tempArray);
                }

                if (res.regular_ot_dates) {
                    const tempArray = res.regular_ot_dates.map((item) => {
                        let temp = []
                        temp.push(formatShortDate(item.regular_ot_date), item.regular_ot_mins);
                        return temp;
                    })
                    setRegOtHistoryData(tempArray);
                }

                if (res.restday_ot_dates) {
                    const tempArray = res.restday_ot_dates.map((item) => {
                        let temp = []
                        temp.push(formatShortDate(item.restday_ot_date), item.restday_ot_mins);
                        return temp;
                    })
                    setRestDayOtHistoryData(tempArray);
                }

                if (res.late_dates) {
                    const tempArray = res.late_dates.map((item) => {
                        let temp = []
                        temp.push(formatShortDate(item.late_date), item.late_mins);
                        return temp;
                    })
                    setLateHistoryData(tempArray);
                }

                if (res.undertime_dates) {
                    const tempArray = res.undertime_dates.map((item) => {
                        let temp = []
                        temp.push(formatShortDate(item.undertime_date), item.undertime_mins);
                        return temp;
                    })
                    setUndertimeHistoryData(tempArray);
                }
            }
        }

        fetchHistoryData();
    }, [filterConfig])

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        ArcElement,
        // Title,
        Tooltip,
        Legend
    );

    return (
        <Navbar formPageTitle="Employee History Report" isForm>
            <Container>
                <Row>
                    <Title level={4}>Employee: {capitalizeEachWord(employeeData.fullname)}</Title>
                </Row>
                <Row className="d-flex justify-content-end mt-3 mb-5">
                    <RangePicker style={{width: 500}} onChange={handleDateFilter} placeholder={[filterConfig.date_from, filterConfig.date_to]}/>
                </Row>
                <Line data={lineData} options={chartOptions} className='line-chart'/>
                <Collapse accordion className="my-5 custom-collapse">
                    <Panel header="Leave" key="leave">
                        <Table dataSource={leaveHistoryData} columns={tableColumns}/>
                    </Panel>
                    <Panel header="Work From Anywhere" key="wfa">
                        <Table dataSource={wfaHistoryData} columns={tableColumns}/>
                    </Panel>
                    <Panel header="Absent" key="absent">
                        <Table dataSource={absentHistoryData} columns={tableColumns}/>
                    </Panel>
                    <Panel header="Present" key="present">
                        <Table dataSource={presentHistoryData} columns={tableColumns}/>
                    </Panel>
                    <Panel header="Regular OT" key="regular_ot">
                        <Table dataSource={regOtHistoryData} columns={tableColumns}/>
                    </Panel>
                    <Panel header="Restday OT" key="restday_ot">
                        <Table dataSource={restDayOtHistoryData} columns={tableColumns}/>
                    </Panel>
                    <Panel header="Late" key="late">
                        <Table dataSource={lateHistoryData} columns={tableColumns}/>
                    </Panel>
                    <Panel header="Undertime" key="undertime">
                        <Table dataSource={undertimeHistoryData} columns={tableColumns}/>
                    </Panel>
                </Collapse>
            </Container>
        </Navbar>
    )
}