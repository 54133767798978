import React, {useState} from "react";
import {Form, Button, Row, Card, DatePicker} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { usePostRequest } from "../../hooks/usePostRequest";

const AddSubmitButton = ({ form, isLoading }) => {
    const [submittable, setSubmittable] = useState(false);
    const values = Form.useWatch([], form);
    
    React.useEffect(() => {
      form
        .validateFields({
          validateOnly: true,
        })
        .then(
          () => {
            setSubmittable(true);
          },
          () => {
            setSubmittable(false);
          }
        );
    }, [values]);
  
    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={!submittable}
        loading={isLoading}
      >
        Submit
      </Button>
    );
  };


export default function EvaluationFormInputPage () {
    const [form] = Form.useForm()
    const location = useLocation();
    const navigate = useNavigate();
    const addForm = location.state.add;

    const {callPost, isLoading} = usePostRequest(() => navigate("/evaluation-forms"));


    const onFinish = (formInputs) => {
        callPost(
            "forms/add", 
            formInputs,
            false
        );
    };

    const onEdit = (formInputs) => {
        callPost(
        `forms/update/${formInputs.id}`, 
        formInputs,
        false
        );
    };

    return (
        <Navbar formTitle={addForm?'Create Evaluation Form':'Edit Evaluation Form'} isForm>
            <Form
                className="mt-3 mx-3"
                form={form}
                name="validateOnly"
                layout="vertical"
                onFinish={addForm?onFinish:onEdit}
            >
                <Form.Item>
                    <Row className="d-flex justify-content-end">
                    <Row>
                        <Button
                        className="me-2"
                        danger
                        onClick={() => {
                            navigate("/evaluation-forms");
                        }}
                        disabled={isLoading}
                        >
                        Cancel
                        </Button>
                        <AddSubmitButton form={form} isLoading={isLoading} />
                    </Row>
                    </Row>
                </Form.Item>
            </Form>
        </Navbar>
    )
}