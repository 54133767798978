import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  HomeOutlined,
  ArrowLeftOutlined,
  TableOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  SettingOutlined,
  BarChartOutlined,
  MoneyCollectOutlined,
  ClockCircleOutlined,
  ScheduleOutlined,
  NotificationOutlined,
  MessageOutlined,
  PrinterOutlined,
  DownOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Layout,
  Menu,
  Modal,
  Row,
  Space,
  theme,
} from "antd";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TabContext } from "../../context/TabContext";
import Title from "antd/es/typography/Title";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import { removeUserSession } from "../../utils/api/Auth";
import { refreshPage, getIsAdminLogin } from "../../utils/Common";
import '../../../src/cssFiles/CustomStyles/Navbar.css';
import moment from "moment";
import dayjs from "dayjs";
import logo from "../../assets/images/logo.png";

const { Content, Sider } = Layout;
const { RangePicker } = DatePicker;
const { SubMenu } = Menu;

const Navbar = (props) => {
  const { activeTab, setActiveTab } = useContext(TabContext);
  const [collapsed, setCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isAdminLogin = getIsAdminLogin();
  const dateToday = moment().format("YYYY-MM-DD");
  const currentYear = dayjs().year();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [modal, contextHolder] = Modal.useModal();
  const [openKeys, setOpenKeys] = useState([]);
  const [showManage, setShowManage] = useState(false);

  const handleMenuSelect = (key) => {
    setActiveTab(key);
  };

  async function handleLogout() {
    setIsLoading(true);
    removeUserSession();
    refreshPage();
    setIsLoading(false);
  }

  const confirm = () => {
    modal.confirm({
      title: "Are you sure you want to logout?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Logout",
      cancelText: "Cancel",
      onOk: handleLogout,
    });
  };

  const adminItems = [
    {
      key: 0,
      icon: <HomeOutlined />,
      label: "Dashboard",
      path: "dashboard",
    },
    {
      key: 1,
      icon: <MoneyCollectOutlined />,
      label: "Payroll",
      path: "payrolls",
    },
    {
      key: 2,
      icon: <TableOutlined />,
      label: "13th Month Report",
      path: "thirteenth-month-report",
    },
    {
      key: 3,
      icon: <ClockCircleOutlined />,
      label: "Timesheet",
      path: "timesheet",
    },
    {
      key: 4,
      icon: <ScheduleOutlined />,
      label: "Schedule",
      path: "schedules",
    },
    {
      key: 5,
      icon: <NotificationOutlined />,
      label: "Memo",
      path: "memorandums",
    },
    {
      key: 6,
      icon: <NotificationOutlined />,
      label: "Announcement",
      path: "announcements",
    },
    {
      key: 7,
      icon: <MessageOutlined />,
      label: "Communication",
      path: "communication-letters",
    },
    {
      key: 8,
      icon: <UserOutlined />,
      label: "Leave",
      path: "leaves",
    },
    {
      key: 9,
      icon: <UserOutlined />,
      label: "WFA",
      path: "wfas",
    },
    // {
    //   key: 10,
    //   icon: <FormOutlined />,
    //   label: "Form",
    //   path: 'evaluation-forms',
    // },
    {
      key: 11,
      icon: <MoneyCollectOutlined />,
      label: "Loan",
      path: "loans",
    },
    {
      key: 12,
      icon: <BarChartOutlined />,
      label: "Performance Evaluation",
      path: "performance-evaluation",
    },
    {
      key: 13,
      icon: <UserOutlined />,
      label: "Contribution Report",
      submenu: [
        { key: "phic", label: "PhilHealth", path: "phic-contribution-report" },
        { key: "sss", label: "SSS", path: "sss-contribution-report" },
        { key: "hdmf", label: "Pag-IBIG", path: "hdmf-contribution-report" },
      ],
    },
    {
      key: 14,
      icon: <UserOutlined />,
      label: "Manage Employee",
      submenu: [
        { key: "employee", label: "Employee", path: "employees" },
        // {key: 'employee-deduction', label: 'Employee Deduction', path: 'employee-deductions'},
        {
          key: "employment-status",
          label: "Employment Status",
          path: "employment-status",
        },
        {
          key: "employee-status",
          label: "Employee Status",
          path: "employee-status",
        },
        { key: "leave-credits", label: "Leave Credits", path: "leave-credits" },
        { key: "wfa-credits", label: "WFA Credits", path: "wfa-credits" },
      ],
    },
    {
      key: 15,
      icon: <SettingOutlined />,
      label: "Manage System",
      submenu: [
        { key: "branch", label: "Branch", path: "branches" },
        {
          key: "business-unit",
          label: "Business Unit",
          path: "business-units",
        },
        { key: "company-files", label: "Company Files", path: "company-files" },
        { key: "department", label: "Department", path: "departments" },
        { key: "holiday", label: "Holiday", path: "holidays" },
        { key: "leave-type", label: "Leave Type", path: "leave-types" },
        { key: "letter-type", label: "Letter Type", path: "letter-types" },
        {
          key: "night-differential",
          label: "Night Differential",
          path: "night-differentials",
        },
        { key: "position", label: "Position", path: "positions" },
        { key: "project", label: "Project", path: "projects" },
        { key: "requirement", label: "Requirement", path: "requirements" },
        // {key: 'hdmf-contribution', label: 'Pag-IBIG Contribution', path: 'hdmf-contributions'},
        // {key: 'phic-contribution', label: 'PhilHealth', path: 'phic-contributions'},
        // {key: 'sss-contribution', label: 'SSS Contribution', path: 'sss-contributions'},
        { key: "shift", label: "Shift", path: "shifts" },
      ],
    },
    // {
    //   key: 16,
    //   icon: <MoneyCollectOutlined />,
    //   label: "Support",
    //   path: 'https://support.mytdevcorp.com/',
    // },
  ];

  const employeeItems = [
    {
      key: 0,
      icon: <HomeOutlined />,
      label: "Dashboard",
      path: "dashboard",
    },
    {
      key: 1,
      icon: <MoneyCollectOutlined />,
      label: "Payroll",
      path: "payrolls",
    },
    {
      key: 2,
      icon: <TableOutlined />,
      label: "13th Month Report",
      path: "thirteenth-month-report",
    },
    {
      key: 3,
      icon: <ClockCircleOutlined />,
      label: "Timesheet",
      path: "timesheet",
    },
    // {
    //     key: 4,
    //     icon: <ScheduleOutlined />,
    //     label: "Schedule",
    //     path: 'schedules',
    // },
    {
      key: 5,
      icon: <NotificationOutlined />,
      label: "Memo",
      path: "memorandums",
    },
    {
      key: 6,
      icon: <NotificationOutlined />,
      label: "Announcement",
      path: "announcements",
    },
    {
      key: 7,
      icon: <MessageOutlined />,
      label: "Communication",
      path: "communication-letters",
    },
    {
      key: 8,
      icon: <UserOutlined />,
      label: "Leave",
      path: "leaves",
    },
    {
      key: 9,
      icon: <UserOutlined />,
      label: "WFA",
      path: "wfas",
    },
    // {
    //   key: 10,
    //   icon: <FormOutlined />,
    //   label: "Form",
    //   path: 'evaluation-forms',
    // },
    {
      key: 11,
      icon: <MoneyCollectOutlined />,
      label: "Loan",
      path: "loans",
    },
    {
      key: 12,
      icon: <BarChartOutlined />,
      label: "Performance Evaluation",
      path: "performance-evaluation",
    },
    {
      key: 13,
      icon: <UserOutlined />,
      label: "Manage Employee",
      submenu: [
        { key: "employee", label: "Employee", path: "employees" },
        { key: "leave-credits", label: "Leave Credits", path: "leave-credits" },
        { key: "wfa-credits", label: "WFA Credits", path: "wfa-credits" },
      ],
    },
  ];

  const menuItems = isAdminLogin ? adminItems : employeeItems;

  const handleSupportClick = () => {
    window.open("https://support.mytdevcorp.com/", "_blank");
  };

  return (
    <Layout style={{ height: "100vh" }} className="">
      <Sider
        breakpoint="md"
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="menu-cont"
      >
        <div className="demo-logo-vertical" />
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            color: "white",
            fontSize: "16px",
            width: 64,
            height: "auto",
          }}
        />
        <div className="d-flex justify-content-center">
          <img src={logo} alt="" className="navbar-logo" />
        </div>
        <Menu
          // theme="dark"
          mode="inline"
          selectedKeys={[activeTab]}
          onClick={({ key }) => handleMenuSelect(key)}
          openKeys={openKeys}
          onOpenChange={(keys) => setOpenKeys(keys)}
          className="navbar-menu-container"
        >
          {menuItems.map((item) =>
            item.submenu ? (
              <SubMenu
                key={item.key}
                title={
                  <>
                    {item.icon}
                    <span>{item.label}</span>
                  </>
                }
                onTitleClick={() => setShowManage(!showManage)} // Use item.key as the identifier for the submenu
                open={showManage === true} // Control the submenu's open state
              >
                {item.submenu.map((subItem) => (
                  <Menu.Item key={subItem.key}>
                    <Link
                      to={`/${subItem.path}`}
                      style={{ textDecoration: "none" }}
                    >
                      {subItem.label}
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.key}>
                <Link to={`/${item.path}`} style={{ textDecoration: "none" }}>
                  {item.icon}
                  <span>{item.label}</span>
                </Link>
              </Menu.Item>
            )
          )}

          <Menu.Item
            key="support"
            onClick={handleSupportClick}
            disabled={isLoading}
          >
            <CustomerServiceOutlined className="" />
            <span> Support</span>
          </Menu.Item>
          <Menu.Item
            key="logout"
            onClick={confirm}
            disabled={isLoading}
            className="mb-5"
          >
            <LogoutOutlined />
            <span>Logout</span>
          </Menu.Item>
          {contextHolder}
        </Menu>
      </Sider>

      <Layout className="main-content">
        <Content
          style={{
            flex: 1,
            padding: 24,
            background: colorBgContainer,
          }}
        >
          {props.isManager && (
            <Row style={{ alignItems: "center" }} justify="space-between">
              <Col>
                <Title level={3}>{props?.pageTitle || ""}</Title>
              </Col>
              <Col className="d-flex justify-content-center">
                <Space>
                  {props.withYearFilter && (
                    <DatePicker picker="year" style={{ width: 150 }} onChange={props.onYearChange} placeholder={currentYear}/>
                  )}
                  {props.withDateRangePicker && (
                    <RangePicker
                      style={{ width: 300 }}
                      onChange={props.onDateChange}
                      placeholder={[dateToday, dateToday]}
                    />
                  )}
                  {props.searchable && (
                    <Input
                      suffix={
                        <FontAwesomeIcon icon={icon({ name: "search" })} />
                      }
                      placeholder={"Search " + (props.searchPlaceholder || "")}
                      style={{ width: 300, padding: 5 }}
                      onChange={(e) => props?.setSearchQuery(e.target.value)}
                      onKeyDown={props?.onSearch}
                      allowClear
                    />
                  )}
                  {props.withPrintBtn && (
                    <ReactToPrint
                      trigger={() => (
                        <Button type="primary">
                          <PrinterOutlined /> Print
                        </Button>
                      )}
                      content={() => props.printContent.current}
                    />
                  )}
                  {props.isDownloadBtn && (
                    <Button
                      style={{ marginLeft: 8 }}
                      type="primary"
                      onClick={props?.onClickButton}
                      icon={<FontAwesomeIcon icon={faDownload} />}
                    >
                      {props?.pageButtonTitle || "Add"}
                    </Button>
                  )}
                  {props.noButton ? (
                    ""
                  ) : (
                    <Button
                      style={{ marginLeft: 8 }}
                      type="primary"
                      onClick={props?.onClickButton}
                      icon={<FontAwesomeIcon icon={faPlus} />}
                    >
                      {props?.pageButtonTitle || "Add"}
                    </Button>
                  )}
                  {props.printButtonTitle && (
                    <Button
                      style={{ marginLeft: 8 }}
                      type="primary"
                      onClick={props?.onClickButton}
                    >
                      <PrinterOutlined /> {props.printButtonTitle}
                    </Button>
                  )}
                  {props.actionDropdownButton && (
                    <Dropdown
                      overlay={
                        <Menu>
                          {props.actionDropdownOptions.map((opt) => (
                            <Menu.Item key={opt.value} onClick={opt.action}>
                              {opt.label}
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Button
                        style={{ marginRight: 8 }}
                        type="primary"
                        className=""
                        onClick={(e) => e.preventDefault()}
                      >
                        Select Action <DownOutlined />
                      </Button>
                    </Dropdown>
                  )}
                </Space>
              </Col>
            </Row>
          )}

          {props?.isForm ? (
            <>
              <Button
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <Content className="form-content">
                <Space className="d-flex justify-content-between">
                {props?.isForm && (
                  <Title level={2}>{props?.formTitle || ""}</Title>
                )}
                {props.withPrintBtn && (
                    <ReactToPrint
                      trigger={() => (
                        <Button type="primary">
                          <PrinterOutlined /> {props.printText}
                        </Button>
                      )}
                      content={() => props.printContent.current}
                    />
                  )}
                </Space>
                {props.children}
              </Content>
            </>
          ) : (
            <>{props.children}</>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};
export default Navbar;
