import React, {useState, useRef} from "react";
import { useParams } from "react-router-dom";
import { Row, Button, Col, Typography, Card } from "antd";
import { capitalizeEachWord, formatAmount, formatDate, getSum, formartNumberToWords } from "../../utils/Common";
import Navbar from "../../components/Navbar/Navbar";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";
const {Title} = Typography;


export default function Payslip () {
    const dateToday = new Date();
    // const location = useLocation();
    const componentRef = useRef();
    // const payrollId = location.state.passed_id;
    const params = useParams();
    const payrollId = params.id;
    const [payrollData, setPayrollData] = useState({});
    // const [signature, setSignature] = useState(null);
    const [earningValues, setEarningValues] = useState([]);
    const [deductionValues, setDeductionValues] = useState([]);
    const earningLabels = [
        "Basic Pay",
        "Holiday Pay",
        "Night Differential",
        "Overtime",
        "Regular OT",
        "Restday OT",
        "Monthly",
        "Semi-monthly",
        "Salary Adjustment",
        "Special Holiday Pay",
        "Communication Allowance",
        "Daily Allowance",
        "Food Allowance",
        "HMO Allowance",
        "OPS Allowance",
        "Special Allowance",
        "Tech Allowance",
        "Transpo Allowance",
        "Training Allowance (F2F)",
        "Training Allowance (Virtual)",
        "Grocery Allowance",
        "Entertainment Allowance",
        "Medicine Allowance",
        "Uniform Allowance",
        "Thirteenth Month",
      ];
      const deductionLabels = [
        "Absent",
        "PhilHealth Contribution",
        "PhilHealth Loans",
        "PAGIBIG Contribution",
        "PAGIBIG Loans",
        "SSS Contribution",
        "SSS Loans",
        "SSS Calamity Loans",
        "SSS Salary Loans",
        "Calamity Loans",
        "Company Loans",
        "Cooperative Loans",
        "Cooperative Savings",
        "HMO Maxicare",
        "Undertime",
        "Late",
        "AXA",
        "Phone Loan",
        "Laptop Loan",
        "Cash Advance",
        "Undertime",
        "Withholding Tax",
        "Coop",
        "Others",
        "Intellicare Amount",
      ];

      function formatDateRange (dateFrom, dateTo) {
        if (moment(dateFrom).format('YYYY')===moment(dateTo).format('YYYY')) {
            return `${moment(dateFrom).format('MMMM DD')} - ${moment(dateTo).format('MMMM DD, YYYY')}`
        } else {
            return `${moment(dateFrom).format('MMMM DD, YYYY')} - ${moment(dateTo).format('MMMM DD, YYYY')}`
        }
      }

    React.useEffect(() => {
        async function fetchPayrollData () {
            const response = await UsePostRequest("payrolls/get", {payroll_id: payrollId});
            if (response.data) {
              const payroll = response.data[0];
              const tempEarnings = [];
              const  tempdDeductions = [];
              var info = payroll;
              info.id = payroll.id;
              info.salary =  Number(payroll.basic_pay);
              info.date_from = payroll.date_from;
              info.date_to = payroll.date_to;
              info.restday_ot_mins = payroll.restday_ot_mins||0;
              info.regular_ot_mins = payroll.regular_ot_mins||0;
              info.undertime_mins = payroll.undertime_mins||0;
              info.late_mins = payroll.late_mins||0;

            // Store all earnings in the earningValues state value
              tempEarnings.push(
                payroll.basic_pay,
                payroll.holiday_pay,
                payroll.night_differential,
                payroll.overtime,
                payroll.regular_ot,
                payroll.restday_ot,
                payroll.monthly,
                payroll.semi_monthly,
                payroll.salary_adj,
                payroll.special_holiday_pay,
                payroll.comm_allowance,
                payroll.daily_allowance,
                payroll.food_allowance,
                payroll.hmo_allowance,
                payroll.ops_allowance,
                payroll.special_allowance,
                payroll.tech_allowance,
                payroll.transpo_allowance,
                payroll.training_allowance_f2f,
                payroll.training_allowance_virtual,
                payroll.grocery_allowance,
                payroll.entertainment_allowance,
                payroll.medicine_allowance,
                payroll.uniform_allowance
              );
            // Include thirteenth month to earnings if it was set to true during payroll generation
              if (payroll.is_thirteenth_included === "1") {
                tempEarnings.push(payroll.thirteenth_month);
              } 
            // Store all deductions in the deductionValues state value
            tempdDeductions.push(
                payroll.absent,
                payroll.philhealth_contribution,
                payroll.philhealth_loans,
                payroll.pagibig_contribution,
                payroll.pagibig_loan,
                payroll.sss_contribution,
                payroll.sss_loans,
                payroll.sss_calamity_loan,
                payroll.sss_salary_loan,
                payroll.calamity_loan,
                payroll.company_loan,
                payroll.cooperative_loans,
                payroll.cooperative_savings,
                payroll.hmo_maxicare,
                payroll.undertime_amount,
                payroll.late,
                payroll.axa_amount,
                payroll.phone_loan,
                payroll.laptop_loan,
                payroll.cash_advance,
                payroll.undertime,
                payroll.wth_tax,
                payroll.coop,
                payroll.others,
                payroll.intellicare_amount
              );
              
              info.total_earnings = getSum(tempEarnings);
              setEarningValues(tempEarnings);
              info.total_deductions = getSum(tempdDeductions);
              setDeductionValues(tempdDeductions);
              info.total_net_pay = info.total_earnings - info.total_deductions;
              setPayrollData(info);
            }
        }

        fetchPayrollData();
    }, [])

    return (
      <Navbar isForm>
        <div ref={componentRef}>
          {/*<div className="payslip-container">
            <Row className="d-flex justify-content-end">
              <Title level={2}>PAYSLIP</Title>
            </Row>
            <Row className="d-flex justify-content-end">
              Date Printed: {formatDate(dateToday)}
            </Row>
            <Row>
              <img src={logo} className="payslip-logo" alt="" />
            </Row>
            <Row>
              <Title level={5}>{window.$company_name}</Title>
            </Row>
            <Row>{window.$address_line1}</Row>
            <Row>{window.$address_line2}</Row>

            <Row>
              <table className="table table-bordered mt-3">
                <thead>
                  <tr className="payslip-blue-bg">
                    <th colSpan={2}>Employee Information</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="bold">Name</td>
                    <td>{payrollData.employee_name}</td>
                  </tr>
                  <tr>
                    <td className="bold">Address</td>
                    <td>{payrollData.address}</td>
                  </tr>
                  <tr>
                    <td className="bold">Contact Number</td>
                    <td>{payrollData.contact_no}</td>
                  </tr>
                </tbody>
              </table>
            </Row>

            <Row className="mt-3">
              For the month of:{" "}
              {formatDateRange(payrollData.date_from, payrollData.date_to)}
            </Row>
            <Row>
              <Col xs={24} md={12} lg={12} className="mt-3 pe-2">
                <table className="table table-bordered">
                  <thead>
                    <tr className="payslip-blue-bg">
                      <th>Earnings</th>
                      <th className="d-flex justify-content-end">Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {earningValues.map((val, index) => {
                      if (Number(val) > 0) {
                        return (
                          <tr key={index}>
                            <td>{earningLabels[index]}</td>
                            <td className="d-flex justify-content-end">
                              {formatAmount(val)}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </Col>
              <Col xs={24} md={12} lg={12} className="mt-3 ps-2">
                <table className="table table-bordered">
                  <thead>
                    <tr className="payslip-blue-bg">
                      <th>Deductions</th>
                      <th className="d-flex justify-content-end">Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {deductionValues.map((val, index) => {
                      if (parseFloat(val) > 0) {
                        return (
                          <tr>
                            <td>{deductionLabels[index]}</td>
                            <td className="d-flex justify-content-end">
                              {formatAmount(val)}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
            <Row className="mt-1">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Gross Earnings</td>
                    <td className="d-flex justify-content-end">
                      {formatAmount(payrollData.total_earnings)}
                    </td>
                    <td>Total Deductions</td>
                    <td className="d-flex justify-content-end">
                      {formatAmount(payrollData.total_deductions)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}></td>
                    <td className="bold">Net Salary</td>
                    <td className="d-flex justify-content-end">
                      {formatAmount(payrollData.total_net_pay)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>

            <Row className="mt-3 d-flex justify-content-between">
              <Col xs={24} md={9}>
                <Row className="mt-5"></Row>
                <Row className="d-flex justify-content-center no-margin-bottom">
                  JUAN DELA CRUZ
                </Row>
                <Divider />
                <Row className="d-flex justify-content-center">
                  Employer's Signature
                </Row>
              </Col>
              <Col xs={24} md={9}>
                {signature && signature.trim() !== "" ? (
                  <Row className="d-flex justify-content-center no-margin-bottom">
                    {<img src={signature} alt="" className="signature" />}
                  </Row>
                ) : (
                  <Row className="mt-5"></Row>
                )}
                <Row className="d-flex justify-content-center no-margin-bottom">
                  {payrollData.employee_name
                    ? payrollData.employee_name.toUpperCase()
                    : ""}
                </Row>
                <Divider />
                <Row className="d-flex justify-content-center">
                  Employee's Signature
                </Row>
              </Col>
            </Row>

            <Row className="mt-5 d-flex justify-content-end noPrint">
              <Row>
                <ReactToPrint
                  trigger={() => <Button type="primary"><PrinterOutlined />Print</Button>}
                  content={() => componentRef.current}
                  documentTitle={`${payrollData.employee_name} Payslip`}
                />
              </Row>
            </Row>
                  </div>*/}

          <div className="payslip-container">
            <Card className="sharp-edge bordered">
                <Row className="sharp-edge bordered">
                  <Col xs={20} md={14} lg={20} className="border-right">
                    <Row className="border-bottom center-text py-2"><Title level={4}>SALARY SLIP</Title></Row>
                    <Row className="black-border center-text py-2">{formatDateRange(payrollData.date_from, payrollData.date_to)}</Row>
                  </Col>
                  <Col xs={4} md={10} lg={4} className="center-text secondary-bg"><Title level={4}>CONFIDENTIAL</Title></Col>
                </Row>
                
                <Row className="bordered">
                  <Col xs={12} md={12} className="border-right p-2">
                    <Row><Col xs={10} md={10}>Name</Col>: {capitalizeEachWord(payrollData.employee_name)}</Row>
                    <Row><Col xs={10} md={10}>Employee ID</Col>: {payrollData.employee_id}</Row>
                  </Col>
                  <Col xs={12} md={12} className="border-right p-2">
                    <Row><Col xs={10} md={10} lg={8}>Position</Col>: {payrollData.position_name}</Row>
                    <Row><Col xs={10} md={10} lg={8}>Status of Employment</Col>: {payrollData.employment_status_name}</Row>
                    <Row><Col xs={10} md={10} lg={8}>Department</Col>: {payrollData.department_name}</Row>
                  </Col>
                </Row>

                <Row className="bordered">
                  <Col xs={12} className="center-text white-text primary-bg p-1 border-right">Description</Col>
                  <Col xs={6} className="center-text white-text primary-bg p-1 border-right">Earnings</Col>
                  <Col xs={6} className="center-text white-text primary-bg p-1">Deductions</Col>
                </Row>

                <Row className="bordered">
                  <Col xs={12} className="p-2 border-right">
                    {
                      earningLabels.map((label, index) => {
                        if (earningValues[index] && Number(earningValues[index]) > 0) {
                          return <Row>{label}</Row>
                        }
                      })
                    }
                    {
                      deductionLabels.map((label, index) => {
                        if (earningValues[index] && Number(deductionValues[index]) > 0) {
                          return <Row>{label}</Row>
                        }
                      })
                    }
                  </Col>
                  <Col xs={6} className="p-2 border-right">
                    {
                      earningValues.map((value) => {
                        if (value && Number(value) > 0) {
                          return <Row className="justify-content-end">{formatAmount(value)}</Row>
                        }
                      })
                    }
                  </Col>
                  <Col xs={6} className="p-2">
                    {
                      earningValues.map((value) => {
                        if (value && Number(value) > 0) {
                          return <br></br>
                        }
                      })
                    }
                    {
                      deductionValues.map((value) => {
                        if (value && Number(value) > 0) {
                          return <Row className="justify-content-end">{formatAmount(value)}</Row>
                        }
                      })
                    }
                  </Col>
                </Row>

                <Row className="bordered">
                  <Col xs={12} className="p-2 border-right">Total</Col>
                  <Col xs={6} className="p-2 d-flex justify-content-end border-right bold">{formatAmount(payrollData.total_earnings)}</Col>
                  <Col xs={6} className="p-2 d-flex justify-content-end bold">{formatAmount(payrollData.total_deductions)}</Col>
                </Row>

                <Row className="bordered">
                  <Col xs={12} md={12} lg={12} className="border-right p-2">
                    <Row><Col xs={10} md={10}>Payment Date</Col>: {formatDate(dateToday)}</Row>
                    <Row><Col xs={10} md={10}>Bank Name</Col>: {payrollData.bank}</Row>
                    <Row><Col xs={10} md={10}>Bank Account Name</Col>: {payrollData.bank_account_name}</Row>
                    <Row><Col xs={10} md={10}>Bank Account #</Col>: {payrollData.bank_account_number}</Row>
                  </Col>
                  <Col xs={12} md={12} lg={12} className="p-0">
                    <Row className="center-text primary-bg white-text p-1">NET PAY</Row>
                    <Row className="center-text secondary-bg bold p-1">{formatAmount(payrollData.total_net_pay)}</Row>
                    <Row className="center-text p-2"><i>{formartNumberToWords(payrollData.total_net_pay)}</i></Row>
                  </Col>
                </Row>
            </Card>
            <Row className="mt-5 d-flex justify-content-end noPrint">
              <Row>
                <ReactToPrint
                  trigger={() => <Button type="primary"><PrinterOutlined />Print</Button>}
                  content={() => componentRef.current}
                  documentTitle={`${payrollData.employee_name} Payslip`}
                />
              </Row>
            </Row>
          </div>
        </div>
      </Navbar>
    );
}