import React from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import CustomToolbar from "./CustomToolbar";

export default function ReactQuillEditor (props) {
    const modules = {
        toolbar: {
            container: "#toolbar",
        }
    }
    const formats = [
      'font','size',
      'bold','italic','underline','strike',
      'color','background',
      'script',
      'header','blockquote','code-block',
      'indent','list',
      'direction','align',
      'link','image','video','formula',
    ]

    return (
        <div>
            {/* <CustomToolbar/> */}
            <ReactQuill value={props.content} onChange={props.handleQuillChange} readOnly={props.isDisabled}/>
        </div>
    )
}