import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Button, Form, Input, Modal, Table, Row, Col, InputNumber, TimePicker } from 'antd'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { usePostRequest } from '../../hooks/usePostRequest'
import { UseGetRequest } from '../../utils/api/ApiRequests'
import moment from 'moment'

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  
  // Watch all values
  const values = Form.useWatch([], form);
    React.useEffect(() => {
      form.validateFields({
        validateOnly: true,
      }).then(() => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
      );
    }, [values]);
    return (
    <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Submit</Button>
    );
};


export default function Shift() {
  const [form] = Form.useForm();
  const {callPost, isLoading} = usePostRequest(()=>{
    setShowAddModal(false); 
    setShowEditModal(false);
    setShowViewModal(false);
    form.resetFields();
    setFilterConfig((prev) => ({...prev, is_updated: !prev.is_updated}))});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  // const [initialValues, setInitialValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [filterConfig, setFilterConfig] = useState({
    is_updated: false
  });
  const [isFetching, setIsFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  
  const tableColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Time In',
        dataIndex: 'time_in',
        key: 'time_in',
        render: (time_in, record) => (
            <div onClick={() => handleRowClick(record)} className='pointer-cursor'>{moment(time_in, 'HH:mm:ss').format('hh:mm A')}</div>
        )
    },
    {
        title: 'Time Out',
        dataIndex: 'time_out',
        key: '',
        render: (time_out, record) => (
            <div onClick={() => handleRowClick(record)} className='pointer-cursor'>{moment(time_out, 'HH:mm:ss').format('hh:mm A')}</div>
        )
    },
    {
        title: 'Overtime Rate (%)',
        dataIndex: 'overtime_fee',
        key: 'overtime_fee',
    },
    {
        title: 'Break (mins)',
        dataIndex: 'break_duration',
        key: 'break_duration',
    },
    
  ];

  const confirmDelete = (shift) => {
    modal.confirm({
      title: `Are you sure you want to delete shift "${shift.name}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleDelete(shift.id),
      okType: 'danger',
    });
  };

  const onFinish = (formInputs) => {
    // var values = Object.entries(formInputs).map(([key, value]) => ({ key, value }));
    formInputs.time_in = formInputs.time_in.format('HH:mm:ss');
    formInputs.time_out = formInputs.time_out.format('HH:mm:ss');
    callPost(
      "shifts/add", 
      formInputs,
      false
    );
    form.resetFields();
  };

  const onEdit = (formInputs) => {
    // var values = Object.entries(formInputs).map(([key, value]) => ({ key, value }));
    formInputs.shift_id = clickedRowData.id;
    formInputs.time_in = formInputs.time_in.format('HH:mm:ss');
    formInputs.time_out = formInputs.time_out.format('HH:mm:ss');
    callPost(
      `shifts/update`, 
      formInputs,
      false
    );
    form.resetFields();
  };

  const handleSearch = (e) => {
    if (e.key==="Enter") {
      setFilterConfig((prev) => ({...prev, term: searchTerm}));
    }
  }

  function handleDelete(passedId) {
    callPost("shifts/delete", {shift_id: passedId}, false);
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData);
    const formattedValues = { ...rowData };
    formattedValues.time_in = moment(rowData.time_in,"hh:mm A");
    formattedValues.time_out = moment(rowData.time_out,"hh:mm A"); 
    form.setFieldsValue(formattedValues);  
    // setInitialValues(()=>{return{...rowData}});
    setShowViewModal(true);
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }, 2000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchTerm]);

React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UseGetRequest("shifts/get", filterConfig);
    if (response.data) {
        const tempData = response.data.data.map((row) => {
            var info = row;
            return info;
        })
        setTableData(tempData);
        // setTableData(response.data.data);
    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar
      pageTitle="Shifts"
      pageButtonTitle="Add Shift"
      setSearchQuery={(e)=>setSearchTerm(e)} onSearch={handleSearch} searchPlaceholder="shift" 
      onClickButton={() => setShowAddModal(true)}
      isManager
      searchable
    >
      <Table
        className="mt-5"
        rowKey="id"
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching || isLoading}
      />

      <Modal
        title={`${showAddModal ? "Add" : "Edit"} Shift`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          form.resetFields();
        }}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_in"
                label="Time In"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format={"hh:mm A"} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_out"
                label="Time Out"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format={"hh:mm A"} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="overtime_fee"
                label="Overtime Rate (%)"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="break_duration"
                label="Break Duration (mins)"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Shift"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          form.resetFields();
        }}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_in"
                label="Time In"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format={"hh:mm A"} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_out"
                label="Time Out"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format={"hh:mm A"} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="overtime_fee"
                label="Overtime Rate (%)"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="break_duration"
                label="Break Duration (mins)"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <InputNumber disabled/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowViewModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
              >
                Edit
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </Navbar>
  );
}