import React, {useState, useRef} from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { Table, Container } from "react-bootstrap";
import Navbar from "../../components/Navbar/Navbar";
import ReactToPrint from "react-to-print";
import {UsePostRequest} from "../../utils/api/ApiRequests";
import sss_logo from "../../assets/images/sss_logo.png";
import { formatAmount } from "../../utils/Common";


export default function PrintSssContributionReport () {
    const location = useLocation();
    const componentRef = useRef();
    const dateToday = new Date();
    const currentMonth = dateToday.toLocaleString('default', { month: 'long' });
    const currentYear = dateToday.getFullYear();
    // const [isFetching, setIsFetching] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [totals, setTotals] = useState({});
    const [filterConfig, setFilterConfig] = useState({
        date_from: location.state.date_from,
        date_to: location.state.date_to,
    });

    React.useEffect(() => {
        async function fetchTableData () {
            // setIsFetching(true);
            setTableData([]);
            setTotals({});
            const response = await UsePostRequest("contributions/get_sss_report", filterConfig);
            if (response.data) {
                setTableData(response.data);
                setTotals(response.response.grand_total);
            }
            // setIsFetching(false);
        }

        fetchTableData();
    }, [filterConfig])

    return (
        <Navbar isForm>
            <Container className="print-report-page p-3">
                <div ref={componentRef}>
            <Row>
              <Col xs={3}>
                <img src={sss_logo} alt="img" className="sss-logo pt-4" />
              </Col>
              <Col xs={18} className="mt-2">
                  <Row className="center tiny-text">Republic of the Philippines</Row>
                  <Row className="center bold">SOCIAL SECURITY SYSTEM</Row>
                  <Row className="center larger-text bold">ELECTRONIC CONTRIBUTION</Row>
                  <Row className="center larger-text bold">COLLECTION LIST</Row>
              </Col>
              <Col xs={3}>
              </Col>
            </Row>
            
            <Row className="mt-4">
                <Col className="bold">{window.$company_sss_no}</Col>
            </Row>
            <Row className="">
                <Col className="bold uppercase">{window.$company_name}</Col>
            </Row>
            <Row className="">
                <Col className="bold">{currentMonth+' '+currentYear}</Col>
            </Row>

            <div className="mt-3">
                <Table className="table-header-only-borders stripe-table">
                    <thead>
                        <tr className="text-center bw-cells">
                            <th></th>
                            <th>Surname</th>
                            <th>Given Name</th>
                            <th>Middle Name</th>
                            <th>SS Number</th>
                            <th>SS</th>
                            <th>EC</th>
                            <th>Total Contributions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (tableData && tableData.length) ? (
                                tableData.map((row, index) => {
                                    return (
                                        <tr>
                                            <td className="text-center">{index+1}</td>
                                            <td>{row.last_name}</td>
                                            <td>{row.first_name}</td>
                                            <td>{row.middle_name}</td>
                                            <td className="text-center">{row.sss}</td>
                                            <td className="text-end">{formatAmount(row.employee_contribution)}</td>
                                            <td className="text-end">{formatAmount(row.employer_contribution)}</td>
                                            <td className="text-end">{formatAmount(row.total_contribution)}</td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr className="text-center">
                                    <td colSpan={8}>No Data Found</td>
                                </tr>
                            )
                        }
                    </tbody>
                    <tbody className="bordered-body">
                        <tr>
                            <td colSpan={5} className="bold">Subtotal</td>
                            <td className="text-end">Php {totals.total_employee_contribution ? Number(totals.total_employee_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}</td>
                            <td className="text-end">Php {totals.total_employer_contribution ? Number(totals.total_employer_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}</td>
                            <td className="text-end">Php {totals.total_total ? Number(totals.total_total).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}</td>
                        </tr>
                        <tr className="bw-cells">
                            <td colSpan={7} className="bold">Total Amount Due</td>
                            <td className="bold text-end">Php {totals.total_total ? Number(totals.total_total).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
                    <Row className="d-flex justify-content-center mt-4 mt-5 mb-5 noPrint">
                        <Col className="d-flex justify-content-center print">
                        <ReactToPrint
                            trigger={() => (
                            <Button type="primary">
                                <PrinterOutlined /> Print
                            </Button>
                            )}
                            content={() => componentRef.current}
                            documentTitle={"PhilHealth Report"}
                        />
                        </Col>
                    </Row>

                </div>
            </Container>
        </Navbar>
    )
}