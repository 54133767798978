import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import {Table, Space, Select, Button, Modal, Row, Col, Form, DatePicker, Input, Divider, Typography, InputNumber, Dropdown, Menu, Upload} from "antd";
import {DeleteOutlined, DownOutlined, ExclamationCircleOutlined, PlusCircleOutlined, UploadOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import "quill/dist/quill.snow.css";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import {
  GetAllBranches,
  GetAllBusinessUnits,
  GetAllDepartments,
  GetAllEmployees,
  GetAllEmploymentStatus,
  GetAllEmployeeStatus,
  GetAllPositions,
  GetAllProjects, GetAllSalaryTypes, GetAllShifts
} from "../../utils/api/DropdownFiltersApi";
import { usePostRequest } from "../../hooks/usePostRequest";
import { capitalizeEachWord, getIsAdminLogin, getEmployeeId } from "../../utils/Common";

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    let validationTimeout;

    if (validationTimeout) {
      clearTimeout(validationTimeout);
    }

    validationTimeout = setTimeout(() => {
      form.validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false));
    }, 300);

    return () => {
      if (validationTimeout) {
        clearTimeout(validationTimeout);
      }
    };
  }, [values]);

    return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Submit
    </Button>
  );
}

const { Title } = Typography;

export default function Employee() {
  const [form] = Form.useForm();
  const [statusForm] = Form.useForm();
  const navigate = useNavigate();
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const [tableData, setTableData] = useState([]);
  // const [filteredTableData, setFilteredTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);
  const [showAllowanceModal, setShowAllowanceModal] = useState(false);
  const [clickedRowId, setClickedRowId] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  // const [currentEmploymentStatus, setCurrentEmploymentStatus] = useState();
  const [modal, contextHolder] = Modal.useModal();
  const branchOptions = GetAllBranches();
  const statusOptions = GetAllEmploymentStatus();
  const positionOptions = GetAllPositions();
  const businessUnitOptions = GetAllBusinessUnits();
  const projectOptions = GetAllProjects();
  const departmentOptions = GetAllDepartments();
  const salaryTypeOptions = GetAllSalaryTypes();
  const employeeOptions = GetAllEmployees();
  const shiftOptions = GetAllShifts();
  const employeeStatusOptions = GetAllEmployeeStatus();
  const dateFormat = "YYYY-MM-DD";
  const [oldSalary, setOldSalary] = useState();
  const [oldPosition, setOldPosition] = useState();

  const { callPost, isLoading } = usePostRequest(() => {});

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: isAdminLogin?"":employeeId,
  });

  const tableColumns = [
    {
      title: "DTR ID",
      dataIndex: "dtr_id",
      key: "dtr_id",
    },
    {
      title: "Employee",
      dataIndex: "fullname",
      key: "fullname",
      render: (fullname) => (
        <div>{capitalizeEachWord(fullname)}</div>
      )
    },
    {
      title: "Date Hired",
      dataIndex: "date_hired",
      key: "date_hired",
      render: (date_hired, record) => (
        <div>{date_hired ? moment(date_hired).format("MMM DD, YYYY") : ""}</div>
      ),
    },
    {
      title: "Date Regularized",
      dataIndex: "date_regularized",
      key: "date_regularized",
      render: (date_regularized, record) => (
        <div>
          {date_regularized
            ? moment(date_regularized).format("MMM DD, YYYY")
            : ""}
        </div>
      ),
    },
    {
      title: "End of Contract",
      dataIndex: "end_of_contract",
      key: "end_of_contract",
      render: (end_of_contract, record) => (
        <div>
          {end_of_contract
            ? moment(end_of_contract).format("MMM DD, YYYY")
            : ""}
        </div>
      ),
    },
    {
      title: "Position",
      dataIndex: "position_name",
      key: "position_name",
    },
    {
        title: 'Actions',
        width: '10%',
        render: (_, record) => (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="edit" onClick={() => {handleRowClick(record); setShowEditModal(true)}}>
                  Edit
                </Menu.Item>
                <Menu.Item key="allowance" onClick={() => {handleRowClick(record); setShowAllowanceModal(true)}}>
                  Update Allowance
                </Menu.Item>
                <Menu.Item key="contract" onClick={() => {handleRowClick(record); setShowContractModal(true)}}>
                  Update Employment Info
                </Menu.Item>
                {/* <Menu.Item key="view" onClick={() => navigate("contract", {state: record})}>
                  View Contract
                </Menu.Item> */}
                <Menu.Item key="history" onClick={() => navigate("history-report", {state: record})}>
                  View History Report
                </Menu.Item>
                <Menu.Item key="profile" onClick={() => navigate("view", {state: {passedId: record.id}})}>
                  View Profile
                </Menu.Item>
                <Menu.Item key="delete" className="danger" onClick={() => confirmDelete(record)}>
                  Delete
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        ),
    },
  ];

  function postCallPostAction () {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowContractModal(false);
    setShowAllowanceModal(false);
    resetValues();
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }));
  }

//   function getBase64(file) {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = () => resolve(reader.result);
//         reader.onerror = error => reject(error);
//     });
// }

  const onFinish = (formInputs) => {
    // filter out empty object and child object where name and age are empty
    const filteredChildrenArray = formInputs.childrenArray.filter((child) => {
      return Object.keys(child).length > 0 && child.name&&child.name.trim() !== '' && child.age&&child.age.trim() !== '';
    });
    const childLen = filteredChildrenArray.length;
    formInputs.no_of_children = childLen;
    formInputs.children = childLen > 0 ? filteredChildrenArray.map((item) => item.child_name):[];
    formInputs.age = childLen > 0 ? filteredChildrenArray.map((item) => item.child_age) : [];
    formInputs.occupation = childLen > 0 ? filteredChildrenArray.map((item) => item.child_occupation) : [];
    formInputs.birthdate = formInputs.birthdate?.format(dateFormat)||'';
    formInputs.date_hired = formInputs.date_hired?.format(dateFormat)||'';
    formInputs.end_of_contract = formInputs.end_of_contract?.format(dateFormat)||'';
    formInputs.date_regularized = formInputs.date_regularized?.format(dateFormat)||'';
    // if (formInputs.profile_picture.fileList.length > 0) {
    //   const file = formInputs.profile_picture.fileList[0];
    //   getBase64(file).then(base64 => {
    //     const imgSrc = base64;
    //     formInputs.file_name = imgSrc;
    //   });
    // } else {
    //   formInputs.file_name = ""
    // }
    
    // Send empty string values to undefined fields (these are fields with no input at all)
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key]===undefined) {
        formInputs[key] = "";
      }
    })

    callPost(
      "employees/add",
      formInputs,
      false
    );   
    postCallPostAction();
  };

  const onEdit = (formInputs) => {
    var filteredChildrenArray = []
    if (formInputs.childrenArray&&formInputs.childrenArray.length>0) {
      formInputs.childrenArray.filter((child) => {
        return Object.keys(child).length > 0 && child.name !== '' && child.age !== '';
      });
    }
    const childLen = filteredChildrenArray.length;
    formInputs.no_of_children = childLen;
    formInputs.children = childLen > 0 ? filteredChildrenArray.map((item) => item.child_name):[];
    formInputs.age = childLen > 0 ? filteredChildrenArray.map((item) => item.child_age) : [];
    formInputs.occupation = childLen > 0 ? filteredChildrenArray.map((item) => item.child_occupation) : [];
    formInputs.birthdate = formInputs.birthdate?formInputs.birthdate.format(dateFormat):'';
    formInputs.date_hired = formInputs.date_hired?formInputs.date_hired.format(dateFormat):'';
    formInputs.end_of_contract = formInputs.end_of_contract?formInputs.end_of_contract.format(dateFormat):'';
    formInputs.date_regularized = formInputs.date_regularized?formInputs.date_regularized.format(dateFormat):'';
    formInputs.salary = formInputs.salary_to;

    // if (formInputs.profile_picture.fileList.length > 0) {
    //   const file = formInputs.profile_picture.fileList[0];
    //   getBase64(file).then(base64 => {
    //     const imgSrc = base64;
    //     formInputs.file_name = imgSrc;
    //   });
    // } else {
    //   formInputs.file_name = ""
    // }
    
    // Send empty string values to undefined fields (these are fields with no input at all)
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key]===undefined||formInputs[key]===null) {
        formInputs[key] = "";
      }
    })

    callPost(`employees/update/`+clickedRowId, formInputs, false);

    // UPDATE SALARY
    if (parseFloat(formInputs.salary_to).toFixed(2) !== parseFloat(oldSalary).toFixed(2)) {
      const salaryValues = {
        employee_id: clickedRowId,
        salary_from: oldSalary,
        salary_to: formInputs.salary_to,
        salary_type: formInputs.salary_type,
      }
      callPost("employees/update_employee_salary", salaryValues, false);
    }

    // UPDATE POSITION
    if (parseFloat(formInputs.position_to).toFixed(2) !== parseFloat(oldPosition).toFixed(2)) {
      const positionValues = {
        employee_id: clickedRowId,
        position_from: oldSalary,
        position_to: formInputs.position_to,
      }
      callPost("employees/update_employee_position", positionValues, false);
    }

    postCallPostAction();

  };

  const confirmDelete = (employee) => {
    modal.confirm({
      title: `Are you sure you want to delete employee "${employee.last_name}"?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(employee.id),
      okType: "danger",
    });
  };

  function handleDelete(passedId) {
    callPost("employees/delete", { employee_id: passedId }, false);
    postCallPostAction();
  }

  function resetValues() {
    form.resetFields();
    statusForm.resetFields();
  }

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }
  };

  const handleFilter = (e, field) => {
    setFilterConfig((prev) => ({ ...prev, [field]: e }));
  };

  const handleRowClick = (rowData) => {
    setClickedRowId(rowData.id);
    getEmployeeDetailsById(rowData.id)
  };

  async function getEmployeeDetailsById (passedId) {
    const response = await UsePostRequest("employees/get", {employee_id: passedId});
    if (response.data) {
      const res = response.data[0];
      // For the major form -- edit, update contract, allowance, etc.
      // setCurrentEmploymentStatus(res.employment_status_id);
      const formValues = res;
      formValues.birthdate = res.birthdate?dayjs(res.birthdate, dateFormat):"";
      formValues.date_hired = res.date_hired?dayjs(res.date_hired, dateFormat):"";
      formValues.end_of_contract= res.end_of_contract?dayjs(res.end_of_contract, dateFormat):"";
      formValues.date_regularized = res.date_regularized?dayjs(res.date_regularized, dateFormat):"";
      formValues.salary_type = res.salary[0]?.salary_type;
      formValues.salary_to = res.salary[0]?.salary_to;
      setOldSalary(res.salary[0]?.salary_to);
      setOldPosition(res.position_to);
      formValues.business_unit_id = res.business_unit;
      formValues.daily_allowance = res.allowance[0]?.daily_allowance;
      formValues.commu_allowance = res.allowance[0]?.commu_allowance;
      formValues.transpo_allowance = res.allowance[0]?.transpo_allowance;
      formValues.food_allowance = res.allowance[0]?.food_allowance;
      formValues.hmo_allowance = res.allowance[0]?.hmo_allowance;
      formValues.tech_allowance = res.allowance[0]?.tech_allowance;
      formValues.ops_allowance = res.allowance[0]?.ops_allowance;
      formValues.special_allowance = res.allowance[0]?.special_allowance;
      formValues.grocery_allowance = res.allowance[0]?.grocery_allowance;
      formValues.entertainment_allowance = res.allowance[0]?.entertainment_allowance;
      formValues.medicine_allowance = res.allowance[0]?.medicine_allowance;
      formValues.uniform_allowance = res.allowance[0]?.uniform_allowance;
      formValues.employment_status_id = res.employment_status;

      var tempChildren = []
      
      if (res.children && res.children.length > 0) {
        tempChildren = res.children.map((item) => {
        var info = {}
          info.child_name = item.name;
          info.child_age = item.age;
          info.child_occupation = item.occupation;
          return info;
        })
      }

      formValues.childrenArray = tempChildren;
      form.setFieldsValue(formValues);
      const statusFormValues = {};
      statusFormValues.employee_id = res.id;
      statusFormValues.employee_status_id = res.employee_status_id;
      statusForm.setFieldsValue(statusFormValues);
    }
  }

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }, 2000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchTerm]);

  React.useEffect(() => {
    async function fetchTableData() {
      setIsFetching(true);
      setTableData([]);
      const response = await UsePostRequest("employees/get_all", filterConfig);
      if (response.data) {
        const res = response.data.map((data) => {
          var info = data;
          info.fullname = `${data.last_name}, ${data.first_name} ${data.middle_name}`;
          return info;
        });
        setTableData(res);
      }
      setIsFetching(false);
    }

    fetchTableData();
  }, [filterConfig]);


  // These functions are the different input field sections in every modal in the employee module
  // Decided to put them as functions since I will be reusing them across the code
  // For which section every function is should be self explanatory.
  // Why do I need to reuse them in every modal? Because for some reason, if these sections are not included in the Form,
  // their values disappear sa form (which I need by the way even if I only edit one section at once)

  function formProfileSection () {
    return (
      <>
        <Divider />
          <Row>
            <Title level={5}>Personal Information</Title>
          </Row>
          <Row>
            <Col xs={12} md={8} lg={8}>
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="middle_name" label="Middle Name">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="nickname" label="Nickname">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item
                name="contact_no"
                label="Contact Number"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item
                name="email"
                label="Email Address"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item
                name="gender"
                label="Gender"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                  options={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item
                name="birthdate"
                label="Birthdate"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <DatePicker placeholder="YYYY-MM-DD"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item
                name="civil_status"
                label="Civil Status"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select options={[
                    { value: "single", label: "Single" },
                    { value: "married", label: "Married" },
                    { value: "widowed", label: "Widowed" },
                ]} />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item
                name="nationality"
                label="Nationality"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="religion" label="Religion">
                <Input />
              </Form.Item>
            </Col>
            {/* <Col xs={12} md={8} lg={8}>
              <Form.Item name="remarks" label="Remarks">
                <Input />
              </Form.Item>
            </Col> */}
            {/* <Col xs={12} md={8} lg={8}>
              <Form.Item name="profile_picture" label="Profile Picture">
                <Upload listType="picture-card" accept="image/*" beforeUpload={false} maxCount={1}>
                    + Add Photo
                  </Upload>
              </Form.Item>
            </Col> */}
          </Row>

          <Divider />
          <Row>
            <Title level={5}>Benefit Contribution Information</Title>
          </Row>
          <Row>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="sss" label="SSS Number">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="hdmf" label="HDMF Number">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="phic" label="PhilHealth Number">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="intellicare_number" label="Intellicare No">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="axa_policy_number" label="AXA Policy Number">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row>
            <Title level={5}>Withholding Tax Information</Title>
          </Row>
          <Row>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="tin" label="SSS Number">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="wtaxstatus" label="Status">
                <Select options={[
                    {value: 'Zero Exception', label: 'Zero Exception'},
                    {value: 'Single', label: 'Single'},
                    {value: 'Married', label: 'Married'},
                ]} />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="dependent" label="Dependent">
                <InputNumber type="number"/>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row>
            <Title level={5}>Family Relationship Information</Title>
          </Row>
          <Row>
            <Col xs={10} md={10} lg={10}>
              <Form.Item name="spouse_name" label="Spouse Name">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={4} md={4} lg={4}>
              <Form.Item name="spouse_age" label="Age">
                <InputNumber type="number"/>
              </Form.Item>
            </Col>
            <Col xs={10} md={10} lg={10}>
              <Form.Item name="spouse_occupation" label="Occupation">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={10} md={10} lg={10}>
              <Form.Item name="mothers_name" label="Mother's Name">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={4} md={4} lg={4}>
              <Form.Item name="mothers_age" label="Age">
                <InputNumber type="number"/>
              </Form.Item>
            </Col>
            <Col xs={10} md={10} lg={10}>
              <Form.Item name="mothers_occupation" label="Occupation">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={10} md={10} lg={10}>
              <Form.Item name="fathers_name" label="Father's Name">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={4} md={4} lg={4}>
              <Form.Item name="fathers_age" label="Age">
                <InputNumber type="number"/>
              </Form.Item>
            </Col>
            <Col xs={10} md={10} lg={10}>
              <Form.Item name="fathers_occupation" label="Occupation">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.List
              name="childrenArray"
              initialValue={[{}]}
            >
              {(fields, { add, remove }) => (
                <>
                  <Col xs={24} lg={24}>
                    {fields.map(({ key, name, fieldKey }) => (
                        <Row
                          key={key}
                          style={{ marginBottom: 0 }}
                          className="d-flex justify-content-between"
                        >
                          <Col xs={10}>
                            <Form.Item
                              name={[name, "child_name"]}
                              label="Child Name"
                              fieldKey={[fieldKey, "child_name"]}
                            >
                             <Input/>
                            </Form.Item>
                          </Col>
                          <Col xs={4}>
                            <Form.Item
                              label="Age"
                              name={[name, "child_age"]}
                              fieldKey={[fieldKey, "child_age"]}
                            >
                              <InputNumber type="number"/>
                            </Form.Item>
                          </Col>
                          <Col xs={8}>
                            <Form.Item
                              label="Occupation"
                              name={[name, "child_occupation"]}
                              fieldKey={[fieldKey, "child_occupation"]}
                            >
                              <Input/>
                            </Form.Item>
                          </Col>
                          <Col xs={2} className="mt-4 p-1">
                            <Button
                              onClick={() => {
                                remove(name);
                              }}
                              icon={<DeleteOutlined />}
                            />
                          </Col>
                        </Row>
                      ))}
                  </Col>
                  <Row>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusCircleOutlined />}
                    >
                      Add Child
                    </Button>
                  </Row>
                </>
              )}
            </Form.List>
          </Row>

          <Divider />
          <Row>
            <Title level={5}>Person to Contact in Case of Emergency</Title>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Form.Item name="contact_person" label="Contact Person" rules={[{required: false,}]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <Form.Item name="contact_person_relationship" label="Relationship to Contact Person" rules={[{required: false,}]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <Form.Item name="contact_number" label="Contact Number" rules={[{required: false,}]}>
                <Input type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <Form.Item name="contact_address" label="Contact Address" rules={[{required: false,}]}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
      </>
    )
  }

  function formEmploymentInfoSection () {
    return (
      <>
      <Divider />
          <Row>
            <Title level={5}>Employment Information</Title>
          </Row>
          <Row>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="dtr_id" label="DTR ID" rules={[{required: false,}]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="branch_id" label="Branch" rules={[{required: false,}]}>
                <Select options={branchOptions.slice(1)}/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="business_unit_id" label="Business Unit"  rules={[{required: false,}]}>
                <Select options={businessUnitOptions.slice(1)}/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="employment_status_id" label="Employment Status" rules={[{required: false,}]}>
                <Select options={statusOptions.slice(1)}/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="salary_type" label="Salary Type" rules={[{required: false,}]}>
                <Select options={salaryTypeOptions.slice(1)}/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="salary_to" label="Basic Salary" rules={[{required: false,}]}>
                <InputNumber type="number" />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="bank" label="Bank">
                <Input/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="bank_account_name" label="Bank Account Name">
                <Input/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="bank_account_number" label="Bank Account Number">
                <InputNumber type="number"/>
              </Form.Item>
            </Col>
            {/* <Col xs={12} md={8} lg={8}>
              <Form.Item name="employee_status_id" label="Employee Status" rules={[{required: false,}]}>
                <Select options={employeeStatusOptions}/>
              </Form.Item>
            </Col> */}
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="immediate_head_id" label="Immediate Head">
                <Select showSearch
                  // style={{ width: 200 }}
                  placeholder="Select employee"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option.label.toLowerCase().includes(input.toLowerCase()))}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  } 
                  options={employeeOptions.slice(1)} />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="department_id" label="Department" rules={[{required: false,}]}>
                <Select options={departmentOptions.slice(1)}/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="position_to" label="Position" rules={[{required: false,}]}>
                <Select options={positionOptions.slice(1)}/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="date_hired" label="Date Hired" rules={[{required: false,}]}>
                <DatePicker placeholder="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="end_of_contract" label="End of Contract">
                <DatePicker placeholder="YYYY-MM-DD" />
              </Form.Item>
            </Col>
           
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="date_regularized" label="Date Regularized">
                <DatePicker placeholder="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="employee_status_id" label="Employee Status" rules={[{required: false,}]}>
                <Select options={employeeStatusOptions.slice(1)}/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="previous_employer" label="Previous Employer">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="current_shift_id" label="Current Shift" rules={[{required: false,}]}>
                <Select options={shiftOptions.slice(1)}/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="project_id" label="Project">
                <Select options={projectOptions}/>
              </Form.Item>
            </Col>
          </Row>
      </>
    )
  }

  function formAllowanceSection () {
    return (
      <>
      <Divider />
          <Row>
            <Title level={5}>Allowance</Title>
          </Row>
          <Row>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="daily_allowance" label="Daily Allowance" initialValue={0}>
                <InputNumber min={0} type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="commu_allowance" label="Communication Allowance" initialValue={0}>
                <InputNumber min={0} type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="transpo_allowance" label="Transportation Allowance" initialValue={0}>
                <InputNumber min={0} type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="food_allowance" label="Food Allowance" initialValue={0}>
                <InputNumber min={0} type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="hmo_allowance" label="HMO Allowance" initialValue={0}>
                <InputNumber min={0} type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="tech_allowance" label="Tech Allowance" initialValue={0}>
                <InputNumber min={0} type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="ops_allowance" label="Ops Allowance" initialValue={0}>
                <InputNumber min={0} type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="special_allowance" label="Special Allowance" initialValue={0}>
                <InputNumber min={0} type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="grocery_allowance" label="Grocery Allowance" initialValue={0}>
                <InputNumber min={0} type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="entertainment_allowance" label="Entertainment Allowance" initialValue={0}>
                <InputNumber min={0} type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="medicine_allowance" label="Medicine Allowance" initialValue={0}>
                <InputNumber min={0} type="number"/>
              </Form.Item>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <Form.Item name="uniform_allowance" label="Uniform Allowance" initialValue={0}>
                <InputNumber min={0} type="number"/>
              </Form.Item>
            </Col>
          </Row>
      </>
    )
  }


  // function formSystemAccessSection () {
  //   return (
  //     <>
  //     <Divider />
  //         <Row>
  //           <Title level={5}>System Access</Title>
  //         </Row>
  //         <Row>
  //           <Col xs={12} md={8} lg={8}>
  //             <Form.Item name="role_id" label="Role">
  //               <Select options={systemRoleOptions}/>
  //             </Form.Item>
  //           </Col>
  //           <Col xs={12} md={8} lg={8}>
  //             <Form.Item name="password" label="Password" >
  //               <Input />
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //     </>
  //   )
  // }

  return (
    <Navbar
      pageTitle="Employees"
      pageButtonTitle="Add Employee"
      onClickButton={() => {
        setShowAddModal(true);
      }}
      setSearchQuery={(e) => setSearchTerm(e)}
      // setSearchQuery={tempSearchFilter}
      onSearch={handleSearch}
      searchPlaceholder="employee"
      isManager
      searchable
    >
      <Space className="mt-3">
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Branch"
          optionFilterProp="items"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={branchOptions}
          onChange={(e) => handleFilter(e, "branch_id")}
        />
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Status"
          optionFilterProp="items"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={statusOptions}
          onChange={(e) => handleFilter(e, "employment_status_id")}
        />
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Position"
          optionFilterProp="items"
          filterOption={(input, option) => option.label.includes(input)}
          filterSort={(optionA, optionB) =>
            optionA.label
              .toLowerCase()
              .localeCompare(optionB.label.toLowerCase())
          }
          options={positionOptions}
          onChange={(e) => handleFilter(e, "position_id")}
        />
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Business Unit"
          optionFilterProp="items"
          filterOption={(input, option) => option.label.includes(input)}
          filterSort={(optionA, optionB) =>
            optionA.label
              .toLowerCase()
              .localeCompare(optionB.label.toLowerCase())
          }
          options={businessUnitOptions}
          onChange={(e) => handleFilter(e, "business_unit_id")}
        />
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Project"
          optionFilterProp="items"
          filterOption={(input, option) => option.label.includes(input)}
          filterSort={(optionA, optionB) =>
            optionA.label
              .toLowerCase()
              .localeCompare(optionB.label.toLowerCase())
          }
          options={projectOptions}
          onChange={(e) => handleFilter(e, "project_id")}
        />
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Department"
          optionFilterProp="items"
          filterOption={(input, option) => option.label.includes(input)}
          filterSort={(optionA, optionB) =>
            optionA.label
              .toLowerCase()
              .localeCompare(optionB.label.toLowerCase())
          }
          options={departmentOptions}
          onChange={(e) => handleFilter(e, "department_id")}
        />
      </Space>
      <Table
        className="mt-2"
        rowKey="id"
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching}
        pagination={{ defaultPageSize: 10, showQuickJumper: true, showSizeChanger: true }}
      />

      {/* ADD MODAL */}
      <Modal
        title="Add Employee"
        centered
        open={showAddModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={onFinish}
        >
          {formProfileSection()}
          {formEmploymentInfoSection()}
          {formAllowanceSection()}

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {/* EDIT MODAL */}     
      <Modal
        title="Edit Employee"
        centered
        open={showEditModal}
        footer={null}
        onCancel={() => {
          setShowEditModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={onEdit}
        >
          {formProfileSection()}
          
          {/* these are hidden but cannot be removed because the values for some reason disappears from the form */}
          <div style={{display: "none"}}>
            {formEmploymentInfoSection()}
            {formAllowanceSection()}
          </div>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowEditModal(false); resetValues();
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {/* UPDATE CONTRACT MODAL */}
      <Modal
        title="Update Employment Information"
        centered
        open={showContractModal}
        footer={null}
        onCancel={() => {
          setShowContractModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={onEdit}
        >
          {formEmploymentInfoSection()}

          <div style={{display: 'none'}}>
            {formProfileSection()}
            {formAllowanceSection()}
          </div>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowContractModal(false); resetValues();
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

    {/* UPDATE ALLOWANCE MODAL */}
      <Modal
        title="Update Allowance"
        centered
        open={showAllowanceModal}
        footer={null}
        onCancel={() => {
          setShowAllowanceModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={onEdit}
        >
          {formAllowanceSection()}

          <div style={{display: 'none'}}>
            {formProfileSection()}
            {formEmploymentInfoSection()}
          </div>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAllowanceModal(false); resetValues();
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </Navbar>
  );
}
