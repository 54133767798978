import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Button, Form, Input, Modal, Table, Row } from 'antd'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { usePostRequest } from '../../hooks/usePostRequest'
import { UseGetRequest } from '../../utils/api/ApiRequests'


const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  
  // Watch all values
  const values = Form.useWatch([], form);
    React.useEffect(() => {
      form.validateFields({
        validateOnly: true,
      }).then(() => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
      );
    }, [values]);
    return (
    <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Submit</Button>
    );
};


export default function Project() {
  const [form] = Form.useForm();
  const {callPost, isLoading} = usePostRequest(()=>{
    setShowAddModal(false); 
    setShowEditModal(false);
    setShowViewModal(false);
    form.resetFields();
    setFilterConfig((prev) => ({...prev, is_updated: !prev.is_updated}))});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  // const [initialValues, setInitialValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [filterConfig, setFilterConfig] = useState({
    is_updated: false
  });
  const [isFetching, setIsFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  
  const tableColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
  ];

  const confirmDelete = (project) => {
    modal.confirm({
      title: `Are you sure you want to delete project "${project.name}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleDelete(project.id),
      okType: 'danger',
    });
  };

  const onFinish = (formInputs) => {
    // var values = Object.entries(formInputs).map(([key, value]) => ({ key, value }));
    callPost(
      "projects/add", 
      formInputs,
      false
    );
  };

  const onEdit = (formInputs) => {
    // var values = Object.entries(formInputs).map(([key, value]) => ({ key, value }));
    callPost(
      `projects/update/${clickedRowData.id}`, 
      formInputs,
      false
    );
  };

  const handleSearch = (e) => {
    if (e.key==="Enter") {
      setFilterConfig((prev) => ({...prev, term: searchTerm}));
    }
  }

  function handleDelete(passedId) {
    callPost("projects/delete/" + passedId,  {}, false);
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData);
    form.setFieldsValue(rowData); 
    // setInitialValues(()=>{return{...rowData}});
    setShowViewModal(true);
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }, 2000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchTerm]);

React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UseGetRequest("projects/get", filterConfig);
    if (response.data) {
        setTableData(response.data.data);
    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar pageTitle="Projects" pageButtonTitle="Add Project" setSearchQuery={(e)=>setSearchTerm(e)} onSearch={handleSearch} searchPlaceholder="project" onClickButton={()=>setShowAddModal(true)} isManager searchable>
        <Table className='mt-5' rowKey="id"
          onRow={(record) => ({
            ...rowProps(record), style: { cursor: 'pointer' },
          })} 
          columns={tableColumns} 
          dataSource={tableData} 
          loading={isFetching || isLoading} />

        <Modal
          title={`${showAddModal? "Add" : "Edit"} Project`}
          centered
          open={showAddModal||showEditModal}
          footer={null}
          onCancel={() => {
            setShowAddModal(false); 
            setShowEditModal(false);
            form.resetFields();
          }}
        >
          <Form className='mt-3 mx-3' form={form} name="validateOnly" layout='vertical' onFinish={showAddModal?onFinish:onEdit}>
            <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
              <Input />
            </Form.Item>

            <Form.Item>
              <Row className='d-flex justify-content-between'>
                <Row>
                  <Button className='me-2' danger onClick={()=>{setShowAddModal(false); setShowEditModal(false)}} disabled={isLoading}>Cancel</Button>
                  <AddSubmitButton form={form} isLoading={isLoading} />
                </Row>
              </Row>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Review Project"
          centered
          open={showViewModal}
          footer={null}
          onCancel={() => {
            setShowViewModal(false); 
            form.resetFields();
          }}
        >
          <Form className='mt-3 mx-3' form={form} name="editForm" layout='vertical'>
            <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
              <Input disabled/>
            </Form.Item>
          
            <Form.Item>
              <Row className='d-flex justify-content-end'>
                  <Button danger onClick={()=>setShowViewModal(false)} disabled={isLoading}>Cancel</Button>
                  <Button type="text" onClick={()=>{setShowViewModal(false); setShowEditModal(true)}} icon={<EditOutlined/>}>Edit</Button>
                  <Button type="text" danger onClick={()=>{confirmDelete(clickedRowData)}} icon={<DeleteOutlined/>}>Delete</Button>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
        {contextHolder}
    </Navbar>
  )
}