import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import {Table, Select, Button, Modal, Row, Col, Form, DatePicker, Upload} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined, CheckCircleOutlined, EyeOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import { UsePostRequest } from "../../utils/api/ApiRequests";
import {GetAllEmployees} from "../../utils/api/DropdownFiltersApi";
import { usePostRequest } from "../../hooks/usePostRequest";
import ReactQuillEditor from "../../components/ReactQuillEditor/ReactQuillEditor";
import { getIsAdminLogin, getEmployeeId } from "../../utils/Common";


const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Submit
    </Button>
  );
};

const {Dragger} = Upload;

export default function Leave () {
  const [form] = Form.useForm();
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const navigate = useNavigate();
  const [content, setContent] = useState('');
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  const [leaveTypes, setLetterTypes] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const employeeOptions = GetAllEmployees();
  const dateFormat = "YYYY-MM-DD";
  const [searchTerm, setSearchTerm] = useState("");

  const { callPost, isLoading } = usePostRequest(() => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowViewModal(false);
    resetValues();
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }));
  });

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: isAdminLogin?'':employeeId,
  });


  const tableColumns = [
    {
        title: "Employee",
        dataIndex: "employee_name",
        key: "employee_name",
    },
    {
      title: "Date Applied",
      dataIndex: "added_on",
      key: "added_on",
      render: (added_on, record) => (
        <div>{moment(added_on).format("MMM DD, YYYY")}</div>
      ),
    },
    {
        title: "Date From",
        dataIndex: "date_from",
        key: "date_from",
        render: (date_from, record) => (
          <div>{moment(date_from).format("MMM DD, YYYY")}</div>
        ),
      },
      {
        title: "Date To",
        dataIndex: "date_to",
        key: "date_to",
        render: (date_to, record) => (
          <div>{moment(date_to).format("MMM DD, YYYY")}</div>
        ),
      },
    {
      title: "Days",
      dataIndex: "no_of_days",
      key: "no_of_days",
    },
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    //   render: (details, record) => (
    //     <ul
    //       onClick={() => handleRowClick(record)}
    //       className="pointer-cursor custom-list"
    //     >
    //       {details.map((value, index) => (
    //         <li key={index}>{value.leave_to}</li>
    //       ))}
    //     </ul>
    //   ),
    },
  ];

 
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const onFinish = async (formInputs) => {
    formInputs.date_from = formInputs.date_from.format(dateFormat);
    formInputs.date_to = formInputs.date_to.format(dateFormat);
    formInputs.status = "pending";
    // const tempFiles = fileList.map( async (file) => {
    //     var info = {}
    //     info.file_name = file.name;
    //     info.file_attachment = await getBase64(file);
    //     return info;
    // });

    formInputs.file_names = [];

    callPost(
      "employee_leaves/add",
      formInputs,
      false
    );
  };

  const onEdit = async (formInputs) => {
    formInputs.employee_leave_id = clickedRowData.id;
    formInputs.date_from = formInputs.date_from.format(dateFormat);
    formInputs.date_to = formInputs.date_to.format(dateFormat);
    formInputs.status = clickedRowData.status;
    
    formInputs.file_names = [];
    callPost(`employee_leaves/update`, formInputs, false);
  };

  const confirmApprove = (leave) => {
    modal.confirm({
      title: `Are you sure you want to approve leave ID "${leave.id}"?`,
      icon: <CheckCircleOutlined />,
    //   content: "You cannot undo this action.",
      okText: "Approve",
      cancelText: "Cancel",
      onOk: () => handleApprove(leave.id),
    //   okType: "danger",
    });
  };

  function handleApprove(passedId) {
    callPost("employee_leaves/approve", { employee_leave_id: passedId, status: "approved" }, false);
    setShowViewModal(false);
    resetValues();
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }));
  }

  const confirmDelete = (leave) => {
    modal.confirm({
      title: `Are you sure you want to delete leave ID "${leave.id}"?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(leave.id),
      okType: "danger",
    });
  };

  function handleDelete(passedId) {
    callPost("employee_leaves/delete", { employee_leave_id: passedId }, false);
    resetValues();
  }

  function resetValues () {
    form.resetFields();
    setContent("");
    setFileList([]);
  }

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }
  };
  
  const handleRowClick = (rowData) => {
    setContent(rowData.remarks);
    setClickedRowData(rowData);
    const formattedValues = { ...rowData };
    formattedValues.date_from = formattedValues.date_from?dayjs(formattedValues.date_from, dateFormat):'';
    formattedValues.date_to = formattedValues.date_to?dayjs(formattedValues.date_to, dateFormat):'';
    form.setFieldsValue(formattedValues);
    // setInitialValues(() => {
    //   return { ...rowData };
    // });
    setShowViewModal(true);
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

  const handleQuillChange = (html) => {
    setContent(html);
    form.setFieldValue('remarks', html);
  }

  const handleRemove = (selectedFile) => {
    var newlist = fileList.filter((file) => {
      return selectedFile.uid !== file.uid;
    });
    setFileList(newlist);
  };

  const handleBeforeUpload = (file) => {
    const isSizeValid = file.size / 1024 / 1024 < 5; // Check if file size is less than 2MB (backend request)

    if (!isSizeValid) {
      setFileSizeExceeded(true);
    } else {
      setFileList([...fileList, file]);
    }
  };

    // React.useEffect(() => {
    //     const formattedValues = { ...initialValues };
    //     formattedValues.date_from = formattedValues.date_from?dayjs(formattedValues.date_from, dateFormat):'';
    //     formattedValues.date_to = formattedValues.date_to?dayjs(formattedValues.date_to, dateFormat):'';
    //     form.setFieldsValue(formattedValues);
    // }, [initialValues]);


    React.useEffect(() => {
        async function fetchLetterTypes () {
            const response = await UsePostRequest("leave_types/get", {});
            if (response.data) {
                const res = response.data.map((data) => {
                    var info = {}
                    info.value = data.id;
                    info.label = data.description;
                    return info;
                })
                setLetterTypes(res);
            }
        }
        fetchLetterTypes()
    }, [])

    React.useEffect(() => {
      const searchTimeout = setTimeout(() => {
        setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
      }, 2000);
  
      return () => {
        clearTimeout(searchTimeout);
      };
    }, [searchTerm]);

  React.useEffect(() => {
    async function fetchTableData() {
      setIsFetching(true);
      setTableData([]);
      const response = await UsePostRequest("employee_leaves/get", filterConfig);
      if (response.data) {
        setTableData(response.data);
      }
      setIsFetching(false);
    }

    fetchTableData();
  }, [filterConfig]);

  return (
    <Navbar
      pageTitle="Leaves"
      pageButtonTitle="Add Leave"
      onClickButton={() => {setShowAddModal(true); setShowViewModal(false)}}
      searchPlaceholder="employee" setSearchQuery={(e) => setSearchTerm(e)} onSearch={handleSearch}
      isManager
      searchable
    >

      <Table
        className="mt-2"
        rowKey="id"
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching}
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
      />
      <Modal
        title={`${showAddModal ? "Add" : "Edit"} Leave`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={employeeOptions.slice(1)} searchable/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="leave_id"
                label="Leave Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={leaveTypes} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date_from"
                label="Date From"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker  placeholder="YYYY-MM-DD"/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date_to"
                label="Date To"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker  placeholder="YYYY-MM-DD"/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="remarks"
                label="Remarks"
              >
                <ReactQuillEditor content={content} handleQuillChange={handleQuillChange}/>
              </Form.Item>
            </Col>
          </Row>

          {/* <Row className="mb-3">
            Attachments
            <Col span={24}>
              <Dragger
                {...{
                  fileList: fileList,
                  onRemove: handleRemove,
                  beforeUpload: handleBeforeUpload,
                  multiple: false,
                  listType: "picture",
                  progress: { showInfo: true },
                  data: (file) => {},
                }}
              >
                <CloudUploadOutlined className="upload-icon" />
                <p className="ant-upload-text">Browse Files</p>
              </Dragger>
            </Col>
          </Row> */}

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Leave"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={employeeOptions.slice(1)} searchable disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="leave_id"
                label="Leave Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={leaveTypes} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date_from"
                label="Date From"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date_to"
                label="Date To"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="remarks"
                label="Remarks"
              >
                <ReactQuillEditor content={content} handleQuillChange={handleQuillChange} isDisabled={true}/>
              </Form.Item>
            </Col>
          </Row>

          {/* <Row className="mb-3">
            Attachments
            <Col span={24}>
              <Dragger
                {...{
                  fileList: fileList,
                  onRemove: handleRemove,
                  beforeUpload: handleBeforeUpload,
                  multiple: false,
                  listType: "picture",
                  progress: { showInfo: true },
                  data: (file) => {},
                }}
                disabled
              >
                <CloudUploadOutlined className="upload-icon" />
                <p className="ant-upload-text">Browse Files</p>
              </Dragger>
            </Col>
          </Row> */}
          

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowViewModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
                disabled={clickedRowData.status==="approved"}
              >
                Edit
              </Button>
              <Button
                type="text"
                onClick={() => {
                  confirmApprove(clickedRowData)
                }}
                icon={<CheckCircleOutlined />}
                disabled={clickedRowData.status==="approved"}
              >
                Approve
              </Button>
              <Button
                type="text"
                onClick={() => {
                  navigate("view", {state: clickedRowData})
                }}
                icon={<EyeOutlined />}
              >
                View
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
                disabled={clickedRowData.status==="approved"}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={fileSizeExceeded}
        title={""}
        footer={null}
        onCancel={() => setFileSizeExceeded(false)}
      >
        File size must not exceed 5MB.
      </Modal>
      {contextHolder}
    </Navbar>
  );
}