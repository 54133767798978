import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Button, Form, Modal, Table, Row, Col, DatePicker, Select } from 'antd'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { usePostRequest } from '../../hooks/usePostRequest'
import { UsePostRequest } from '../../utils/api/ApiRequests'
import moment from 'moment'
import dayjs from 'dayjs'
import { GetAllEmployees, GetAllShifts } from '../../utils/api/DropdownFiltersApi'


const AddSubmitButton = ({ form, isLoading }) => {
    const [submittable, setSubmittable] = useState(false);
    const values = Form.useWatch([], form);
      React.useEffect(() => {
        form.validateFields({
          validateOnly: true,
        }).then(() => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
        );
      }, [values]);

      return (
        <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Submit</Button>
      );
  };

export default function Schedule() {
  const [form] = Form.useForm();
  const employeeOptions = GetAllEmployees();
  const shiftOptions = GetAllShifts();
  const dateFormat = 'YYYY-MM-DD';
  
  const {callPost, isLoading} = usePostRequest(()=>{
    setShowAddModal(false); 
    setShowEditModal(false);
    setShowViewModal(false);
    setFilterConfig((prev) => ({...prev, is_updated: !prev.is_updated}))});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  // const [initialValues, setInitialValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [modal, contextHolder] = Modal.useModal();

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const [isFetching, setIsFetching] = useState(false);
  
  const tableColumns = [
    {
        title: 'Employee',
        dataIndex: 'full_name',
        key: 'full_name',
    },
    {
        title: 'Shift Name',
        dataIndex: 'shift_name',
        key: 'shift_name',
    },
    {
        title: 'Date From',
        dataIndex: 'date_from',
        key: 'date_from',
        render: (date_from, record) => (
            <div onClick={()=>handleRowClick(record)}>{moment(date_from).format('MMM DD, YYYY')}</div>
        )
    },
    {
        title: 'Date To',
        dataIndex: 'date_to',
        key: 'date_to',
        render: (date_to, record) => (
            <div onClick={()=>handleRowClick(record)}>{moment(date_to).format('MMM DD, YYYY')}</div>
        )
    },
  ];

  const onFinish = (formInputs) => {
    formInputs.date_from = formInputs.date_from.format(dateFormat);
    formInputs.date_to = formInputs.date_to.format(dateFormat);
    callPost(
      "schedules/add", 
      formInputs,
      false
    );
  };

  const onEdit = (formInputs) => {
    formInputs.date_from = formInputs.date_from.format(dateFormat);
    formInputs.date_to = formInputs.date_to.format(dateFormat);
    formInputs.schedule_id = clickedRowData.id;
    callPost(
      `schedules/update`, 
      formInputs,
      false
    );
  };

  const confirmDelete = (attendance) => {
    modal.confirm({
      title: `Are you sure you want to delete attendance ID "${attendance.id}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleDelete(attendance.id),
      okType: 'danger',
    });
  };


  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({...prev, term: searchTerm}));
    }
  }

  function handleDelete(passedId) {
    callPost("schedules/delete",  {schedule_id: passedId}, false);
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData);
    const formattedValues = { ...rowData };
    formattedValues.date_from = rowData.date_from?dayjs(rowData.date_from, dateFormat):'';
    formattedValues.date_to = rowData.date_to?dayjs(rowData.date_to, dateFormat):'';
    form.setFieldsValue(formattedValues); 
    // setInitialValues(()=>{return{...rowData}});
    setShowViewModal(true);
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }, 2000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchTerm]);
  
React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UsePostRequest("schedules/get", filterConfig);
    if (response.data) {
        setTableData(response.data);
    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar
      pageTitle="Schedules"
      pageButtonTitle="Add Schedule"
      searchPlaceholder="employee" setSearchQuery={(e) => setSearchTerm(e)} onSearch={handleSearch}
      onClickButton={() => setShowAddModal(true)}
      isManager
      searchable
      //   withDateRangePicker
      //   onDateChange={handleDateFilter}
    >
      <Table
        className="mt-5"
        rowKey="id"
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching || isLoading}
      />

      <Modal
        title={`${showAddModal ? "Add" : "Edit"} Schedule`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          form.resetFields();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
        >
          <Row>
            <Col xs={24} lg={12}>
            <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={employeeOptions.slice(1)} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="shift_id"
                label="Shift"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={shiftOptions.slice(1)} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date_from"
                label="Date From"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker placeholder="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date_to"
                label="Date To"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker placeholder="YYYY-MM-DD"/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Schedule"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          form.resetFields();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="full_name"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={employeeOptions.slice(1)} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="shift_id"
                label="Shift"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={shiftOptions.slice(1)} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date_from"
                label="Date From"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="date_to"
                label="Date To"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowViewModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
              >
                Edit
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {contextHolder}
    </Navbar>
  );
}