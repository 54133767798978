import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import {Table, Select, Button, Modal, Row, Col, Form, DatePicker, Input} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,CheckCircleOutlined, EyeOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import 'quill/dist/quill.snow.css';
import { UseGetRequest, UsePostRequest } from "../../utils/api/ApiRequests";
import {
  GetAllBranches,
  GetAllBusinessUnits,
  GetAllDepartments,
  GetAllEmploymentStatus,
  GetAllPositions,
  GetAllProjects,
  GetAllEmployees
} from "../../utils/api/DropdownFiltersApi";
import { usePostRequest } from "../../hooks/usePostRequest";
import ReactQuillEditor from "../../components/ReactQuillEditor/ReactQuillEditor";


const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Submit
    </Button>
  );
};

const {Option} = Select;

export default function CommunicationLetter() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [content, setContent] = useState('');
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  // const [initialValues, setInitialValues] = useState({});
  const [letterTypes, setLetterTypes] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const branchOptions = GetAllBranches();
  const statusOptions = GetAllEmploymentStatus();
  const positionOptions = GetAllPositions();
  const businessUnitOptions = GetAllBusinessUnits();
  const projectOptions = GetAllProjects();
  const departmentOptions = GetAllDepartments();
  const employeeOptions = GetAllEmployees();
  const dateFormat = "YYYY-MM-DD";
  const [searchTerm, setSearchTerm] = useState("");

  const dropdownOptions = {
    branch: branchOptions,
    business_unit: businessUnitOptions,
    department: departmentOptions,
    employee: employeeOptions,
    employment_status: statusOptions,
    position: positionOptions,
    project: projectOptions,
  };

  const { callPost, isLoading } = usePostRequest(() => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowViewModal(false);
    resetValues();
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }));
  });

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
  });


  const tableColumns = [
    {
      title: "Date Posted",
      dataIndex: "letter_date",
      key: "letter_date",
      render: (letter_date, record) => (
        <div>{moment(letter_date).format("MMM DD, YYYY")}</div>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Letter From",
      dataIndex: "letter_from",
      key: "letter_from",
    },
    {
      title: "Letter To",
      dataIndex: "details",
      key: "details",
      render: (details, record) => (
        <ul
          onClick={() => handleRowClick(record)}
          className="pointer-cursor custom-list"
        >
          {details.map((value, index) => (
            <li key={index}>{value.letter_to}</li>
          ))}
        </ul>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const onFinish = (formInputs) => {
    formInputs.letter_date = formInputs.letter_date.format(dateFormat);
    formInputs.letter_details = formInputs.recipients;
    formInputs.status = "pending";
    callPost(
      "letters/add",
      formInputs,
      false
    );
  };

  const onEdit = (formInputs) => {
    formInputs.letter_id = clickedRowData.id;
    formInputs.letter_date = formInputs.letter_date.format(dateFormat);
    formInputs.letter_details = formInputs.recipients;
    callPost(`letters/update`, formInputs, false);
  };

  const confirmDelete = (letter) => {
    modal.confirm({
      title: `Are you sure you want to delete letter ID "${letter.id}"?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(letter.id),
      okType: "danger",
    });
  };

  function handleDelete(passedId) {
    callPost("letters/delete", { letter_id: passedId }, false);
    resetValues();
  }

  const confirmApprove = (letter) => {
    modal.confirm({
      title: `Are you sure you want to approve letter ID "${letter.id}"?`,
      icon: <CheckCircleOutlined />,
      okText: "Approve",
      cancelText: "Cancel",
      onOk: () => handleApprove(letter.id),
    });
  };

  function handleApprove(passedId) {
    callPost("letters/approve", { letter_id: passedId, status: "approved" }, false);
    resetValues();
  }

  function resetValues () {
    form.resetFields();
    setContent("");
  }

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }
  };
  
  const handleRowClick = (rowData) => {
    setContent(rowData.content);
    setClickedRowData(rowData);
    const formattedValues = { ...rowData };
    formattedValues.letter_date = rowData.letter_date?dayjs(rowData.letter_date, dateFormat):'';
    form.setFieldsValue(formattedValues);
    setShowViewModal(true);
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

  const handleQuillChange = (html) => {
    setContent(html);
    form.setFieldValue('content', html);
  }

    // React.useEffect(() => {
    //     const formattedValues = { ...initialValues };
    //     formattedValues.letter_date = initialValues.letter_date?dayjs(initialValues.letter_date, dateFormat):'';
    //     form.setFieldsValue(formattedValues);
    // }, [initialValues]);


    React.useEffect(() => {
        async function fetchLetterTypes () {
            const response = await UseGetRequest("letter_types/get", {});
            if (response.data) {
                const res = response.data.data.map((data) => {
                    var info = {}
                    info.value = data.id;
                    info.label = data.name;
                    return info;
                })
                setLetterTypes(res);
            }
        }
        fetchLetterTypes()
    }, [])

    React.useEffect(() => {
      const searchTimeout = setTimeout(() => {
        setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
      }, 2000);
  
      return () => {
        clearTimeout(searchTimeout);
      };
    }, [searchTerm]);

  React.useEffect(() => {
    async function fetchTableData() {
      setIsFetching(true);
      setTableData([]);
      const response = await UsePostRequest("letters/get", filterConfig);
      if (response.data) {
        const res = response.data.letters.map((data) => {
          var info = data;
          info.recipients = data.details.map((rec) => {
            var recInfo = {}
            recInfo.type = rec.type;
            recInfo.letter_to = rec.letter_to_id;
            return recInfo;
          });
          return info;
        })
        setTableData(res);
      }
      setIsFetching(false);
    }

    fetchTableData();
  }, [filterConfig]);

  return (
    <Navbar
      pageTitle="Communication Letters"
      pageButtonTitle="Add Communication Letter"
      onClickButton={() => {setShowAddModal(true); setShowViewModal(false)}}
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      searchPlaceholder="subject"
      isManager
      searchable
    >

      <Table
        className="mt-2"
        rowKey="id"
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching}
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
      />
      <Modal
        title={`${showAddModal ? "Add" : "Edit"} Communication Letter`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
        >
          <Row>
            <Col xs={24} lg={8}>
              <Form.Item
                name="letter_date"
                label="Date Posted"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker placeholder="YYYY-MM-DD"/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                name="letter_from"
                label="Letter From"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                name="letter_type_id"
                label="Letter Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={letterTypes} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="subject"
                label="Subject"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="content"
                label="Content"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <ReactQuillEditor content={content} handleQuillChange={handleQuillChange}/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            Recipients
            <Form.List
              name="recipients"
              initialValue={[{}]}
            >
              {(fields, { add, remove }) => (
                <>
                  <Col xs={24} lg={24}>
                    {fields.map(({ key, name, fieldKey }) => (
                        <Row
                          key={key}
                          style={{ marginBottom: 0 }}
                          className="d-flex justify-content-between"
                        >
                          <Col xs={11}>
                            <Form.Item
                              name={[name, "type"]}
                              fieldKey={[fieldKey, "type"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select the recipient type",
                                },
                              ]}
                            >
                              <Select placeholder="Type" onChange={(value) => {
                                // update form everytime this is changed in order to access the letter dropdown options
                                form.setFieldsValue({ [`${name}.type`]: value });
                              }}>
                                <Option value="branch">Branch</Option>
                                <Option value="business_unit">Business Unit</Option>
                                <Option value="department">Department</Option>
                                <Option value="employee">Employee</Option>
                                <Option value="employment_status">Employment Status</Option>
                                <Option value="position">Position</Option>
                                <Option value="project">Project</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={11}>
                            <Form.Item
                              name={[name, "letter_to"]}
                              fieldKey={[fieldKey, "letter_to"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select the letter to",
                                },
                              ]}
                            >
                              <Select placeholder="Communication Letter To" options={dropdownOptions[form.getFieldValue(["recipients", name, "type"])]}/>
                            </Form.Item>
                          </Col>
                          <Col xs={2}>
                            <Button
                              onClick={() => {
                                remove(name);
                              }}
                              icon={<DeleteOutlined />}
                            />
                          </Col>
                        </Row>
                      ))}
                  </Col>
                  <Row>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusCircleOutlined />}
                    >
                      Add Recipient
                    </Button>
                  </Row>
                </>
              )}
            </Form.List>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Communication Letter"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          resetValues();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={24} lg={8}>
              <Form.Item
                name="letter_date"
                label="Date Posted"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                name="letter_from"
                label="Letter From"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                name="letter_type_id"
                label="Letter Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={letterTypes} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="subject"
                label="Subject"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="content"
                label="Content"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
               <ReactQuillEditor content={content} isDisabled={true}/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            Recipients
            <Form.List
              name="recipients"
              initialValue={[{}]}
            >
              {(fields, { add, remove }) => (
                <>
                  <Col xs={24} lg={24}>
                    {fields.map(({ key, name, fieldKey }) => (
                        <Row
                          key={key}
                          style={{ marginBottom: 0 }}
                          className="d-flex justify-content-between"
                        >
                          <Col xs={12}>
                            <Form.Item
                              name={[name, "type"]}
                              fieldKey={[fieldKey, "type"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select the recipient type",
                                },
                              ]}
                            >
                              <Select placeholder="Type" disabled>
                                <Option value="branch">Branch</Option>
                                <Option value="business_unit">Business Unit</Option>
                                <Option value="department">Department</Option>
                                <Option value="employment_status">Employment Status</Option>
                                <Option value="position">Position</Option>
                                <Option value="project">Project</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={12}>
                            <Form.Item
                              name={[name, "letter_to"]}
                              fieldKey={[fieldKey, "letter_to"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select the letter to",
                                },
                              ]}
                            >
                              <Select disabled placeholder="Communication Letter To" options={dropdownOptions[form.getFieldValue(["recipients", name, "type"])]}/>
                            </Form.Item>
                          </Col>
                          {/* <Col xs={4}>
                            <Button
                              onClick={() => {
                                remove(name);
                              }}
                              icon={<DeleteOutlined />}
                            />
                          </Col> */}
                        </Row>
                      ))}
                  </Col>
                  <Row>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusCircleOutlined />}
                    >
                      Add Recipient
                    </Button>
                  </Row>
                </>
              )}
            </Form.List>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowViewModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  // refreshPage();
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
                disabled={clickedRowData.status==="approved"}
              >
                Edit
              </Button>
              <Button
                type="text"
                onClick={() => {
                  confirmApprove(clickedRowData)
                }}
                icon={<CheckCircleOutlined />}
                disabled={clickedRowData.status==="approved"}
              >
                Approve
              </Button>
              <Button
                type="text"
                onClick={() => {
                  navigate("view", {state: clickedRowData})
                }}
                icon={<EyeOutlined />}
              >
                View
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </Navbar>
  );
}