import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Button, Form, Input, Modal, Table, Row, Upload, Col } from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined
} from "@ant-design/icons";
import { usePostRequest } from "../../hooks/usePostRequest";
import { UseGetRequest } from "../../utils/api/ApiRequests";

const AddSubmitButton = ({ form, isLoading, fileList }) => {
  const [submittable, setSubmittable] = useState(false);
  
  // Watch all values
  const values = Form.useWatch([], form);
  const isFileListEmpty = !fileList || fileList.length===0;

  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(!isFileListEmpty);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values, fileList]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Submit
    </Button>
  );
};

const EditSubmitButton = ({ form, isLoading, fileList }) => {
  const [submittable, setSubmittable] = useState(false);
  const isFileListEmpty = !fileList || fileList.length===0;

  // Watch all values
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(!isFileListEmpty);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values, fileList]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      loading={isLoading}
    >
      Save Changes
    </Button>
  );
};


const { Dragger } = Upload;

export default function CompanyFile() {
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const { callPost, isLoading } = usePostRequest(() => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowViewModal(false);
    resetFields();
    setFilterConfig((prev) => ({ ...prev, is_updated: !prev.is_updated }));
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);

  const [modal, contextHolder] = Modal.useModal();
  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleAnchorClick = (fileUrl) => {
    window.open(fileUrl, "_black");
  }

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, record) => (
        <div onClick={() => handleRowClick(record)} className="pointer-cursor">{name}</div>
      )
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description, record) => (
        <div onClick={() => handleRowClick(record)} className="pointer-cursor" >{description}</div>
      )
    },
    {
      title: "Attachments",
      dataIndex: "company_files",
      key: "company_files",
      align: "start",
      with: "50%",
      render: (company_files, record) => (
        <ul
          // onClick={() => handleRowClick(record)}
          // className="pointer-cursor custom-list"
        >
          {company_files && company_files.map((value, index) => (
            <li><Button className="no-style-btn" onClick={() => handleAnchorClick(value.file_url)}>{value.file_name}</Button></li>
          ))}
        </ul>
      ),
    },
  ];

  const confirmDelete = (companyFile) => {
    modal.confirm({
      title: `Are you sure you want to delete business unit "${companyFile.name}"?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(companyFile.id),
      okType: "danger",
    });
  };

  const onFinish = (formInputs) => {
    formInputs.attachments = fileList;

    var values = Object.entries(formInputs).map(([key, value]) => ({ key, value }));
    callPost("companies/add", values, true);
  };

  const onEdit = (formInputs) => {
    formInputs.company_id = clickedRowData.id;
    formInputs.attachments = fileList;

    var values = Object.entries(formInputs).map(([key, value]) => ({ key, value }));
    callPost("companies/update", values, true);
  };

  const handleSearch = (e) => {
    if (e.key==="Enter") {
      setFilterConfig((prev) => ({...prev, term: searchTerm}));
    }
  }

  function handleDelete(passedId) {
    callPost("companies/delete", { company_id: passedId }, false);
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData);
    const tempFiles = rowData.company_files.map((file) => {
      var info = {}
      info.id = file.id;
      info.name = file.file_name;
      return info;
    })
    setFileList(tempFiles);
    editForm.setFieldsValue(rowData);
    setShowViewModal(true);
  };


  const handleRemove = (selectedFile) => {
    var newlist = fileList.filter((file) => {
      return selectedFile.uid !== file.uid;
    });
    setFileList(newlist);
  };

  const handleBeforeUpload = (file) => {
    const isSizeValid = file.size / 1024 / 1024 < 5; // Check if file size is less than 2MB (backend request)

    if (!isSizeValid) {
      setFileSizeExceeded(true);
    } else {
      setFileList([...fileList, file]);
    }
  };

  function resetFields() {
    form.resetFields();
    editForm.resetFields();
    setFileList([]);
  }

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }, 2000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchTerm]);
  
  React.useEffect(() => {
    async function fetchTableData() {
      setIsFetching(true);
      setTableData([]);
      const response = await UseGetRequest("companies/get_all", filterConfig);
      if (response.data) {
        setTableData(response.data.data.company);
      }
      setIsFetching(false);
    }

    fetchTableData();
  }, [filterConfig]);

  return (
    <Navbar
      pageTitle="Company Files"
      pageButtonTitle="Add Company File"
      setSearchQuery={(e)=>setSearchTerm(e)} onSearch={handleSearch} searchPlaceholder="company file" 
      onClickButton={() => setShowAddModal(true)}
      isManager
      searchable
    >
      <Table
        className="mt-5"
        rowKey="id"
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching || isLoading}
      />

      <Modal
        title="Add Company File"
        centered
        open={showAddModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          resetFields();
        }}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: false,
              },
            ]}
            initialValue={''}
          >
            <Input />
          </Form.Item>

          <Row className="mb-3">
            <Col span={24}>
              <Form.Item name="attachment" label="Attachment" rules={[{required: true}]}>
              <Dragger
                {...{
                  fileList: fileList,
                  onRemove: handleRemove,
                  beforeUpload: handleBeforeUpload,
                  multiple: false,
                  listType: "picture",
                  progress: { showInfo: true },
                  data: (file) => {},
                }}
              >
                <CloudUploadOutlined className="upload-icon" />
                <p className="ant-upload-text">Browse Files</p>
              </Dragger>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => setShowAddModal(false)}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} fileList={fileList}/>
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Company File"
        centered
        open={showEditModal}
        footer={null}
        onCancel={() => {
          setShowEditModal(false);
          resetFields();
        }}
      >
        <Form
          className="mt-3 mx-3"
          form={editForm}
          name="editForm"
          layout="vertical"
          onFinish={onEdit}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: false,
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>

          <Row className="mb-3">
            <Col span={24}>
              <Dragger
                {...{
                  fileList: fileList,
                  onRemove: handleRemove,
                  beforeUpload: handleBeforeUpload,
                  multiple: false,
                  listType: "picture",
                  progress: { showInfo: true },
                  data: (file) => {},
                }}
              >
                <CloudUploadOutlined className="upload-icon" />
                <p className="ant-upload-text">Browse Files</p>
              </Dragger>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => {setShowEditModal(false); resetFields()}}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <EditSubmitButton form={editForm} isLoading={isLoading} fileList={fileList}/>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Company File"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          resetFields();
        }}
      >
        <Form
          className="mt-3 mx-3"
          form={editForm}
          name="editForm"
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input disabled/>
          </Form.Item>

          <Row className="mb-3">
            <Col span={24}>
              <Dragger
                {...{
                  fileList: fileList,
                  onRemove: handleRemove,
                  beforeUpload: handleBeforeUpload,
                  multiple: false,
                  listType: "picture",
                  progress: { showInfo: true },
                  data: (file) => {},
                }}
                disabled
              >
                <CloudUploadOutlined className="upload-icon" />
                <p className="ant-upload-text">Browse Files</p>
              </Dragger>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => {setShowViewModal(false); resetFields()}}
                disabled={isLoading}
              >
                Cancel
              </Button>
              {/* <Button type="text" onClick={()=>{setShowViewModal(false); setShowEditModal(true)}} icon={<EditOutlined/>}>Edit</Button> */}
             
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={fileSizeExceeded}
        title={""}
        footer={null}
        onCancel={() => setFileSizeExceeded(false)}
      >
        File size must not exceed 5MB.
      </Modal>
      {contextHolder}
    </Navbar>
  );
}
