import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Button, Form, Input, Modal, Table, Row, Col, Select } from 'antd'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { usePostRequest } from '../../hooks/usePostRequest'
import { UseGetRequest } from '../../utils/api/ApiRequests'


const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  
  // Watch all values
  const values = Form.useWatch([], form);
    React.useEffect(() => {
      form.validateFields({
        validateOnly: true,
      }).then(() => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
      );
    }, [values]);
    return (
    <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Submit</Button>
    );
};


export default function EmploymentStatus() {
  const [form] = Form.useForm();
  const {callPost, isLoading} = usePostRequest(()=>{
    setShowAddModal(false); 
    setShowEditModal(false);
    setShowViewModal(false);
    form.resetFields();
    setFilterConfig((prev) => ({...prev, is_updated: !prev.is_updated}))});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [allRequirements, setAllRequirements] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [filterConfig, setFilterConfig] = useState({
    is_updated: false
  });
  const [isFetching, setIsFetching] = useState(false);
  
  const tableColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
      title: 'Requirements',
      dataIndex: 'employment_requirements',
      key: 'employment_requirements',
      render: (employment_requirements) => (
        <ul>
          {
            employment_requirements && employment_requirements.map((data) => (
              <li>{data.name}</li>
            ))
          }
        </ul>
      )
  },
  ];

  const confirmDelete = (employment_status) => {
    modal.confirm({
      title: `Are you sure you want to delete employment status "${employment_status.name}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleDelete(employment_status.id),
      okType: 'danger',
    });
  };

  const onFinish = (formInputs) => {
    formInputs.no_of_employment_requirements = formInputs.employment_requirements.length;

    callPost(
      "employment_statuses/add", 
      formInputs,
      false
    );
  };

  const onEdit = (formInputs) => {
    formInputs.employment_status_id = clickedRowData.id;
    formInputs.no_of_employment_requirements = formInputs.employment_requirements.length;

    callPost(
      `employment_statuses/update`, 
      formInputs,
      false
    );
  };

  const handleSearch = (e) => {
    setFilterConfig((prev) => ({...prev, term: e}));
  }

  function handleDelete(passedId) {
    callPost("employment_statuses/delete",  {employment_status_id: passedId}, false);
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData);
    form.setFieldsValue(rowData); 
    setShowViewModal(true);
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });


React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UseGetRequest("employment_statuses/get", filterConfig);
    if (response.data) {
        setTableData(response.data.data);
    }
    setIsFetching(false);
  }

  async function fetchAllRequirements () {
    setAllRequirements([]);
    const response = await UseGetRequest("requirements/get", {});
    if (response.data) {
        const res = response.data.data.map((row) => {
            var info = {}
            info.label = row.name;
            info.value = row.id;
            return info;
        })
        setAllRequirements(res);
    }
  }

    fetchTableData ();
    fetchAllRequirements();
}, [filterConfig]);


  return (
    <Navbar
      pageTitle="Employment Status"
      pageButtonTitle="Add Employment Status"
      setSearchQuery={handleSearch}
      onClickButton={() => setShowAddModal(true)}
      isManager
      searchable
    >
      <Table
        className="mt-5"
        rowKey="id"
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching || isLoading}
      />

      <Modal
        title={`${showAddModal ? "Add" : "Edit"} Employment Status`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          form.resetFields();
        }}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Row>
            Requirements
            <Form.List name="employment_requirements" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  <Col xs={24} lg={24}>
                    {fields.map(({ key, name, fieldKey }) => (
                      <Row
                        key={key}
                        style={{ marginBottom: 0 }}
                        className="d-flex justify-content-between"
                      >
                        <Col xs={22}>
                          <Form.Item
                            name={[name, "requirement_id"]}
                            fieldKey={[fieldKey, "requirement_id"]}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select options={allRequirements}/>
                          </Form.Item>
                        </Col>
                        <Col xs={2}>
                          <Button
                            onClick={() => {
                              remove(name);
                            }}
                            icon={<DeleteOutlined />}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>

                  <Row>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusCircleOutlined />}
                    >
                      Add Recipient
                    </Button>
                  </Row>
                </>
              )}
            </Form.List>
          </Row>

          <Form.Item>
            <Row className="mt-2 d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Employment Status"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          form.resetFields();
        }}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Row>
            Requirements
            <Form.List name="employment_requirements" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  <Col xs={24} lg={24}>
                    {fields.map(({ key, name, fieldKey }) => (
                      <Row
                        key={key}
                        style={{ marginBottom: 0 }}
                        className="d-flex justify-content-between"
                      >
                        <Col xs={22}>
                          <Form.Item
                            name={[name, "requirement_id"]}
                            fieldKey={[fieldKey, "requirement_id"]}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select options={allRequirements} disabled/>
                          </Form.Item>
                        </Col>
                        <Col xs={2}>
                          <Button
                            onClick={() => {
                              remove(name);
                            }}
                            icon={<DeleteOutlined />}
                            disabled
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>

                  {/* <Row>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusCircleOutlined />}
                    >
                      Add Recipient
                    </Button>
                  </Row> */}
                </>
              )}
            </Form.List>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowViewModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
              >
                Edit
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </Navbar>
  );
}