import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { TabProvider } from "./context/TabContext";
import { Toaster } from "react-hot-toast";
import { globalStyles } from "./utils/theme";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '../src/cssFiles/CommonStyles/Common.css';

import { getToken, getIsAdminLogin } from "./utils/Common";

import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Payroll from "./pages/Payroll/Payroll";
import Payslip from "./pages/Payroll/Payslip";
import GeneratePayroll from "./pages/Payroll/GeneratePayroll";
import ThirteenthMonth from "./pages/Reports/ThirteenthMonth";
import Timesheet from "./pages/Timesheet/Timesheet";
import UploadTimesheet from "./pages/Timesheet/UploadTimesheet";
import Schedule from "./pages/Schedule/Schedule";
import Memorandum from "./pages/Memorandum/Memorandum";
import ViewMemorandum from "./pages/Memorandum/ViewMemorandum";
import Announcement from "./pages/Announcement/Announcement";
import ViewAnnouncement from "./pages/Announcement/ViewAnnouncement";
import CommunicationLetter from "./pages/CommunicationLetter/CommunicationLetter";
import ViewCommunicationLetter from "./pages/CommunicationLetter/ViewCommunicationLetter";
import Leave from "./pages/Leave/Leave";
import ViewLeave from "./pages/Leave/ViewLeave";
import WorkFromAnywhere from "./pages/WorkFromAnywhere/WorkFromAnywhere";
import ViewWorkFromAnywhere from "./pages/WorkFromAnywhere/ViewWorkFromAnywhere";
import EvaluationForm from "./pages/Form/EvaluationForm";
import EvaluationFormInputPage from "./pages/Form/EvaluationFormInputPage";
import PerformanceEvalutaion from "./pages/Reports/PerformanceEvaluation";
import Loan from "./pages/Loan/Loan";
import ViewLoan from "./pages/Loan/ViewLoan";

// Contribution Report
import PhicContributionReport from "./pages/PhicContributionReport/PhicContributionReport";
import ViewEmployeePhicContribution from "./pages/PhicContributionReport/ViewEmployeePhicContribution";
import PrintPhicContributionReport from "./pages/PhicContributionReport/PrintPhicContributionReport";
import SssContributionReport from "./pages/SssContributionReport/SssContributionReport";
import ViewEmployeeSssContribution from "./pages/SssContributionReport/ViewEmployeeSssContribution";
import PrintSssContributionReport from "./pages/SssContributionReport/PrintSssContributionReport";
import HdmfContributionReport from "./pages/HdmfContributionReport/HdmfContributionReport";
import ViewEmployeeHdmfContribution from "./pages/HdmfContributionReport/ViewEmployeeHdmfContribution";
import PrintHdmfContributionReport from "./pages/HdmfContributionReport/PrintHdmfContributionReport";

// Manage Employee Modules
import Employee from "./pages/Employee/Employee";
import EmployeeProfile from "./pages/Employee/EmployeeProfile";
// import EmployeeContact from "./pages/Employee/EmployeeContract";
import EmployeeHistoryReport from "./pages/Employee/EmployeeHistoryReport";
import LeaveCredit from "./pages/Reports/LeaveCredit";
import WfaCredit from "./pages/Reports/WfaCredits";
import FaceEnrollment from "./pages/FaceRecognition/FaceEnrollment/FaceEnrollment";
import FaceAttendance from "./pages/FaceRecognition/FaceAttendance/FaceAttendance";

// Manage Systems
import Branch from "./pages/Branch/Branch";
import BusinessUnit from "./pages/BusinessUnit/BusinessUnit";
import CompanyFile from "./pages/CompanyFile/CompanyFile";
import Department from "./pages/Department/Department";
import EmploymentStatus from "./pages/EmploymentStatus/EmploymentStatus";
import EmployeeStatus from "./pages/EmployeeStatus/EmployeeStatus";
import LeaveType from "./pages/LeaveType/LeaveType";
import LetterType from "./pages/LetterType/LetterType";
import NightDifferential from "./pages/NightDifferential/NightDifferential";
import Position from "./pages/Position/Position";
import Project from "./pages/Project/Project";
import Requirement from "./pages/Requirement/Requirement";
import Shift from "./pages/Shift/Shift";
import Holiday from "./pages/Holiday/Holiday";


/**
 *  MAIN APP
 */
function App() {
  const userToken = getToken();
  const isAdminLogin = getIsAdminLogin();
  
  return (
    <div>
      <TabProvider>
      <Toaster 
        position="top-right" 
        reverseOrder={false} 
        toastOptions={{
          style:{
            fontFamily: globalStyles.fontRegular,
            textTransform: 'capitalize'
          }
        }}
      />
      <Router>
        <Routes>
            <Route
              path="/"
              element={<Login />}
            />
            <Route
              path="/dashboard"
              element={userToken ? <Dashboard/> : <Login/>}
            />
            <Route
              path="/payrolls"
              element={userToken ? <Payroll/> : <Login/>}
            />
            <Route
              path="/payrolls/generate"
              element={userToken&&isAdminLogin ? <GeneratePayroll/> : <Login/>}
            />
            <Route
              path="/payrolls/payslip/:id"
              element={userToken ? <Payslip/> : <Login/>}
            />
            <Route
              path="/thirteenth-month-report"
              element={userToken ? <ThirteenthMonth/> : <Login/>}
            />
            <Route
              path="/timesheet"
              element={userToken ? <Timesheet/> : <Login/>}
            />
            <Route
              path="/timesheet/upload"
              element={userToken&&isAdminLogin ? <UploadTimesheet/> : <Login/>}
            />
            <Route
              path="/schedules"
              element={userToken&&isAdminLogin ? <Schedule/> : <Login/>}
            />
            <Route
              path="/memorandums"
              element={userToken ? <Memorandum/> : <Login/>}
            />
            <Route
              path="/memorandums/view"
              element={userToken ? <ViewMemorandum/> : <Login/>}
            />
            <Route
              path="/announcements"
              element={userToken ? <Announcement/> : <Login/>}
            />
            <Route
              path="/announcements/view"
              element={userToken ? <ViewAnnouncement/> : <Login/>}
            />
            <Route
              path="/communication-letters"
              element={userToken ? <CommunicationLetter/> : <Login/>}
            />
            <Route
              path="/communication-letters/view"
              element={userToken ? <ViewCommunicationLetter/> : <Login/>}
            />
            <Route
              path="/leaves"
              element={userToken ? <Leave/> : <Login/>}
            />
            <Route
              path="/leaves/view"
              element={userToken ? <ViewLeave/> : <Login/>}
            />
            <Route
              path="/wfas"
              element={userToken ? <WorkFromAnywhere/> : <Login/>}
            />
            <Route
              path="/wfas/view"
              element={userToken ? <ViewWorkFromAnywhere/> : <Login/>}
            />
            <Route
              path="/evaluation-forms"
              element={userToken ? <EvaluationForm/> : <Login/>}
            />
            <Route
              path="/evaluation-forms/form-page"
              element={userToken ? <EvaluationFormInputPage/> : <Login/>}
            />
            <Route
              path="/loans"
              element={userToken ? <Loan/> : <Login/>}
            />
            <Route
              path="/loans/view"
              element={userToken ? <ViewLoan/> : <Login/>}
            />
            <Route
              path="/performance-evaluation"
              element={userToken ? <PerformanceEvalutaion/> : <Login/>}
            />

            {/* CONTRIBUTION REPORTS */}
            <Route
              path="/phic-contribution-report"
              element={userToken ? <PhicContributionReport/> : <Login/>}
            />
            <Route
              path="/phic-contribution-report/view"
              element={userToken ? <ViewEmployeePhicContribution/> : <Login/>}
            />
            <Route
              path="/phic-contribution-report/print-report"
              element={userToken ? <PrintPhicContributionReport/> : <Login/>}
            />
            <Route
              path="/sss-contribution-report"
              element={userToken ? <SssContributionReport/> : <Login/>}
            />
            <Route
              path="/sss-contribution-report/view"
              element={userToken ? <ViewEmployeeSssContribution/> : <Login/>}
            />
            <Route
              path="/sss-contribution-report/print-report"
              element={userToken ? <PrintSssContributionReport/> : <Login/>}
            />
            <Route
              path="/hdmf-contribution-report"
              element={userToken ? <HdmfContributionReport/> : <Login/>}
            />
            <Route
              path="/hdmf-contribution-report/view"
              element={userToken ? <ViewEmployeeHdmfContribution/> : <Login/>}
            />
            <Route
              path="/hdmf-contribution-report/print-report"
              element={userToken ? <PrintHdmfContributionReport/> : <Login/>}
            />

            {/* MANAGE EMPLOYEE MODULES */}
            <Route
              path="/employees"
              element={userToken ? <Employee/> : <Login/>}
            />
            <Route
              path="/employees/view"
              element={userToken ? <EmployeeProfile/> : <Login/>}
            />
            {/* <Route
              path="/employees/contract"
              element={userToken ? <EmployeeContact/> : <Login/>}
            /> */}
            <Route
              path="/employees/history-report"
              element={userToken ? <EmployeeHistoryReport/> : <Login/>}
            />
            <Route
              path="/employees/face-enrollment"
              element={userToken ? <FaceEnrollment/> : <Login/>}
            />
            <Route
              path="/face-attendance"
              element={<FaceAttendance/>}
            />
            <Route
              path="/leave-credits"
              element={userToken ? <LeaveCredit/> : <Login/>}
            />
            <Route
              path="/wfa-credits"
              element={userToken ? <WfaCredit/> : <Login/>}
            />

      
            {/* MANAGE SYSTEMS */}
            <Route
              path="/branches"
              element={userToken&&isAdminLogin ? <Branch/> : <Login/>}
            />
            <Route
              path="/business-units"
              element={userToken&&isAdminLogin ? <BusinessUnit/> : <Login/>}
            />
            <Route
              path="/company-files"
              element={userToken&&isAdminLogin ? <CompanyFile/> : <Login/>}
            />
            <Route
              path="/departments"
              element={userToken&&isAdminLogin ? <Department/> : <Login/>}
            />
            <Route
              path="/employment-status"
              element={userToken&&isAdminLogin ? <EmploymentStatus/> : <Login/>}
            />
            <Route
              path="/employee-status"
              element={userToken&&isAdminLogin ? <EmployeeStatus/> : <Login/>}
            />
            <Route
              path="/leave-types"
              element={userToken&&isAdminLogin ? <LeaveType/> : <Login/>}
            />
            <Route
              path="/letter-types"
              element={userToken&&isAdminLogin ? <LetterType/> : <Login/>}
            />
            <Route
              path="/night-differentials"
              element={userToken&&isAdminLogin ? <NightDifferential/> : <Login/>}
            />
            <Route
              path="/positions"
              element={userToken&&isAdminLogin ? <Position/> : <Login/>}
            />
            <Route
              path="/projects"
              element={userToken&&isAdminLogin ? <Project/> : <Login/>}
            />
            <Route
              path="/requirements"
              element={userToken&&isAdminLogin ? <Requirement/> : <Login/>}
            />
            <Route
              path="/shifts"
              element={userToken&&isAdminLogin ? <Shift/> : <Login/>}
            />
            <Route
              path="/holidays"
              element={userToken&&isAdminLogin ? <Holiday/> : <Login/>}
            />
          </Routes>
      </Router>
      </TabProvider>
    </div>
  );
}

export default App;
