import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import Title from 'antd/es/typography/Title';
import { useNavigate } from 'react-router-dom';

import { Button, Card, Divider, Space, Statistic, Carousel, Modal, Collapse, DatePicker, Row, Col } from 'antd';
// import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { globalStyles } from '../../utils/theme';
import '../../../src/cssFiles/CommonStyles/Common.css';
import { formatDate, getLocalData } from '../../utils/Common';
import {UseGetRequest, UsePostRequest} from "../../utils/api/ApiRequests";

const {RangePicker} = DatePicker;
const {Panel} = Collapse;
export default function Dashboard() {
  let navigate = useNavigate();
  const dateToday = new Date();
  const [date, setDate] = useState(new Date());
  const [user, setUser] = useState("");
  const [filterConfig, setFilterConfig] = useState({
    date_from: moment().format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
  })
  
  const [recentActivities, setRecentActivities] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const [anniversaries, setAnniversaries] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [memos, setMemos] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [lackingEmployees, setLackingEmployees] = useState([]);
  const [showLackingModal, setShowLackingModal] = useState(false);
  const [companyFiles, setCompanyFiles] = useState([]);
  const [countData, setCountData] = useState({});
  const [showCountModal, setShowCountModal] = useState(false);
  const [countModalData, setCountModalData] = useState({title: '', employees: []});
  // const [employmentStatus, setEmploymentStatus] = useState([]);
  // const [employeeStatus, setEmployeeStatus] = useState([]);

  
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  function transformControllerName (name) {
    if (name && name.includes('_')) {
      return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    } else {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }
  }

  function handleModalClick (title, modalData) {
    setCountModalData({title: title, employees: modalData});
    setShowCountModal(true);
  } 

  const handleDateFilter = (e) => {
    if (e) {
        setFilterConfig((prev) => ({...prev,
            date_from: e[0].format('YYYY-MM-DD'),
            date_to: e[1].format('YYYY-MM-DD'),
        }));
    }
  }

  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  React.useEffect(()=>{
    async function getName() {
      const res = await getLocalData("name");
      setUser(res)
    }
    getName();
  })

  React.useEffect(() => {
    async function fetchRecentActivities () {
      setRecentActivities([]);
      const response = await UsePostRequest("users/get_recent_activities", {});
      if (response.data) {
        const tempData = response.data.map((row) => {
          var info = {}
          info.date = moment(row.responded_on).format('MMMM DD, YYYY');
          var controller = transformControllerName(row.controller)
          if (row.method === "add") {
            info.action = `Added ${controller}`
          } else if (row.method === "update") {
            info.action = `Updated ${controller}`
          } else if (row.method === "delete") {
            info.action = `Deleted ${controller}`
          } else {
            info.action = `${row.method} ${controller}`
          }
          return info;
        })
        setRecentActivities(tempData)
      }
    }

    async function fetchBirthdaysAndAnniversaries () {
      const response = await UsePostRequest("employees/get_all", {});
      if (response.data) {
        const currentDate = new Date();
        // const dateToday = new Date();

        const upcomingBirthdays = response.data.filter((employee) => {
          const birthdate = new Date(employee.birthdate);
          const nextBirthday = new Date(
            currentDate.getFullYear(),
            birthdate.getMonth(),
            birthdate.getDate(),
          );
        
          return (
            nextBirthday >= currentDate && nextBirthday <= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7)
          );
        });    

        const upcomingAnniversaries = response.data.filter((employee) => {
          const dateHired = new Date(employee.date_hired);
          const nextAnniv = new Date(
            currentDate.getFullYear(),
            dateHired.getMonth(),
            dateHired.getDate(),
          );
          
          return (
            nextAnniv >= currentDate && nextAnniv <= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7)
          );
        });
        setBirthdays(upcomingBirthdays);
        setAnniversaries(upcomingAnniversaries);
      }
    }

    async function fetchAnnouncementsAndMemos () {
      const announecements = await UseGetRequest("announcements/get");
      if (announecements.data) {
        setAnnouncements(announecements.data.data.announcements)
      }

      const memos = await UseGetRequest("memos/get");
      if (memos.data) {
        setMemos(memos.data.data.memos)
      }
    }

    async function fetchOtherData () {
      const tempRequirements = await UseGetRequest("dashboard/get_requirement_lacking_count");
      if (tempRequirements.data) {
        setRequirements(tempRequirements.data.reports);
      }

      const tempCompanyFiles = await UseGetRequest("companies/get_all");
      if (tempCompanyFiles.data) {
        setCompanyFiles(tempCompanyFiles.data.data.company);
      }

      // const tempEmploymentStatus = await UseGetRequest("dashboard/get_employment_status_report");
      // if (tempEmploymentStatus.data) {
      //   setEmploymentStatus(tempEmploymentStatus.data.reports);
      // }

      // const tempEmployeeStatus = await UseGetRequest("dashboard/get_employee_status_report");
      // if (tempEmployeeStatus.data) {
      //   setEmployeeStatus(tempEmployeeStatus.data.reports);
      // }
    }

    fetchRecentActivities();
    fetchBirthdaysAndAnniversaries();
    fetchAnnouncementsAndMemos();
    fetchOtherData();
  }, [])


  React.useEffect(() => {
    async function fetchCounts() {
      setCountData({})
      const response = await UsePostRequest("dashboard/get_counts", filterConfig);
      if (response.response) {
        setCountData(response.response);
      }
    }

    fetchCounts();
  }, [filterConfig])


  return (
    <Navbar>
      <Space className="d-flex justify-content-between p-3" wrap>
        <Title level={3}>Hello, {user}! 👋🏻</Title>
        <Title level={4}>
          {formattedDate}, {formattedTime}
        </Title>
      </Space>

      <Row className="p-3">
        <Col className="p-1" xs={24} lg={8}>
          <Card className="shadow" style={{ height: "50vh", paddingRight: 0 }}>
            <Row className="m-o p-0 d-flex align-items-center justify-content-between">
              <Col>
                <Title level={5}>Recent Activies</Title>
              </Col>
              {/* <Col style={{textAlign: 'right'}}><Button type='text' onClick={()=>navigate("/orders")}>View all</Button></Col> */}
            </Row>
            <Divider className="" />
            <Row
              style={{ height: "30vh", overflow: "auto", overflowX: "hidden" }}
            >
              {recentActivities.map((order, index) => {
                return (
                  <Card
                    key={index}
                    className="mt-1 w-100"
                    bodyStyle={{ padding: 10 }}
                    hoverable
                  >
                    <Row>
                      <Col className="">
                        <Row>
                          <Col className="fw-bold small-text">
                            {order.action}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="small-text text-muted">
                            {order.date}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
            </Row>
          </Card>
        </Col>
        <Col className="p-1" xs={24} lg={8}>
          <Card className="shadow" style={{ height: "50vh", paddingRight: 0 }}>
            <Row className="m-o p-0 d-flex align-items-center justify-content-between">
              <Col>
                <Title level={5}>Birthdays</Title>
              </Col>
              {/* <Col style={{textAlign: 'right'}}><Button type='text' onClick={()=>navigate("/orders")}>View all</Button></Col> */}
            </Row>
            <Divider />
            <Row
              style={{ height: "30vh", overflow: "auto", overflowX: "hidden" }}
            >
              {
                birthdays.length === 0 && 
                <Card className='gray text-center center w-100'>
                  NO UPCOMING BIRTHDAYS
                </Card>
              }
              {birthdays.length > 0 && birthdays.map((row, index) => {
                return (
                  <Card
                    key={index}
                    className="m-1 mt-0 w-100"
                    bodyStyle={{ padding: 10 }}
                    hoverable
                  >
                    <Row>
                      <Col className="col-10">
                        <Row>
                          <Col className="fw-bold small-text">
                            {`${row.first_name} ${row.middle_name} ${row.last_name}`}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="small-text text-muted">
                            {row.birthdate ? formatDate(row.birthdate) : ""}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
            </Row>
          </Card>
        </Col>
        <Col className="p-1" xs={24} lg={8}>
          <Card className="shadow" style={{ height: "50vh", paddingRight: 0 }}>
            <Row className="m-o p-0 d-flex align-items-center justify-content-between">
              <Col>
                <Title level={5}>Anniversaries</Title>
              </Col>
            </Row>
            <Divider />
            <Row
              style={{ height: "30vh", overflow: "auto", overflowX: "hidden" }}
            >
              {
                anniversaries.length === 0 && 
                <Card className='gray text-center center w-100'>
                  NO UPCOMING ANNIVERSARIES
                </Card>
              }
              {anniversaries.length > 0 && anniversaries.map((row, index) => {
                return (
                  <Card
                    key={index}
                    className="m-1 mt-0 w-100"
                    bodyStyle={{ padding: 10 }}
                    hoverable
                  >
                    <Row>
                      <Col className="col-10">
                        <Row>
                          <Col className="fw-bold small-text">
                            {`${row.first_name} ${row.middle_name} ${row.last_name}`}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="small-text text-muted">
                            {row.date_hired ? formatDate(row.date_hired) : ""}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
            </Row>
          </Card>
        </Col>
      </Row>

      <Row className="p-3">
        <Col className="p-1" xs={24} lg={12}>
          <Card
            className="shadow carousel-card"
            style={{ height: "50vh", paddingRight: 0 }}
          >
            <Row className="m-o p-0 d-flex align-items-center justify-content-between">
              <Col>
                <Title level={5}>Announcements</Title>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <Button type="text" onClick={() => navigate("/announcements")}>
                  View all
                </Button>
              </Col>
            </Row>
            <Divider />
            <Carousel autoplay>
              {announcements.map((data, index) => {
                return (
                  <div key={index} className="px-3">
                    <Row className="d-flex justify-content-between carousel-header">
                      <Col className="welcome-msg">
                        {data.subject ? data.subject : ""}
                      </Col>
                      <Col className="text-end">
                        {data.announcement_date
                          ? formatDate(data.announcement_date)
                          : ""}
                      </Col>
                    </Row>
                    <Row>
                      <div
                        dangerouslySetInnerHTML={{ __html: data.content }}
                        className="p-3 carousel-content"
                      />
                    </Row>
                  </div>
                );
              })}
            </Carousel>
          </Card>
        </Col>
        <Col className="p-1" xs={24} lg={12}>
          <Card
            className="shadow carousel-card"
            style={{ height: "50vh", paddingRight: 0 }}
          >
            <Row className="m-o p-0 d-flex align-items-center justify-content-between">
              <Col>
                <Title level={5}>Memorandum</Title>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <Button type="text" onClick={() => navigate("/memorandums")}>
                  View all
                </Button>
              </Col>
            </Row>
            <Divider />
            <Carousel autoplay>
              {memos.map((data, index) => {
                return (
                  <div key={index} className="px-3">
                    <Row className="d-flex justify-content-between carousel-header">
                      <Col className="welcome-msg">
                        {data.subject ? data.subject : ""}
                      </Col>
                      <Col className="text-end">
                        {data.announcement_date
                          ? formatDate(data.announcement_date)
                          : ""}
                      </Col>
                    </Row>
                    <Row>
                      <div
                        dangerouslySetInnerHTML={{ __html: data.content }}
                        className="p-3 carousel-content"
                      />
                    </Row>
                  </div>
                );
              })}
            </Carousel>
          </Card>
        </Col>
      </Row>

      <Row className="p-3">
        <Col className="p-1" xs={24} lg={12}>
          <Card className="shadow" style={{ height: "50vh", paddingRight: 0 }}>
            <Row className="m-o p-0 d-flex align-items-center justify-content-between">
              <Col>
                <Title level={5}>Lacking Requirements</Title>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <Button type="text" onClick={() => navigate("/requirements")}>
                  View all
                </Button>
              </Col>
            </Row>
            <Divider />
            <Row className="">
              {requirements.map((data) => (
                <Col xs={6} md={3} className="p-1" onClick={() => {
                  setLackingEmployees(data.employees);
                  setShowLackingModal(true);
                }}>
                  <Card bordered className="custom-card pointer-cursor">
                    <Statistic
                      title={data.status}
                      value={data.count}
                      valueStyle={{
                        color: globalStyles.colorPrimary,
                      }}
                      style={{ height: 70 }}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
        <Col className="p-1" xs={24} lg={12}>
          <Card className="shadow carousel-card" style={{ height: "50vh", paddingRight: 0 }}>
            <Row className="m-o p-0 d-flex align-items-center justify-content-between">
              <Col>
                <Title level={5}>Company Files</Title>
              </Col>
              {/* <Col style={{ textAlign: "right" }}>
                <Button type="text" onClick={() => navigate("/memorandums")}>
                  View all
                </Button>
              </Col> */}
            </Row>
            <Divider />
            <Collapse accordion>
              {companyFiles.map((data, index) => (
                <Panel header={data.name} key={index}>
                  {
                    data.company_files.map((file, index) => (
                      <Row className='ms-3'>{`${index+1}. ${file.file_name}`}</Row>
                    ))
                  }
                </Panel>
              ))}
            </Collapse>
          </Card>
        </Col>
      </Row>

      <Row className='mt-4 ms-4'>
        Filter by Date:<Col xs={8} className='ms-2'><RangePicker onChange={handleDateFilter}  placeholder={[dateToday, dateToday]}/></Col>
      </Row>
      <Row className='p-3'>
        <Col xs={24} md={12} lg={8} className="p-2">
          <Card>
              <Row className="m-0 p-0 d-flex align-items-center justify-content-between">
                  <Title level={5}>Present</Title>
              </Row>
              <Divider />
              <Card bordered className="custom-card pointer-cursor" onClick={() => handleModalClick("Present Employees", countData.present_employees)}>
                  <Statistic
                    title="Employees"
                    value={countData.present_count}
                    valueStyle={{
                      color: globalStyles.colorPrimary,
                    }}
                  />
                </Card>
            </Card>
        </Col>
        <Col xs={24} md={12} lg={8} className="p-2">
          <Card className="">
              <Row className="m-0 p-0 d-flex align-items-center justify-content-between">
                  <Title level={5}>Absent</Title>
              </Row>
              <Divider />
              <Card bordered className="custom-card pointer-cursor" onClick={() => handleModalClick("Absent Employees", countData.absent_employees)}>
                  <Statistic
                    title="Employees"
                    value={countData.absent_count}
                    valueStyle={{
                      color: globalStyles.colorPrimary,
                    }}
                  />
                </Card>
            </Card>
        </Col>
        <Col xs={24} md={12} lg={8} className="p-2">
          <Card className="">
              <Row className="m-0 p-0 d-flex align-items-center justify-content-between">
                  <Title level={5}>Leave</Title>
              </Row>
              <Divider />
              <Card bordered className="custom-card pointer-cursor" onClick={() => handleModalClick("Employees on Leave", countData.leave_employees)}>
                  <Statistic
                    title="Employees"
                    value={countData.leave_count}
                    valueStyle={{
                      color: globalStyles.colorPrimary,
                    }}
                  />
                </Card>
            </Card>
        </Col>
        <Col xs={24} md={12} lg={8} className="p-2">
          <Card className="">
              <Row className="m-0 p-0 d-flex align-items-center justify-content-between">
                  <Title level={5}>Late</Title>
              </Row>
              <Divider />
              <Card bordered className="custom-card pointer-cursor" onClick={() => handleModalClick("Late Employees", countData.late_employees)}>
                  <Statistic
                    title="Employees"
                    value={countData.late_counts}
                    valueStyle={{
                      color: globalStyles.colorPrimary,
                    }}
                  />
                </Card>
            </Card>
        </Col>
        <Col xs={24} md={12} lg={8} className="p-2">
          <Card className="">
              <Row className="m-0 p-0 d-flex align-items-center justify-content-between">
                  <Title level={5}>Regular OT</Title>
              </Row>
              <Divider />
              <Card bordered className="custom-card pointer-cursor" onClick={() => handleModalClick("Regular OT Employees", countData.regular_ot_employees)}>
                  <Statistic
                    title="Employees"
                    value={countData.regular_ot_count}
                    valueStyle={{
                      color: globalStyles.colorPrimary,
                    }}
                  />
                </Card>
            </Card>
        </Col>
        <Col xs={24} md={12} lg={8} className="p-2">
          <Card className="">
              <Row className="m-0 p-0 d-flex align-items-center justify-content-between">
                  <Title level={5}>Restday OT</Title>
              </Row>
              <Divider />
              <Card bordered className="custom-card pointer-cursor" onClick={() => handleModalClick("Restday OT Employees", countData.restday_ot_employees)}>
                  <Statistic
                    title="Employees"
                    value={countData.restday_ot_count}
                    valueStyle={{
                      color: globalStyles.colorPrimary,
                    }}
                  />
                </Card>
            </Card>
        </Col>
      </Row>
      <Row className='px-3'>
        <Col xs={24} md={12} lg={8} className="p-2">
          <Card className="">
              <Row className="m-0 p-0 d-flex align-items-center justify-content-between">
                  <Title level={5}>SSS</Title>
              </Row>
              <Divider />
              <Row>
                <Col xs={24} lg={12}>
                  <Card bordered className="custom-card">
                    <Statistic
                      title="EE"
                      value={countData.sss_total_employee}
                      valueStyle={{
                        color: globalStyles.colorPrimary,
                      }}
                    />
                  </Card>
                </Col>
                <Col xs={24} lg={12}>
                  <Card bordered className="custom-card">
                    <Statistic
                      title="ER"
                      value={countData.sss_total_employer}
                      valueStyle={{
                        color: globalStyles.colorPrimary,
                      }}
                    />
                  </Card>
                </Col>
              </Row>
            </Card>
        </Col>
        <Col xs={24} md={12} lg={8} className="p-2">
          <Card className="">
              <Row className="m-0 p-0 d-flex align-items-center justify-content-between">
                  <Title level={5}>PhilHealth</Title>
              </Row>
              <Divider />
              <Row>
                <Col xs={24} lg={12}>
                  <Card bordered className="custom-card">
                    <Statistic
                      title="EE"
                      value={countData.phic_total_employee}
                      valueStyle={{
                        color: globalStyles.colorPrimary,
                      }}
                    />
                  </Card>
                </Col>
                <Col xs={24} lg={12}>
                  <Card bordered className="custom-card">
                    <Statistic
                      title="ER"
                      value={countData.phic_total_employer}
                      valueStyle={{
                        color: globalStyles.colorPrimary,
                      }}
                    />
                  </Card>
                </Col>
              </Row>
            </Card>
        </Col>
        <Col xs={24} md={12} lg={8} className="p-2">
          <Card className="">
              <Row className="m-0 p-0 d-flex align-items-center justify-content-between">
                  <Title level={5}>Pag-IBIG</Title>
              </Row>
              <Divider />
              <Row>
                <Col xs={24} lg={12}>
                  <Card bordered className="custom-card">
                    <Statistic
                      title="EE"
                      value={countData.hdmf_total_employee}
                      valueStyle={{
                        color: globalStyles.colorPrimary,
                      }}
                    />
                  </Card>
                </Col>
                <Col xs={24} lg={12}>
                  <Card bordered className="custom-card">
                    <Statistic
                      title="ER"
                      value={countData.hdmf_total_employer}
                      valueStyle={{
                        color: globalStyles.colorPrimary,
                      }}
                    />
                  </Card>
                </Col>
              </Row>
            </Card>
        </Col>
      </Row>

      {/* <Row className="p-3">
        <Col className="p-1" xs={24} lg={12}>
          <Card className="shadow" style={{ height: "50vh", paddingRight: 0 }}>
            <Row className="m-o p-0 d-flex align-items-center justify-content-between">
              <Col>
                <Title level={5}>Employment Status</Title>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <Button
                  type="text"
                >
                  View all
                </Button>
              </Col>
            </Row>
            <Divider />
            <Row className="">
              {employmentStatus.map((data) => (
                <Col
                  xs={12}
                  md={6}
                  className="p-1"
                >
                  <Card bordered className="custom-card">
                    <Statistic
                      title={data.name}
                      value={data.count}
                      valueStyle={{
                        color: globalStyles.colorPrimary,
                      }}
                      style={{ height: 70 }}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row> */}

      <Modal
        open={showLackingModal}
        onCancel={() => setShowLackingModal(false)}
        onOk={() => setShowLackingModal(false)}
      >
        <Title level={5}>Peding Requirements</Title>
        <Divider />
        {lackingEmployees.map((row, index) => {
          return (
            <Card
              key={index}
              className="m-1 mt-0"
              bodyStyle={{ padding: 10 }}
              hoverable
            >
              <Row>
                <Col className="col-10">
                  <Row>
                    <Col className="fw-bold small-text">
                      {row}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          );
        })}
      </Modal>

      <Modal
        open={showCountModal}
        footer={false}
        onCancel={() => setShowCountModal(false)}
        // onOk={() => setShowCountModal(false)}
      >
        <Title level={5}>{countModalData.title}</Title>
        <Divider />
        {
        (!countModalData.employees || countModalData.employees.length===0) && 
          <Card className='gray text-center center w-100'>
            NO EMPLOYEES FOUND
          </Card>
        }
        {countModalData.employees && countModalData.employees.map((row, index) => {
          return (
            <Card
              key={index}
              className="m-1 mt-0"
              bodyStyle={{ padding: 10 }}
            >
              <Row>
                <Col className="col-10">
                  <Row>
                    <Col className="small-text">
                      {row.employee}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          );
        })}
        <Divider />
        <Row className='d-flex justify-content-end'><Button onClick={() => setShowCountModal(false)}>Close</Button></Row>
      </Modal>
      
    </Navbar>
  );
}