import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Table, Space, Select } from 'antd'
import { UsePostRequest } from '../../utils/api/ApiRequests'
import { capitalizeEachWord, formatNumber } from '../../utils/Common'
import { GetAllBranches, GetAllBusinessUnits, GetAllDepartments, GetAllEmploymentStatus, GetAllPositions, GetAllProjects } from '../../utils/api/DropdownFiltersApi'


export default function ThirteenthMonth() {
    // const isAdminLogin = getIsAdminLogin();
    // const employeeId = getEmployeeId();
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const branchOptions = GetAllBranches();
  const statusOptions = GetAllEmploymentStatus();
  const positionOptions = GetAllPositions();
  const businessUnitOptions = GetAllBusinessUnits();
  const projectOptions = GetAllProjects();
  const departmentOptions = GetAllDepartments();
  const [searchTerm, setSearchTerm] = useState("");

  
  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    // employee_id: isAdminLogin ? '' : employeeId,
  });
  
  const tableColumns = [
    {
        title: 'Name',
        dataIndex: 'employee_name',
        key: 'employee_name',
        render: (employee_name) => (
            <div>{capitalizeEachWord(employee_name)}</div>
        )
    },
    {
        title: 'Jan',
        dataIndex: 'january',
        key: 'january',
        align: 'right',
        render: (january, record) => (
            <div>{formatNumber(january)}</div>
        )
    },
    {
        title: 'Feb',
        dataIndex: 'february',
        key: 'february',
        align: 'right',
        render: (february, record) => (
            <div>{formatNumber(february)}</div>
        )
    },
    {
        title: 'Mar',
        dataIndex: 'march',
        key: 'march',
        align: 'right',
        render: (march, record) => (
            <div>{formatNumber(march)}</div>
        )
    },
    {
        title: 'Apr',
        dataIndex: 'april',
        key: 'april',
        align: 'right',
        render: (april, record) => (
            <div>{formatNumber(april)}</div>
        )
    },
    {
        title: 'May',
        dataIndex: 'may',
        key: 'may',
        align: 'right',
        render: (may, record) => (
            <div>{formatNumber(may)}</div>
        )
    },
    {
        title: 'Jun',
        dataIndex: 'june',
        key: 'june',
        align: 'right',
        render: (june, record) => (
            <div>{formatNumber(june)}</div>
        )
    },
    {
        title: 'Jul',
        dataIndex: 'july',
        key: 'july',
        align: 'right',
        render: (july, record) => (
            <div>{formatNumber(july)}</div>
        )
    },
    {
        title: 'Aug',
        dataIndex: 'august',
        key: 'august',
        align: 'right',
        render: (august, record) => (
            <div>{formatNumber(august)}</div>
        )
    },
    {
        title: 'Sep',
        dataIndex: 'september',
        key: 'september',
        align: 'right',
        render: (september, record) => (
            <div>{formatNumber(september)}</div>
        )
    },
    {
        title: 'Oct',
        dataIndex: 'october',
        key: 'october',
        align: 'right',
        render: (october, record) => (
            <div>{formatNumber(october)}</div>
        )
    },
    {
        title: 'Nov',
        dataIndex: 'november',
        key: 'november',
        align: 'right',
        render: (november, record) => (
            <div>{formatNumber(november)}</div>
        )
    },
    {
        title: 'Dec',
        dataIndex: 'december',
        key: 'december',
        align: 'right',
        render: (december, record) => (
            <div>{formatNumber(december)}</div>
        )
    },
  ];

  const handleSearch = (e) => {
    if (e.key === "Enter") {
        setFilterConfig((prev) => ({...prev, term: searchTerm}));
    }
  }

  const handleFilter = (e, field) => {
    setFilterConfig((prev) => ({...prev, [field]: e}));
  }

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }, 2000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchTerm]);

React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UsePostRequest("payrolls/thirteenth_month_report", filterConfig);
    if (response.data) {
        setTableData(response.data);
    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar pageTitle="Thirteenth Month Report" noButton  searchable searchPlaceholder="employee" setSearchQuery={(e) => setSearchTerm(e)} onSearch={handleSearch} isManager>
        <Space className='mt-3'>
            <Select
                showSearch
                style={{ width: 200,}}
                placeholder="Branch"
                optionFilterProp="items"
                filterOption={(input, option) => (option.label.toLowerCase().includes(input.toLowerCase()))}
                filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={branchOptions}
                onChange={(e) => handleFilter(e, 'branch_id')}
            />
            <Select
                showSearch
                style={{width: 200,}}
                placeholder="Status"
                optionFilterProp="items"
                filterOption={(input, option) => (option.label.toLowerCase().includes(input.toLowerCase()))}
                filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={statusOptions}
                onChange={(e) => handleFilter(e, 'employment_status_id')}
            />
            <Select
                showSearch
                style={{width: 200,}}
                placeholder="Position"
                optionFilterProp="items"
                filterOption={(input, option) => option.label.includes(input)}
                filterSort={(optionA, optionB) =>
                    optionA.label.toLowerCase().localeCompare((optionB.label).toLowerCase())
                }
                options={positionOptions}
                onChange={(e) => handleFilter(e, 'position_id')}
            />
            <Select
                showSearch
                style={{width: 200,}}
                placeholder="Business Unit"
                optionFilterProp="items"
                filterOption={(input, option) => option.label.includes(input)}
                filterSort={(optionA, optionB) =>
                    optionA.label.toLowerCase().localeCompare((optionB.label).toLowerCase())
                }
                options={businessUnitOptions}
                onChange={(e) => handleFilter(e, 'business_unit_id')}
            />
            <Select
                showSearch
                style={{width: 200,}}
                placeholder="Project"
                optionFilterProp="items"
                filterOption={(input, option) => option.label.includes(input)}
                filterSort={(optionA, optionB) =>
                    optionA.label.toLowerCase().localeCompare((optionB.label).toLowerCase())
                }
                options={projectOptions}
                onChange={(e) => handleFilter(e, 'project_id')}
            />
            <Select
                showSearch
                style={{width: 200,}}
                placeholder="Department"
                optionFilterProp="items"
                filterOption={(input, option) => option.label.includes(input)}
                filterSort={(optionA, optionB) =>
                    optionA.label.toLowerCase().localeCompare((optionB.label).toLowerCase())
                }
                options={departmentOptions}
                onChange={(e) => handleFilter(e, 'department_id')}
            />
        </Space>
        <Table className='mt-2' rowKey="id"
          columns={tableColumns} 
          dataSource={tableData} 
          loading={isFetching} />
    </Navbar>
  )
}