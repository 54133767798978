import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import { globalStyles } from './utils/theme';
import faceIO from "@faceio/fiojs"

//GLOBAL VARIABLES
window.$company_name = "Greencycle Innovative Solutions Inc.";
window.$company_address = "Fulgencio Road, Grand Asia Compound, Barangay Nagkaisang Nayon, Quezon City";
window.$faceioInstance = new faceIO("fioa5dab");
window.$company_phic_no = "0070-8000-8116";
window.$company_tin = "617-471-244-00000";
window.$company_sss_no = "80-0285671-7-000";
window.$company_hr = "GABRIELLE THERESE U. VERA CRUZ";
window.$company_ceo = "ROLANDO C. VERA CRUZ";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary:   globalStyles.colorPrimary,
          colorSuccess:   globalStyles.colorSuccess,
          colorWarning:   globalStyles.colorWarning,
          colorTextBase:  globalStyles.colorTextBase,

          fontFamily  :   globalStyles.fontRegular
      }}}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
