import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Table} from 'antd'
import { UsePostRequest } from '../../utils/api/ApiRequests'
import moment from 'moment';
import { getIsAdminLogin, getEmployeeId } from '../../utils/Common';

export default function WfaCredit () {
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  
  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: isAdminLogin?"":employeeId,
  });

  const tableColumns = [
    {
        title: 'Employee',
        dataIndex: 'employee_name',
        key: 'employee_name',
    },
    {
        title: 'Period Covered',
        dataIndex: 'period_covered',
        key: 'period_covered',
    },
    {
        title: 'WFA Credits',
        dataIndex: 'credits_left',
        key: 'credits_left',
    }
  ]

  const handleSearch = (e) => {
    setFilterConfig((prev) => ({...prev, term: e}));
  }

React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UsePostRequest("wfa_requests/get_wfa_credits", filterConfig);
    if (response.data) {
        const res = response.data.map((data) => {
            var info = data;
            info.period_covered = `${moment(data.date_hired).format('MMM DD, YYYY')} - ${moment(data.date_end).format('MMM DD, YYYY')}`
            return info;
        }); 
        setTableData(res)
    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar pageTitle="Employee WFA Credits" noButton setSearchQuery={handleSearch} isManager searchable>
        <Table className='mt-2' rowKey="id"
          columns={tableColumns} 
          dataSource={tableData} 
          loading={isFetching} />
    </Navbar>
  )
}