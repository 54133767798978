import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Modal, Table, Row, Col, DatePicker, Select, Input } from 'antd'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons'
import { usePostRequest } from '../../hooks/usePostRequest'
import { UsePostRequest } from '../../utils/api/ApiRequests'
import dayjs from 'dayjs'
import { GetAllEmployees } from '../../utils/api/DropdownFiltersApi'
import TextArea from 'antd/es/input/TextArea'
import { formatAmount, formatDate, removeUnderscoreToSentence, getIsAdminLogin, getEmployeeId } from '../../utils/Common'


const AddSubmitButton = ({ form, isLoading }) => {
    const [submittable, setSubmittable] = useState(false);
    const values = Form.useWatch([], form);
      React.useEffect(() => {
        form.validateFields({
          validateOnly: true,
        }).then(() => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
        );
      }, [values]);

      return (
        <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Submit</Button>
      );
  };

export default function Loan() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const employeeOptions = GetAllEmployees();
  const dateFormat = 'YYYY-MM-DD';
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  
  const {callPost, isLoading} = usePostRequest(()=>{
    setShowAddModal(false); 
    setShowEditModal(false);
    setShowViewModal(false);
    setFilterConfig((prev) => ({...prev, is_updated: !prev.is_updated}))});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  // const [initialValues, setInitialValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [searchTerm, setSearchTerm] = useState("");

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: isAdminLogin ? "" : employeeId,
  });

  const [isFetching, setIsFetching] = useState(false);
  
  const tableColumns = [
    {
        title: 'Employee',
        dataIndex: 'employee_name',
        key: 'employee_name',
    },
    {
        title: 'Loan Type',
        dataIndex: 'loan_type',
        key: 'loan_type',
        render: (loan_type) => (
          <div>{removeUnderscoreToSentence(loan_type)}</div>
        )
    },
    {
        title: 'Issue Date',
        dataIndex: 'issue_date',
        key: 'issue_date',
        render: (issue_date) => (
          <div>{formatDate(issue_date)}</div>
        )
    },
    {
        title: 'Loan Amount',
        dataIndex: 'loan_amount',
        key: 'loan_amount',
        align: 'right',
        render: (loan_amount) => (
          <div>{formatAmount(loan_amount)}</div>
        )
    },
    {
        title: 'Paid Amount',
        dataIndex: 'paid_amount',
        key: 'paid_amount',
        align: 'right',
        render: (paid_amount) => (
          <div>{formatAmount(paid_amount)}</div>
        )
    },
    {
        title: 'Remaining Balance',
        dataIndex: 'remaining_balance',
        key: 'remaining_balance',
        align: 'right',
        render: (remaining_balance) => (
          <div>{formatAmount(remaining_balance)}</div>
        )
    },
  ];

  const loanTypes = [
    {value: 'sss_salary_loan', label: 'SSS Salary Loan'},
    {value: 'sss_calamity_loan', label: 'SSS Calamity Loan'},
    {value: 'cooperative_loan', label: 'Cooperative Loan'},
    {value: 'laptop_loan', label: 'Laptop Loan'},
    {value: 'phone_loan', label: 'Phone Loan'},
    {value: 'company_loan', label: 'Company Loan'},
    {value: 'pagibig_loan', label: 'PagIBIG Loan'},
    {value: 'cash_advance', label: 'Cash Advance'},
    {value: 'calamity_loan', label: 'Calamity Loan'},
    {value: 'others', label: 'Others'}
];

const termTypes = [
    {value: 'per_payroll', label: 'Per Payroll'},
    {value: 'end_month', label: 'End Month'},
];

  const onFinish = (formInputs) => {
    formInputs.issue_date = formInputs.issue_date.format(dateFormat);
    callPost(
      "employee_loans/add", 
      formInputs,
      false
    );
  };

  const onEdit = (formInputs) => {
    formInputs.issue_date = formInputs.issue_date.format(dateFormat);
    formInputs.loan_id = clickedRowData.id;
    callPost(
      `employee_loans/update`, 
      formInputs,
      false
    );
  };

  const confirmDelete = (loan) => {
    modal.confirm({
      title: `Are you sure you want to delete loan ID "${loan.id}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleDelete(loan.id),
      okType: 'danger',
    });
  };


  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({...prev, term: searchTerm}));
    }
  }

  function handleDelete(passedId) {
    callPost("employee_loans/delete",  {loan_id: passedId}, false);
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData);
    const formattedValues = { ...rowData };
    formattedValues.issue_date = rowData.issue_date?dayjs(rowData.issue_date, dateFormat):'';
    form.setFieldsValue(formattedValues); 
    // setInitialValues(()=>{return{...rowData}});
    setShowViewModal(true);
  };

  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });

//   useEffect(() => {
//     const formattedValues = { ...initialValues };
//     formattedValues.date_from = initialValues.date_from?dayjs(initialValues.date_from, dateFormat):'';
//     formattedValues.date_to = initialValues.date_to?dayjs(initialValues.date_to, dateFormat):'';
//     form.setFieldsValue(formattedValues);     
// }, [initialValues]);

React.useEffect(() => {
  const searchTimeout = setTimeout(() => {
    setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
  }, 2000);

  return () => {
    clearTimeout(searchTimeout);
  };
}, [searchTerm]);

React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UsePostRequest("employee_loans/get", filterConfig);
    if (response.data) {
        setTableData(response.data);
    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar
      pageTitle="Loans"
      pageButtonTitle="Add Loan"
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      searchPlaceholder="employee"
      onClickButton={() => setShowAddModal(true)}
      isManager
      searchable
      //   withDateRangePicker
      //   onDateChange={handleDateFilter}
    >
      <Table
        className="mt-5"
        rowKey="id"
        onRow={(record) => ({
          ...rowProps(record),
          style: { cursor: "pointer" },
        })}
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching || isLoading}
      />

      <Modal
        title={`${showAddModal ? "Add" : "Edit"} Loan`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          form.resetFields();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={employeeOptions.slice(1)} disabled={showEditModal}/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="issue_date"
                label="Issue Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker  placeholder="YYYY-MM-DD"/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="loan_type"
                label="Loan Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={loanTypes} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="loan_amount"
                label="Loan Amount"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="term"
                label="Term"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={termTypes} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="installment"
                label="Installment"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="reason"
                label="Reason"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Loan"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          form.resetFields();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={employeeOptions.slice(1)} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="issue_date"
                label="Issue Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="loan_type"
                label="Loan Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={loanTypes} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="loan_amount"
                label="Loan Amount"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="number" disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="term"
                label="Term"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={termTypes} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="installment"
                label="Installment"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="number" disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="reason"
                label="Reason"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea disabled/>
              </Form.Item>
            </Col>
          </Row>
        
          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowViewModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
              >
                Edit
              </Button>
              <Button
                type="text"
                onClick={() => {
                  navigate("view", {state: clickedRowData})
                }}
                icon={<EyeOutlined />}
              >
                View
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {contextHolder}
    </Navbar>
  );
}