import React, {useRef} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Button } from "antd";
import Title from "antd/es/typography/Title";
import { Container } from "react-bootstrap";
import Navbar from "../../components/Navbar/Navbar";
import NewLogo from "../../assets/images/logo.png";
import { capitalizeEachWord, formatAmount, formatDate } from "../../utils/Common";
import ReactToPrint from "react-to-print";
import {PrinterOutlined} from "@ant-design/icons";


export default function ViewEmployeePhicContribution () {
    const componentRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const passedData = location.state;
    const dateToday = new Date();


    return (
        <Navbar>
            <Container className='print-report-page p-3'>
                <div  ref={componentRef}>
                <Row className='d-flex justify-content-end'><Title level={3}>PHILHEALTH CONTRIBUTION</Title></Row>
                <Row className='d-flex justify-content-end'>Date Printed: {formatDate(dateToday)}
                </Row>
                <Row className='p-1 ms-2'><img src = {NewLogo} alt={"logo"} className="payslip-logo"/></Row>
                <Row className='bold p-1'>{window.$company_name}</Row>
                <Row className='p-1'>{window.$company_address}</Row>
                <Row className='p-1 mt-3'>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='primary-bg white-text '>
                                <th colSpan={2} className="">Contribution Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='bold w-50'>Employee Name</td>
                                <td>{passedData.full_name ? capitalizeEachWord(passedData.full_name) : ''}</td>
                            </tr>
                            <tr>
                                <td className='bold w-50'>Philhealth No.</td>
                                <td>{passedData.philhealth_no ? capitalizeEachWord(passedData.philhealth_no) : ''}</td>
                            </tr>
                            <tr>
                                <td className='bold w-50'>Total No. of Months</td>
                                <td>{passedData.philhealth_contributions?passedData.philhealth_contributions.length:''}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                
                <Row className='mt-4'>
                <Row className='py-2'><Title level={5}>CONTRIBUTION SUMMARY</Title></Row>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='primary-bg white-text'>
                                <th className="text-end">Total Employee Share</th>
                                <th className="text-end">Total Employer Share</th>
                                <th className="text-end">Total Amount of Contribution</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='text-end'>Php {formatAmount(passedData.total_employee_contribution)}</td>
                                <td className='text-end'>Php {formatAmount(passedData.total_employer_contribution)}</td>
                                <td className='text-end'>Php {formatAmount(passedData.total_amount_contributed)}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>

                <Row className='mt-3'>
                <Row className='py-2'><Title level={5}>CONTRIBUTION HISTORY</Title></Row>
                    <table className='table table-bordered cont-history-wrapper'>
                        <thead>
                            <tr className='primary-bg white-text'>
                                <th>Year</th>
                                <th>Month</th>
                                <th className="text-end">Employee Share</th>
                                <th className="text-end">Employer Share</th>
                                <th className="text-end">Total Contribution</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (passedData.philhealth_contributions && passedData.philhealth_contributions.length) ? (
                                    passedData.philhealth_contributions.map((data) => {
                                    return (
                                        <tr>
                                            <td>{data.year}</td>
                                            <td>{data.month}</td>
                                            <td className="text-end">Php {formatAmount(data.employee_contribution)}</td>
                                            <td className="text-end">Php {formatAmount(data.employer_contribution)}</td>
                                            <td className="text-end">Php {formatAmount(data.total_contribution)}</td>
                                        </tr>
                                    )
                                    })
                                ) : ('')
                            }
                        </tbody>
                    </table>
                </Row>

                <Row className="d-flex justify-content-end my-4 noPrint">
                    <Row className="">
                        <Button className="me-2" onClick={() => navigate(-1)}>
                            Back
                        </Button>
                        <ReactToPrint
                            trigger={() => (
                                <Button type="primary"><PrinterOutlined/>Print</Button>
                            )}
                            content={() => componentRef.current}
                            documentTitle={"PhilHealth"}
                        />  
                    </Row>
                </Row>
                </div>
            </Container>
        </Navbar>
    )
}