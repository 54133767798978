export const globalStyles = {
    /* brand colors */
    colorPrimary: "#458381",
    colorSuccess: "#9de038",
    colorWarning: "#fa9714",
    colorTextBase: "#2f2f2f",

    /* fonts */
    fontLight: "Rubik-Light",
    fontMedium: "Rubik-Medium",
    fontRegular: "Rubik-Regular",
    fontBold: "Rubik-Bold",
    fontSemibold: "Rubik-SemiBold",
}