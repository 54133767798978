import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../components/Navbar/Navbar'
import { Button, Form, Input, Modal, Table, Row, Select, DatePicker, Typography, Checkbox, Space } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { usePostRequest } from '../../hooks/usePostRequest'
import { UsePostRequest, UseGetRequest } from '../../utils/api/ApiRequests'
import { formatAmount, formatShortDate, capitalizeEachWord, getIsAdminLogin, getEmployeeId, minsToHours } from '../../utils/Common'
import { GetAllSalaryTypes, GetAllBranches, GetAllBusinessUnits } from '../../utils/api/DropdownFiltersApi'
import dayjs from 'dayjs'
import html2pdf from 'html2pdf.js'
// import { CSVLink } from 'react-csv'
import ReactToPrint from 'react-to-print'
import PayslipPdf from './PayslipPdf'
import moment from 'moment';

const Title = Typography;

const AddSubmitButton = ({ form, isLoading }) => {
  const [submittable, setSubmittable] = useState(false);
  
  // Watch all values
  const values = Form.useWatch([], form);
    React.useEffect(() => {
      form.validateFields({
        validateOnly: true,
      }).then(() => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
      );
    }, [values]);
    return (
    <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Proceed</Button>
    );
};

export default function Payroll() {
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const currentDate = new Date();
  const navigate = useNavigate();
  const componentRef = useRef();
  const bulkPrintRefs = useRef([]);
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const {callPost, isLoading} = usePostRequest(()=>{
    setShowAddModal(false); 
    setShowViewModal(false);
    setFilterConfig((prev) => ({...prev, is_updated: !prev.is_updated}))});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [pdfGenerationInProgress, setPdfGenerationInProgress] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [printTableData, setPrintTableData] = useState([]);
  // const [totals, setTotals] = useState({});
  const [modal, contextHolder] = Modal.useModal();
  const salaryTypeOptions = GetAllSalaryTypes();
  const [bankExcelUrl, setBankExcelUrl] = useState();
  const [payrollExcelUrl, setPayrollExcelUrl] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteMultiple, setShowDeleteMultiple] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const branchOptions = GetAllBranches();
  const businessUnitOptions = GetAllBusinessUnits();
  const [generatePdf, setGeneratePdf] = useState(false);


  function getFirstDayOfMonth() {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      return moment(date).format('YYYY-MM-DD');
  }

  function get15thDayOfMonth() {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 15);
      return moment(date).format('YYYY-MM-DD');
  }

  function get16thDayOfMonth() {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 16);
      return moment(date).format('YYYY-MM-DD');
  }

  function getLastDayOfMonth() {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0);
      return moment(date).format('YYYY-MM-DD');
  }

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: isAdminLogin ? '' : employeeId, // Only admin can view all employee payrolls
    date_from: currentDate.getDate() <= 15 ? getFirstDayOfMonth() : get16thDayOfMonth(),
    date_to: currentDate.getDate() <= 15 ? get15thDayOfMonth() : getLastDayOfMonth(),
  });

  const [isFetching, setIsFetching] = useState(false);
  
  const tableColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      render: (id) => (
          <div>{showDeleteMultiple && <Checkbox onChange={(e) => e.target.checked?setItemsToDelete([...itemsToDelete, id]):setItemsToDelete(itemsToDelete.filter(item => item!==id))}/>}</div>
      )
    },
    {
        title: 'Date From',
        dataIndex: 'date_from',
        key: 'date_from',
        render: (data, record) => (
            <div onClick={()=>handleRowClick(record)} className='pointer-cursor'>{formatShortDate(data)}</div>
        )
    },
    {
        title: 'Date To',
        dataIndex: 'date_to',
        key: 'date_to',
        render: (data, record) => (
            <div onClick={()=>handleRowClick(record)} className='pointer-cursor'>{formatShortDate(data)}</div>
        )
    },
    {
        title: 'Employee',
        dataIndex: 'employee_name',
        key: 'employee_name',
        render: (employee_name, record) => (
          <div onClick={()=>handleRowClick(record)} className='pointer-cursor'>{capitalizeEachWord(employee_name)}</div>
        )
    },
    {
        title: 'Total Earnings',
        dataIndex: 'total_earnings',
        key: 'total_earnings',
        align: 'right',
        render: (total_earnings, record) => (
            <div onClick={()=>handleRowClick(record)} className='pointer-cursor'>{formatAmount(total_earnings)}</div>
        )
    },
    {
        title: 'Total Deductions',
        dataIndex: 'total_deduction',
        key: 'total_deduction',
        align: 'right',
        render: (total_deduction, record) => (
            <div onClick={()=>handleRowClick(record)} className='pointer-cursor'>{formatAmount(total_deduction)}</div>
        )
    },
    {
        title: 'Total Payout',
        dataIndex: 'total_payout',
        key: 'total_payout',
        align: 'right',
        render: (total_payout, record) => (
            <div onClick={()=>handleRowClick(record)} className='pointer-cursor'>{formatAmount(total_payout)}</div>
        )
    },
  ];

  const rowClassName = (record, index) => {
    const isLastRow = index === printTableData.length - 1;
    return isLastRow ? 'bold-cell' : '';
  };

  const printTableColumns = [
    {
      title: "Date From",
      dataIndex: "date_from",
      key: "date_from",
      render: (date_from, record) => <div>{date_from&&date_from!==""&&formatShortDate(date_from)}</div>,
    },
    {
      title: "Date To",
      dataIndex: "date_to",
      key: "date_to",
      render: (date_to, record) => <div>{date_to&&date_to!==""&&formatShortDate(date_to)}</div>,
    },
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Basic Pay",
      dataIndex: "basic_pay",
      key: "basic_pay",
      align: "right",
      render: (basic_pay, record) => <div>{formatAmount(basic_pay)}</div>,
    },
    {
      title: "Holiday Pay",
      dataIndex: "holiday_pay",
      key: "holiday_pay",
      align: "right",
      render: (holiday_pay, record) => <div>{formatAmount(holiday_pay)}</div>,
    },
    {
      title: "Regular OT (hrs)",
      dataIndex: "regular_ot_hrs",
      key: "regular_ot_hrs",
      render: (regular_ot_hrs, record) => (
        <div>{formatAmount(regular_ot_hrs)}</div>
      ),
    },
    {
      title: "Regular OT",
      dataIndex: "regular_ot",
      key: "regular_ot",
      align: "right",
      render: (regular_ot, record) => <div>{formatAmount(regular_ot)}</div>,
    },
    {
      title: "Restday OT (hrs)",
      dataIndex: "restday_ot_hrs",
      key: "restday_ot_hrs",
      render: (restday_ot_hrs, record) => (
        <div>{formatAmount(restday_ot_hrs)}</div>
      ),
    },
    {
      title: "Restday OT",
      dataIndex: "restday_ot",
      key: "restday_ot",
      align: "right",
      render: (restday_ot, record) => <div>{formatAmount(restday_ot)}</div>,
    },
    {
      title: "Total Earnings",
      dataIndex: "total_earnings",
      key: "total_earnings",
      align: "right",
      render: (total_earnings, record) => (
        <div>{formatAmount(total_earnings)}</div>
      ),
    },
    {
      title: "SSS",
      dataIndex: "sss_contribution",
      key: "sss_contribution",
      align: "right",
      render: (sss_contribution, record) => (
        <div>{formatAmount(sss_contribution)}</div>
      ),
    },
    {
      title: "PhilHealth",
      dataIndex: "philhealth_contribution",
      key: "philhealth_contribution",
      align: "right",
      render: (philhealth_contribution, record) => (
        <div>{formatAmount(philhealth_contribution)}</div>
      ),
    },
    {
      title: "PagIbig",
      dataIndex: "pagibig_contribution",
      key: "pagibig_contribution",
      align: "right",
      render: (pagibig_contribution, record) => (
        <div>{formatAmount(pagibig_contribution)}</div>
      ),
    },
    {
      title: "Late",
      dataIndex: "late",
      key: "late",
      align: "right",
      render: (late, record) => <div>{formatAmount(late)}</div>,
    },
    {
      title: "Undertime",
      dataIndex: "undertime_amount",
      key: "undertime_amount",
      align: "right",
      render: (undertime_amount, record) => (
        <div>{formatAmount(undertime_amount)}</div>
      ),
    },
    {
      title: "Total Deductions",
      dataIndex: "total_deduction",
      key: "total_deduction",
      align: "right",
      render: (total_deduction, record) => (
        <div>{formatAmount(total_deduction)}</div>
      ),
    },
    {
      title: "Total Payout",
      dataIndex: "total_payout",
      key: "total_payout",
      align: "right",
      render: (total_payout, record) => <div>{formatAmount(total_payout)}</div>,
    },
  ];

//   const reportHeaders = [
//     { label: 'DTR ID', key: 'employee_id' },
//     { label: 'Employee Name', key: 'employee_name' },
//     { label: 'Total Working Days', key: 'work_days' },
//     { label: 'Late (hrs)', key: 'late_hrs' },
//     { label: 'Late', key: 'late'},
//     { label: 'Undertime (hrs)', key: 'undertime_hrs' },
//     { label: 'Undertime', key: 'undertime_amount'},
//     { label: 'Holiday Pay', key: 'holiday_pay'},
//     { label: 'Basic Pay', key: 'basic_pay'},
//     { label: 'SSS', key: 'sss_contribution' },
//     { label: 'HDMF', key: 'pagibig_contribution' },
//     { label: 'PHIC', key: 'philhealth_contribution' },
//     { label: 'SSS Salary Loan', key: 'sss_salary_loan' },
//     { label: 'SSS Calamity Loan', key: 'sss_calamity_loan' },
//     { label: 'Pag-Ibig Loan', key: 'pagibig_loan' },
//     { label: 'Calamity Loan', key: 'calamity_loan' },
//     { label: 'HMO', key: 'hmo_maxicare' },
//     { label: 'COOP Contribution', key: 'coop' },
//     { label: 'Company Loan', key: 'company_loan' },
//     { label: 'Withholding Tax', key: 'wth_tax' },
//     { label: 'AXA', key: 'axa_amount' },
//     { label: 'Phone Loan', key: 'phone_loan' },
//     { label: 'Laptop Loan', key: 'laptop_loan' },
//     { label: 'Cash Advance', key: 'cash_advance' },
//     { label: 'Others', key: 'others' },
//     { label: 'Total (1)', key: 'total_one' },
//     { label: 'Daily Allowance', key: 'daily_allowance' },
//     { label: 'Comm Allowance', key: 'comm_allowance' },
//     { label: 'Transpo Allowance', key: 'transpo_allowance' },
//     { label: 'Food Allowance', key: 'food_allowance' },
//     { label: 'HMO Allowance', key: 'hmo_allowance' },
//     { label: 'Tech Allowance', key: 'tech_allowance' },
//     { label: 'OPS Allowance', key: 'ops_allowance' },
//     { label: 'Special Allowance', key: 'special_allowance' },
//     { label: 'Grocery Allowance', key: 'grocery_allowance' },
//     { label: 'Entertainment Allowance', key: 'entertainment_allowance' },
//     { label: 'Medicine Allowance', key: 'medicine_allowance' },
//     { label: 'Uniform Allowance', key: 'uniform_allowance' },
//     { label: 'Training Allowance (F2F)', key: 'training_allowance_f2f' },
//     { label: 'Training Allowance (Virtual)', key: 'training_allowance_virtual' },
//     { label: 'Total (2)', key: 'total_two' },
//     { label: 'Regular OT (hrs)', key: 'regular_ot_hrs' },
//     { label: 'Regular OT', key: 'regular_ot' },
//     { label: 'Rest Day OT (hrs)', key: 'restday_ot_hrs'},
//     { label: 'Rest Day OT', key: 'restday_ot' },
//     { label: 'Special Holiday Pay', key: 'special_holiday_pay' },
//     { label: 'Night Differential', key: 'night_differential' },
//     { label: 'Salary Adj', key: 'salary_adj' },
//     { label: 'Monthly', key: 'monthly' },
//     { label: 'Semi Monthly', key: 'semi_monthly' },
//     { label: 'Total (3)', key: 'total_three' },
//     { label: 'Total Net Pay', key: 'total_net_pay' }
// ];

  const actionDropdownOptions = [
    {value: 'generate', label: 'Add Payroll', action: () => setShowAddModal(true)},
    {value: 'download', label: 'Download Payslips', action: () => {setGeneratePdf(true); handleDownloadPDF()}},
    {value: 'ub', label: <a href={bankExcelUrl} className="pointer no-style-btn">Download Union Bank</a>},
    {value: 'export', label: <a href={payrollExcelUrl} className="pointer no-style-btn">Export Payroll Summary</a>},
    // {value: 'export', label: <CSVLink className='no-style-btn' data={tableData} headers={reportHeaders} filename={"Payroll Report Summary.csv"}>Export File</CSVLink>},
    {value: 'print', label: <ReactToPrint
        trigger={() => (
            <Button className="no-style-btn">Print Report</Button>
        )}
        content={() => componentRef.current}
        documentTitle={"Payslip"}
    />},
    {value: 'delete', label: 'Delete Multiple', action: () => setShowDeleteMultiple(true)},
  ]

  const confirmDelete = (payroll) => {
    modal.confirm({
      title: `Are you sure you want to delete payroll ID "${payroll.id}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleDelete(payroll.id),
      okType: 'danger',
    });
  };

  
  function handleMultipleDelete () {
    itemsToDelete.forEach((item) => {
      handleDelete(item);
    })
  }

  const confirmMultipleDelete = (e) => {
    modal.confirm({
      title: `Are you sure you want to delete the selected payrolls?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleMultipleDelete(),
      okType: 'danger',
    });
  };

  const onFinish = (formInputs) => {
    setShowAddModal(false);
    navigate("/payrolls/generate", {state: formInputs});
  }

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({...prev, term: searchTerm}));
    }
  }

  function handleDelete(passedId) {
    callPost("payrolls/delete/" + passedId,  {}, false);
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData);
    const finalValues = {...rowData};
    finalValues.date_from = rowData.date_from?dayjs(rowData.date_from, 'YYYY-MM-DD'):'';
    finalValues.date_to = rowData.date_to?dayjs(rowData.date_to, 'YYYY-MM-DD'):'';
    editForm.setFieldsValue(finalValues); 
    setShowViewModal(true);
  };

  // const rowProps = (record) => ({
  //   onClick: () => handleRowClick(record),
  // });

  const handleDateFilter = (e) => {
    if (e) {
        setFilterConfig((prev) => ({...prev,
            date_from: e[0].format('YYYY-MM-DD'),
            date_to: e[1].format('YYYY-MM-DD'),
        }));
    }
  }

  const handleDownloadPDF = async () => {
    setPdfGenerationInProgress(true);

    setTimeout(async () => {
      for (let i = 0; i < tableData.length; i++) {
          const payroll = tableData[i];
          const element = bulkPrintRefs.current[i];
          element.classList.remove('printOnly');
          const options = {
              filename: `${payroll.employee_name}_payslip.pdf`,
              pageOrientation: 'landscape',
          };

          await html2pdf().set(options).from(element).save();
          await new Promise((resolve) => setTimeout(resolve, 500));
          element.classList.add('printOnly');
      }
      setPdfGenerationInProgress(false);
      setGeneratePdf(false);
    }, 3000)
  };

  const handleBulkPrintRef = (ref) => {
    bulkPrintRefs.current.push(ref);
  };

  function renderPdfGenerator () {
    if (generatePdf) {
      return (
        <Row className="always-hidden">
          {tableData.map((payroll, index) => (
            <PayslipPdf
              key={index}
              setBulkPrintRef={handleBulkPrintRef}
              payroll_id={payroll.id}
              hide="printOnly"
            />
          ))}
        </Row>
    )
    }
  }

  React.useEffect(() => {
    if (generatePdf) {
      renderPdfGenerator();
    }
  }, [generatePdf])

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }, 2000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchTerm]);

 
  React.useEffect(() => {
    setItemsToDelete([]);
    setShowDeleteMultiple(false);

    async function fetchTableData  () {
      var tempTotals = {
        basic_pay: 0,
        holiday_pay: 0,
        regular_ot_hrs: 0,
        regular_ot: 0,
        restday_ot_hrs: 0,
        restday_ot: 0,
        total_earnings: 0,
        sss_contribution: 0,
        philhealth_contribution: 0,
        pagibig_contribution: 0,
        late: 0,
        undertime_amount: 0,
        total_deduction: 0,
        total_payout: 0,
      }

      setIsFetching(true);
      setTableData([]);
      setPrintTableData([]);
      const response = await UsePostRequest("payrolls/get", filterConfig);
      if (response.data) {

        const res = response.data.map((row) => {
          // Get the totals
          tempTotals.basic_pay += Number(row.basic_pay);
          tempTotals.holiday_pay += Number(row.holiday_pay);
          tempTotals.regular_ot += Number(row.regular_ot);
          tempTotals.regular_ot_hrs += Number(minsToHours(row.regular_ot_mins));
          tempTotals.restday_ot += Number(row.restday_ot);
          tempTotals.restday_ot_hrs += Number(minsToHours(row.restday_ot_mins));
          tempTotals.total_earnings += Number(row.total_earnings);
          tempTotals.sss_contribution += Number(row.sss_contribution);
          tempTotals.philhealth_contribution += Number(row.philhealth_contribution);
          tempTotals.pagibig_contribution += Number(row.pagibig_contribution);
          tempTotals.late += Number(row.late);
          tempTotals.undertime_amount += Number(row.undertime_amount);
          tempTotals.total_deduction += Number(row.total_deduction);
          tempTotals.total_payout += Number(row.total_payout);

          var info = row;
          info.regular_ot_hrs = minsToHours(row.regular_ot_mins);
          info.restday_ot_hrs = minsToHours(row.restday_ot_mins);
          return info;
        })

        // Insert the totals as the last row in the print table data
        const tempTotalRow = {
          date_from: "",
          date_to: '',
          employee_name :'TOTAL',
          ...tempTotals
        }

        setTableData(res);
        setPrintTableData([...res, tempTotalRow]);
      }
      setIsFetching(false);
    }

    async function fetchUnionBankData () {
      setBankExcelUrl();
      const response = await UseGetRequest("payrolls/create_union_bank_excel", filterConfig);
      if (response.data) {
        setBankExcelUrl(response.data.url);
      }
    }

    async function fetchExcelExportData () {
      setPayrollExcelUrl();
      const response = await UseGetRequest("payrolls/create_payroll_excel", filterConfig);
      if (response.data) {
        setPayrollExcelUrl(response.data.url);
      }
    }

      fetchTableData ();
      fetchUnionBankData();
      fetchExcelExportData();
  }, [filterConfig]);

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }, 2000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchTerm]);


  return (
    <Navbar
      pageTitle="Payrolls"
      noButton
      actionDropdownButton
      actionDropdownOptions={actionDropdownOptions}
      setSearchQuery={(e) => setSearchTerm(e)}
      onSearch={handleSearch}
      onClickButton={() => setShowAddModal(true)}
      isManager
      searchable
      searchPlaceholder="employee"
      withDateRangePicker
      onDateChange={handleDateFilter}
    >
      <Row className="mt-5 d-flex justify-content-between">
        <Space className="">
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Branch"
            optionFilterProp="items"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={branchOptions}
            onChange={(e) => setFilterConfig((prev) => ({...prev, branch_id: e }))}
          />
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Business Unit"
            optionFilterProp="items"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={businessUnitOptions}
            onChange={(e) =>
              setFilterConfig((prev) => ({...prev, business_unit_id: e }))
            }
          />
        </Space>
        {
        showDeleteMultiple &&
        <Space className="">
          <Button
            danger
            onClick={(e) => confirmMultipleDelete(e)}
            className="me-2"
            disabled={!itemsToDelete || itemsToDelete.length === 0}
          >
            Delete Selected
          </Button>
          <Button
            onClick={() => {
              setShowDeleteMultiple(false);
              setItemsToDelete([]);
            }}
          >
            Cancel
          </Button>
        </Space>
        }
      </Row>

      <Table
        rowKey="id"
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching || isLoading}
      />

      <Modal
        title="Review Payroll"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          editForm.resetFields();
        }}
      >
        <Form className="mt-3 mx-3" form={editForm} name="editForm" layout="">
          <Form.Item name="employee_name" label="Name">
            <Input disabled />
          </Form.Item>
          <Form.Item name="date_from" label="Date From">
            <DatePicker disabled />
          </Form.Item>
          <Form.Item name="date_to" label="Date To">
            <DatePicker disabled />
          </Form.Item>
          <Form.Item name="total_payout" label="Total Payout">
            <Input disabled />
          </Form.Item>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowViewModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  window.open(
                    "/payrolls/payslip/" + clickedRowData.id,
                    "_blank"
                  );
                  // navigate("payslip", {state: {passed_id: clickedRowData.id}});
                }}
              >
                View
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Generate Payroll"
        centereds
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        footer={false}
      >
        <Form
          form={form}
          className="mt-3 mx-3"
          name="validateOnly"
          layout=""
          onFinish={onFinish}
        >
          <Form.Item name="salary_type" label="Salary Type" initialValue={""}>
            <Select options={salaryTypeOptions.slice(1)} />
          </Form.Item>
          <Form.Item
            name="date_from"
            label="Date From"
            rules={[{ required: true }]}
          >
            <DatePicker placeholder="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item
            name="date_to"
            label="Date To"
            rules={[{ required: true }]}
          >
            <DatePicker placeholder="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item>
            <Row className="d-flex justify-content-between">
              <Button
                className="me-2"
                danger
                onClick={() => setShowAddModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <AddSubmitButton form={form} isLoading={isLoading} />
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={pdfGenerationInProgress}
        footer={null}
        onCancel={() => setPdfGenerationInProgress(false)}
      >
        DOWNLOADING PDF
      </Modal>

      <div className="printOnly">
        <div className="print-report-page" ref={componentRef}>
          <Title level={5} className="px-4">
            PAYROLL
          </Title>
          <Table
            className="px-2 mt-3"
            rowKey="id"
            columns={printTableColumns}
            dataSource={printTableData}
            pagination={false}
            rowClassName={rowClassName}
          />

        </div>
      </div>

      <div>{renderPdfGenerator()}</div>

      {contextHolder}
    </Navbar>
  );
}