import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Input, Modal, Table, Row, Col, TimePicker, DatePicker, Select, Checkbox, Space } from 'antd'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { CSVLink } from 'react-csv';
import { usePostRequest } from '../../hooks/usePostRequest'
import { UsePostRequest } from '../../utils/api/ApiRequests'
import moment from 'moment'
import dayjs from 'dayjs'
import { capitalizeEachWord, extractDate, getIsAdminLogin, getEmployeeId, minsToHours, formatShortDate } from '../../utils/Common'
import { GetAllEmployees, GetAllBranches, GetAllBusinessUnits } from '../../utils/api/DropdownFiltersApi'


const AddSubmitButton = ({ form, isLoading }) => {
    const [submittable, setSubmittable] = useState(false);
    const values = Form.useWatch([], form);
      React.useEffect(() => {
        form.validateFields({
          validateOnly: true,
        }).then(() => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
        );
      }, [values]);

      return (
        <Button type="primary" htmlType="submit" disabled={!submittable} loading={isLoading}>Submit</Button>
      );
  };

export default function Timesheet() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const employeeOptions = GetAllEmployees();
  const branchOptions = GetAllBranches();
  const businessUnitOptions = GetAllBusinessUnits();
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const dateFormat = 'YYYY-MM-DD';
  const {callPost, isLoading} = usePostRequest(()=>{
    setShowAddModal(false); 
    setShowEditModal(false);
    setShowViewModal(false);
    resetFields();
    setFilterConfig((prev) => ({...prev, is_updated: !prev.is_updated}))});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  // const [initialValues, setInitialValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modal, contextHolder] = Modal.useModal();
  const [showDeleteMultiple, setShowDeleteMultiple] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState([]);

  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: isAdminLogin ? '' : employeeId,
    date_from: new Date(),
    date_to: new Date(),
    branch_id: '',
    business_unit_id: '',
  });

  const [isFetching, setIsFetching] = useState(false);
  
  const tableColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      render: (id) => (
          <div>{showDeleteMultiple && <Checkbox onChange={(e) => e.target.checked?setItemsToDelete([...itemsToDelete, id]):setItemsToDelete(itemsToDelete.filter(item => item!==id))}/>}</div>
      )
    },
    {
        title: 'Employee',
        dataIndex: 'employee_name',
        key: 'employee_name',
        render: (data, record) => (
          <div className='pointer-cursor' onClick={() => handleRowClick(record)}>{capitalizeEachWord(data)}</div>
      )
    },
    {
        title: 'DTR ID',
        dataIndex: 'dtr_id',
        key: 'dtr_id',
        render: (data, record) => (
          <div className='pointer-cursor' onClick={() => handleRowClick(record)}>{data}</div>
      )
    },
    {
        title: 'Work Date',
        dataIndex: 'work_date',
        key: 'work_date',
        render: (data, record) => (
          <div className='pointer-cursor' onClick={() => handleRowClick(record)}>{moment(data).format('MMM DD, YYYY')}</div>
      )
    },
    {
        title: 'Time In',
        dataIndex: 'time_in',
        key: 'time_in',
        render: (data, record) => (
          <div className='pointer-cursor' onClick={() => handleRowClick(record)}>{moment(data, 'hh:mm:ss').format('hh:mm A')}</div>
      )
    },
    {
        title: 'Time Out',
        dataIndex: 'time_out',
        key: 'time_out',
        render: (data, record) => (
            <div className='pointer-cursor' onClick={() => handleRowClick(record)}>{moment(data, 'hh:mm:ss').format('hh:mm A')}</div>
        )
    },
    {
        title: 'Late (hrs)',
        dataIndex: 'late_hrs',
        key: 'late_hrs',
        render: (data, record) => (
          <div className='pointer-cursor' onClick={() => handleRowClick(record)}>{data}</div>
      )
    },
    {
        title: 'Undertime (hrs)',
        dataIndex: 'undertime_hrs',
        key: 'undertime_hrs',
        render: (data, record) => (
          <div className='pointer-cursor' onClick={() => handleRowClick(record)}>{data}</div>
      )
    },
    {
        title: 'Reg OT (hrs)',
        dataIndex: 'regular_ot_hrs',
        key: 'regular_ot_hrs',
        render: (data, record) => (
          <div className='pointer-cursor' onClick={() => handleRowClick(record)}>{data}</div>
      )
    },
    {
      title: 'Restday OT (hrs)',
      dataIndex: 'restday_ot_hrs',
      key: 'restday_ot_hrs',
      render: (data, record) => (
        <div className='pointer-cursor' onClick={() => handleRowClick(record)}>{data}</div>
    )
    },
    // {
    //     title: 'Actions',
    //     width: '10%',
    //     render: (_, record) => (
    //       <Space size="middle">
    //          {/* <Button type="text" onClick={()=>{setInitialValues(()=>{return{...record}}); setAddCategoryModal(true);}} icon={<EditOutlined/>}>Edit</Button> */}
    //          <Button type="text" onClick={()=>{setInitialValues(()=>{return{...record}}); setEditFileList(()=>{return[{url: record.file_url}]}); setEditCategoryModal(true)}} icon={<EditOutlined/>}>Edit</Button>
    //          <Button type="text" danger onClick={()=>confirm(record)} icon={<DeleteOutlined/>}>Delete</Button>
    //       </Space>
    //     ),
    // },
  ];

  const reportHeaders = [
    {label: 'Employee', key: 'employee_name'},
    {label: 'DTR ID', key: 'dtr_id'},
    {label: 'Date', key: 'formatted_work_date'},
    {label: 'Time In', key: 'time_in'},
    {label: 'Time Out', key: 'time_out'},
    {label: 'Late (hrs)', key: 'late_hrs'},
    {label: 'Undertime (hrs)', key: 'undertime_hrs'},
    {label: 'Reg OT (hrs)', key: 'regular_ot_hrs'},
    {label: 'Restday OT (hrs)', key: 'restday_ot_hrs'},
  ]

  const onFinish = (formInputs) => {
    formInputs.time_in = formInputs.time_in.format('HH:mm:ss');
    formInputs.time_out = formInputs.time_out.format('HH:mm:ss');
    formInputs.work_date = extractDate(formInputs.work_date);
    callPost(
      "timesheets/add", 
      formInputs,
      false
    );
  };

  const onEdit = (formInputs) => {
    formInputs.time_in = formInputs.time_in.format('HH:mm:ss');
    formInputs.time_out = formInputs.time_out.format('HH:mm:ss');
    formInputs.timesheet_id = clickedRowData.id;
    formInputs.work_date = extractDate(formInputs.work_date);
    callPost(
      `timesheets/update`, 
      formInputs,
      false
    );
  };

  const confirmDelete = (attendance) => {
    modal.confirm({
      title: `Are you sure you want to delete attendance ID "${attendance.id}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleDelete(attendance.id),
      okType: 'danger',
    });
  };

  
  function handleMultipleDelete () {
    itemsToDelete.forEach((item) => {
      handleDelete(item);
    })
  }

  const confirmMultipleDelete = (e) => {
    modal.confirm({
      title: `Are you sure you want to delete the selected timesheets?`,
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this action.',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: ()=>handleMultipleDelete(),
      okType: 'danger',
    });
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }
  }

  function handleDelete(passedId) {
    callPost("timesheets/delete",  {timesheet_id: passedId}, false);
  }

  const handleRowClick = (rowData) => {
    setClickedRowData(rowData);
    const formattedValues = {...rowData};
    formattedValues.work_date = rowData.work_date?dayjs(rowData.work_date, dateFormat):'';
    formattedValues.time_in = rowData.time_in?moment(rowData.time_in,"hh:mm A"):'';
    formattedValues.time_out = rowData.time_out?moment(rowData.time_out,"hh:mm A"):''; 
    form.setFieldsValue(formattedValues);
    setShowViewModal(true);
  };

  // const rowProps = (record) => ({
  //   onClick: () => handleRowClick(record),
  // });

  const handleDateFilter = (e) => {
    if (e) {
        setFilterConfig((prev) => ({...prev,
            date_from: e[0].format(dateFormat),
            date_to: e[1].format(dateFormat),
        }));
    }
  }

  const handleFormChange = (changedValues, allValues) => {
    if (allValues.employee_id) {
        const empRow = employeeOptions.filter((emp) => emp.id === allValues.employee_id)
        form.setFieldValue('dtr_id', empRow[0].dtr_id);
    }
  };

  const actionDropdownOptions = [
    {value: 'generate', label: 'Add Timesheet', action: () => setShowAddModal(true)},
    {value: 'export', label: <CSVLink className='no-style-btn' data={tableData} headers={reportHeaders} filename={"Timesheet.csv"}>Export File</CSVLink>},
    {value: 'upload', label: 'Upload Timesheet', action: () => navigate("upload", {state: "timesheet"})},
    {value: 'biometrics', label: 'Upload Biometrics', action: () => navigate("upload", {state: "biometrics"})},
    {value: 'delete', label: 'Delete Multiple', action: () => setShowDeleteMultiple(true)},
  ]

  function resetFields () {
    form.resetFields();
  }

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setFilterConfig((prev) => ({ ...prev, term: searchTerm }));
    }, 2000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchTerm]);

React.useEffect(() => {
  setItemsToDelete([]);
  setShowDeleteMultiple(false);
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UsePostRequest("timesheets/get", filterConfig);
    if (response.data) {
      const res = response.data.map((data) => {
        var info = data;
        info.formatted_work_date = formatShortDate(data.work_date);
        info.late_hrs = minsToHours(data.late);
        info.undertime_hrs = minsToHours(data.undertime_mins);
        info.regular_ot_hrs = minsToHours(data.regular_ot_mins);
        info.restday_ot_hrs = minsToHours(data.restday_ot_mins);
        return info;
      });
      setTableData(res);
    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar
      pageTitle="Timesheets"
      // pageButtonTitle="Add Timesheet"
      // onClickButton={() => {
      //   setShowAddModal(true);
      // }}
      noButton actionDropdownButton actionDropdownOptions={actionDropdownOptions}
      onSearch={handleSearch}
      setSearchQuery={(e) => setSearchTerm(e)}
      searchPlaceholder="employee"
      isManager
      searchable
      withDateRangePicker
      onDateChange={handleDateFilter}
    >
      <Row className="mt-5 d-flex justify-content-between">
        <Space className="">
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Branch"
            optionFilterProp="items"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={branchOptions}
            onChange={(e) => setFilterConfig((prev) => ({...prev, branch_id: e }))}
          />
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Business Unit"
            optionFilterProp="items"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={businessUnitOptions}
            onChange={(e) =>
              setFilterConfig((prev) => ({...prev, business_unit_id: e }))
            }
          />
        </Space>
        {
        showDeleteMultiple &&
        <Space className="">
          <Button
            danger
            onClick={(e) => confirmMultipleDelete(e)}
            className="me-2"
            disabled={!itemsToDelete || itemsToDelete.length === 0}
          >
            Delete Selected
          </Button>
          <Button
            onClick={() => {
              setShowDeleteMultiple(false);
              setItemsToDelete([]);
            }}
          >
            Cancel
          </Button>
        </Space>
        }
      </Row>

      <Table
        rowKey="id"
        columns={tableColumns}
        dataSource={tableData}
        loading={isFetching || isLoading}
      />

      <Modal
        title={`${showAddModal ? "Add" : "Edit"} Timesheet`}
        centered
        open={showAddModal || showEditModal}
        footer={null}
        onCancel={() => {
          setShowAddModal(false);
          setShowEditModal(false);
          resetFields();
        }}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="validateOnly"
          layout="vertical"
          onFinish={showAddModal ? onFinish : onEdit}
          onValuesChange={handleFormChange}
        >
          <Form.Item
            name="employee_id"
            label="Employee"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={employeeOptions.slice(1)} disabled={showEditModal}/>
          </Form.Item>

          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="dtr_id"
                label="DTR ID"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="work_date"
                label="Work Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker placeholder="YYYY-MM-DD"/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_in"
                label="Time In"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format="hh:mm A"/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_out"
                label="Time Out"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format={"hh:mm A"} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Row>
                <Button
                  className="me-2"
                  danger
                  onClick={() => {
                    setShowAddModal(false);
                    setShowEditModal(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <AddSubmitButton form={form} isLoading={isLoading} />
              </Row>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Review Timesheet"
        centered
        open={showViewModal}
        footer={null}
        onCancel={() => {
          setShowViewModal(false);
          resetFields();
        }}
        width={800}
      >
        <Form
          className="mt-3 mx-3"
          form={form}
          name="editForm"
          layout="vertical"
        >
          <Row>
            <Col xs={24}>
              <Form.Item
                name="employee_id"
                label="Employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={employeeOptions.slice(1)} disabled/>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                name="dtr_id"
                label="DTR ID"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="work_date"
                label="Work Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_in"
                label="Time In"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format={"hh:mm A"} disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="time_out"
                label="Time Out"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker format={"hh:mm A"} disabled/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="d-flex justify-content-end">
              <Button
                danger
                onClick={() => setShowViewModal(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="text"
                onClick={() => {
                  setShowViewModal(false);
                  setShowEditModal(true);
                }}
                icon={<EditOutlined />}
              >
                Edit
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirmDelete(clickedRowData);
                }}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {contextHolder}
    </Navbar>
  );
}