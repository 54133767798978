import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { Table} from 'antd'
import { UsePostRequest } from '../../utils/api/ApiRequests'
import moment from 'moment';
import { getIsAdminLogin, getEmployeeId } from '../../utils/Common';

export default function LeaveCredit () {
  const isAdminLogin = getIsAdminLogin();
  const employeeId = getEmployeeId();
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  
  const [filterConfig, setFilterConfig] = useState({
    is_updated: false,
    employee_id: isAdminLogin?"":employeeId,
  });

//   Dynamic table columns because leave types are dynamic as well
  const tableColumns = tableColumn.map((data) => {
        return {
            title: data,
            key: data,
            dataIndex: data,
        }
    });

  const handleSearch = (e) => {
    setFilterConfig((prev) => ({...prev, term: e}));
  }

React.useEffect(() => {
  async function fetchTableData  () {
    setIsFetching(true);
    setTableData([]);
    const response = await UsePostRequest("leave_credits/get", filterConfig);
    if (response.data) {
        const res = response.data;

        // Define headers
        const headers = ["Employee", "Period"];
        res.map((data) => {
            const headerName = `${data.description} (${data.maximum_days})`;
            headers.push(headerName);
            return headerName;
        });

        // Flatten and format data
        const mergedArr = res.flatMap((data) =>
            data.employee_leaves.map((emp) => ({
                employee_id: emp.employee_id,
                Employee: emp.employee_name,
                Period: `${moment(emp.validity_from).format('MMM D, YYYY')} - ${moment(emp.validity_to).format('MMM D, YYYY')}`,
                [`${data.description} (${data.maximum_days})`]: emp.remaining,
            }))
        );

        // Group by employee_id and merge objects
        const mergedObject = mergedArr.reduce((result, obj) => {
            const id = obj.employee_id;
            result[id] = { ...result[id], ...obj };
            return result;
        }, {});

        // Sort by employee name
        const finalArray = Object.values(mergedObject).sort((a, b) =>
            a.Employee.localeCompare(b.Employee)
        );

        const tableCols = Object.keys(finalArray[0]).filter((key) => key !== 'employee_id');
        setTableColumn(tableCols);
        setTableData(finalArray);
    }
    setIsFetching(false);
  }

    fetchTableData ();
}, [filterConfig]);


  return (
    <Navbar pageTitle="Employee Leave Credits" noButton setSearchQuery={handleSearch} isManager searchable>
        <Table className='mt-2' rowKey="id"
          columns={tableColumns} 
          dataSource={tableData} 
          loading={isFetching} />
    </Navbar>
  )
}